import React from "react";
import Layout from "../../Utilities/layout";
import ContactClaases from "./contact.module.css";
import contactImg from "../../Assets/contactus.png";
import phone from "../../Assets/phone.png";
import { FiSmartphone } from "react-icons/fi";
import mail from "../../Assets/mail.png";
import location from "../../Assets/location.png";
import { Helmet } from "react-helmet";
function contact() {
    return (
        <>
            <Helmet defer={false}>
                <title>Contact us</title>
            </Helmet>
            <Layout>
                <div className={ContactClaases["contact-container"]}>
                    <div className={ContactClaases["wrapper"]}>
                        <div className={ContactClaases["wrapper-left"]}>
                            <div className={ContactClaases["call-us"]}>
                                <h3>
                                    <img
                                        src={phone}
                                        width={"30px"}
                                        alt="phone"
                                    />
                                    Call us
                                </h3>
                                <p>
                                    Dear Customer for any queries Please call
                                    our Customer support number
                                </p>
                                <div>
                                    <p>
                                        <FiSmartphone color="grey" size={22} />{" "}
                                        8886660201
                                    </p>
                                    <p>
                                        <FiSmartphone color="grey" size={22} />{" "}
                                        8886660207
                                    </p>
                                </div>
                            </div>
                            <div className={ContactClaases["call-us"]}>
                                <h3>
                                    <img
                                        src={mail}
                                        width={"30px"}
                                        alt="phone"
                                    />
                                    Mail us
                                </h3>
                                <h4>info@genifast.in</h4>
                            </div>
                            <div className={ContactClaases["call-us"]}>
                                <h3>
                                    <img
                                        src={location}
                                        width={"30px"}
                                        alt="phone"
                                    />
                                    Reach us
                                </h3>
                                <p className={ContactClaases["corporate"]}>
                                    Corporate Address
                                </p>
                                <p className={ContactClaases["address"]}>
                                    #105, Plot No A-1, GBS Enclave,
                                    <br /> Moti Valley, Trimulgherry,
                                    <br />
                                    Secunderabad - 500015
                                    <br /> Hyderabad, Telangana, India
                                </p>
                            </div>
                        </div>
                        <div className={ContactClaases["wrapper-right"]}>
                            <h3>Timings</h3>
                            <p>
                                10 Am to 6 PM <br />
                                Monday - Saturday only
                            </p>
                            <img
                                src={contactImg}
                                // width={"30px"}
                                alt="phone"
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default contact;
