import React from "react";
import profileClasses from "./Order/profile.module.css";
import Navbar from "./navbar/navbar";
import Layout from "../../Utilities/layout";
function ProfileLayout({ children, isactive }) {
    const searchStyle = { padding: "1rem 0.5rem" };
    const NavStyle = { padding: "1rem 2rem" };
    return (
        <Layout searchStyle={searchStyle} NavStyle={NavStyle}>
            <div className={profileClasses["profile-wrapper"]}>
                <div className={profileClasses["profile-leftside"]}>
                    <Navbar isactive={isactive} />
                </div>
                <div className={profileClasses["profile-rightSide"]}>
                    {children}
                </div>
            </div>
        </Layout>
    );
}

export default ProfileLayout;
