import React, { useEffect, useState } from "react";
import PopularClasses from "./Popular.module.css";
import { Services } from "../../../services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useStore from "../../../store";
import Skeleton from "react-loading-skeleton";
function Popular(props) {
    const Navigate = useNavigate();
    const [category, setCategory] = useState([]);
    const setStoreCategories = useStore((state) => state.setCategoris);

    useEffect(() => {
        Services.getCategories("GET", null)
            .then((res) => {
                if (res.Status === 1) {
                    setCategory(res.categories);
                    setStoreCategories(res.categories);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        // eslint-disable-next-line
    }, []);
    const viewhandler = () => {
        Navigate("/Categories");
    };
    const productHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        console.log(category.find((item) => item.categoryId == id));
        // setCategorisMetaData(category.find((item) => item.categoryId == id));
        sessionStorage.setItem(
            "categoryName",
            e.currentTarget.getAttribute("name")
        );
        let categoryParam = e.currentTarget
            .getAttribute("name")
            .replace(/\s+/g, "-")
            .replace("/", "");
        Navigate({
            pathname: `/shop/${categoryParam}`,
            search: `id=${e.currentTarget.getAttribute("id")}`,
        });
        // Navigate({
        //     pathname: "/shop",
        //     search: `${e.currentTarget.getAttribute(
        //         "name"
        //     )}&id=${e.currentTarget.getAttribute("id")}`,
        // });
    };
    return (
        <div className={PopularClasses["popularProducts-wrapper"]}>
            <div className={PopularClasses["headings"]}>
                <h2>
                    {props.loading ? (
                        <Skeleton width={500} />
                    ) : (
                        "Checkout the most popular categories"
                    )}
                </h2>
                {props.loading ? (
                    <Skeleton width={200} />
                ) : (
                    <button onClick={viewhandler}>View All</button>
                )}
            </div>
            <div className={PopularClasses["product-wrapper"]}>
                {props.loading
                    ? Array(5)
                          .fill(0)
                          .map((_, i) => (
                              <div
                                  key={i}
                                  className={PopularClasses["popular-item"]}>
                                  <Skeleton width={150} count={2} />
                                  <h3></h3>
                              </div>
                          ))
                    : category.slice(0, 5).map((item) => {
                          return (
                              <div
                                  className={PopularClasses["popular-item"]}
                                  onClick={productHandler}
                                  name={item.categoryName}
                                  id={item.categoryId}>
                                  <img src={item.imageUrl} alt="mask" />
                                  <h3>{item.categoryName}</h3>
                              </div>
                          );
                      })}
            </div>
        </div>
    );
}

export default Popular;
