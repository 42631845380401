import React from "react";
import Stepper from "react-stepper-horizontal";
import OrderClasses from "./orderDetails.module.css";
function Processed(props) {
    return (
        <div className={OrderClasses["stepper2"]}>
            {" "}
            <Stepper
                defaultBarColor={"#25A139"}
                activeColor={"grey"}
                completeColor={"green"}
                activeTitleColor={"grey"}
                completeTitleColor={"green"}
                defaultBorderWidth={10}
                steps={[
                    {
                        title: (
                            <p className={OrderClasses["title"]}>Processing</p>
                        ),
                    },
                    {
                        title: <p className={OrderClasses["title"]}>Shipped</p>,
                    },

                    {
                        title: (
                            <p className={OrderClasses["title"]}>Delivered</p>
                        ),
                    },
                ]}
                activeStep={props.orderStatus}
            />
        </div>
    );
}

export default Processed;
