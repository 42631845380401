import React from "react";
import cartclasses from "../../cart/cart.module.css";

function Modal(props) {
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setview(false);
    };
    const DeleteHandler = (e) => {
        e.stopPropagation();
        props.deleteHandler();
    };
    const openhandler = (e) => {
        e.stopPropagation();
    };
    return (
        <div className={cartclasses["modal"]} onClick={CancelHandler}>
            <div className={cartclasses["modal-content"]} onClick={openhandler}>
                <div className={cartclasses["modal-content"]}>
                    <button className={cartclasses["close-btn"]}>
                        {/* <FaRegWindowClose size={27} /> */}
                    </button>
                    <p>Do you really want to Delete Address?</p>
                    <div className={cartclasses["control-btns"]}>
                        {/* <button
                            className={cartclasses["cancel-btn"]}
                            onClick={props.moveTowishlist}>
                            WishList
                        </button> */}
                        <button
                            className={cartclasses["delete-btn"]}
                            onClick={DeleteHandler}>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
