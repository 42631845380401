import React from "react";
import Navbar from "../HomePage/Navbar/navbar";
import PolicyClasses from "./Privacy.module.css";
import Layout from "../../Utilities/layout";
import Footer from "../HomePage/footer/footer";
function ReturnPolicy() {
    return (
        <>
            <Layout>
                <div className={PolicyClasses["policy-wrapper"]}>
                    <h1>Return policy</h1>
                    <div className={PolicyClasses["policy"]}>
                        <p>
                            At GENIFAST, we strive to ensure your orders are
                            delivered to your satisfaction. However, in the rare
                            event that your order is unsatisfactory, we are
                            happy to accept returns for orders, if:
                            <ul>
                                <li>
                                    you've received an item(s) that does not
                                    match your order
                                </li>
                                <li>
                                    the item(s) were damaged in transit or
                                    shortage (delivery of such order to be
                                    refused at doorstep)
                                </li>
                                <li>
                                    the item(s) is due to expire within 3 months
                                    where the shelf life of the item is more
                                    than 3 months
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>
                            Returns are subject to the following conditions:
                        </h2>
                        <p>
                            <ul>
                                <li>
                                    Items being returned should only be in their
                                    original manufacturer's packaging i.e. with
                                    labels, bar-code.
                                </li>
                                <li>
                                    Only fully unused strips/bottles can be
                                    returned
                                </li>
                                <li>
                                    Batch number of the product being returned
                                    should match as mentioned on the sales
                                    invoice
                                </li>
                                <li>
                                    Original sales invoice has to be returned
                                    for processing returns and refunds.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2>
                            We do not accept returns in following scenarios:
                        </h2>
                        <p>
                            <ul>
                                <li>
                                    Partially used strips, opened bottles cannot
                                    be returned.
                                </li>
                                <li>
                                    Products which require specific storage
                                    conditions (temperature controlled) cannot
                                    be returned (vials, injections, vaccines,
                                    penfills, etc.)
                                </li>
                                <li>
                                    Products which have expired post purchase.
                                </li>
                                <li>
                                    Second time request against the same
                                    product/order.
                                </li>
                            </ul>{" "}
                        </p>
                        <p>
                            If you're order is suitable for a return based on
                            the conditions above, you can raise a request within
                            seven (7) days from the date of delivery. For
                            details on how to contact us for a return. Please
                            note that all returns are reviewed for eligibility.
                            All refunds are subject to the verification and
                            review by our Returns team.
                        </p>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2>REFUNDS</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Mode of Payment</th>
                                    <th>Mode of Refund</th>
                                    <th>Time frame</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Credit cards / Debit Cards</td>
                                    <td>Credit cards / Debit Cards</td>
                                    <td>5-7 business days*</td>
                                </tr>
                                <tr>
                                    <td>Net Banking</td>
                                    <td>Net Banking</td>
                                    <td>5-7 business days*</td>
                                </tr>
                                <tr>
                                    <td>Cash on Delivery</td>
                                    <td>Cash on Delivery</td>
                                    <td>5-7 business days*</td>
                                </tr>
                                <tr>
                                    <td>E-Wallets</td>
                                    <td>E-Wallets</td>
                                    <td>5-7 business days*</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            PREPAID Orders if returned by the Customer will be
                            refunded only after deducting Rs 100 towards the
                            Handling Charges.
                        </p>
                        <p>
                            Handling Charges will be added the Customers wallet
                            and the customer has to clear the Handling Charges
                            to proceed with the new order.
                        </p>
                        <p>
                            Refund timelines depend on bank turnaround times and
                            RBI guidelines. This may change from time to time.
                            Business days shall mean the working days on which
                            GENIFAST corporate office operates.
                        </p>
                        <p>
                            For orders placed using Cash on Delivery as the
                            payment method, refunds can be processed to your
                            bank account via National Electronic Funds Transfer
                            (NEFT). You will need to update following
                            information to enable us to process a refund to your
                            account.
                        </p>
                        <ul>
                            <li>The Bank Account Number</li>
                            <li>IFSC Code</li>
                            <li>Account Holder's Name</li>
                            <li>Bank Branch</li>
                            <li>Bank Name</li>
                        </ul>
                        <p>
                            Important:Refunds cannot be processed to third-party
                            accounts. The name on your GENIFAST account should
                            match with the name of the bank account holder. If
                            you have paid the bills using a third-party account,
                            refunds will be made to the same account. NEFT
                            refunds will typically be initiated in three
                            business days from our end and will be credited to
                            your account in another two to three business days.
                            GENIFAST will not be liable for any delay caused in
                            refunds due to delay by third party affiliates
                            (including banks), in providing information by the
                            customer, technical issues and other reasons beyond
                            its control.
                        </p>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2>Cancellation</h2>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2>
                            We understand that there will be genuine reasons
                            when you decide to cancel an order. You may cancel
                            your order by calling customer care number
                            7036-3344-00 or 7036-3344-11 writing a mail to
                            info@genifast.in
                        </h2>
                        <p>
                            Only the order which are yet to be dispatched can be
                            cancelled. Order once dispatched cannot be
                            cancelled. For any Order once dispatched and
                            cancelled by the Customer, handling charges of Rs 99
                            will have to paid by the
                            <ol>
                                <li>
                                    During order delivery, GENIFAST’s delivery
                                    team requests every customer to verify the
                                    contents and provide an acknowledgement
                                    confirming the correctness and completeness
                                    of the order.
                                </li>
                                <li>
                                    In case of any discrepancies including
                                    tampered order box or contents, please alert
                                    the delivery team or call us on our customer
                                    care number 7036-3344-00 or 7036-3344-11 or
                                    write to us at info@genifast.in for
                                    resolution.
                                </li>
                                <li>
                                    Once the order is verified and acknowledged
                                    by the customer, the order in full or part
                                    cannot be returned unless there is a defect
                                    in the delivered order which is highly
                                    unlikely. However, should you receive an
                                    incomplete order, damaged or incorrect
                                    product(s), please notify GENIFAST Customer
                                    Support immediately or within 10 working
                                    days of receiving the products, to ensure
                                    prompt resolution.
                                </li>
                                <li>
                                    Refunds will be processed only for defective
                                    and incorrect deliveries.
                                </li>
                                <li>
                                    All refunds will be made only through NEFT
                                    to the account which is provided by the
                                    customer. Refunds for Credit Card payments
                                    will take 10 days to get processed.
                                </li>
                                <li>
                                    Full Refund Possible If:
                                    <ol>
                                        <li>you received a defective item;</li>
                                        <li>
                                            the ordered item(s) is lost or
                                            damaged during transit
                                        </li>
                                        <li>
                                            the ordered item(s) is past its
                                            expiry date.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    We also understand that various
                                    circumstances may arise leading you to want
                                    to return a product or products that are not
                                    defective. In these cases, we may allow the
                                    return of unopened, unused products after
                                    deducting a 30% restocking charge, ONLY if
                                    you notify us within 7 working days of
                                    receipt.
                                </li>
                            </ol>
                        </p>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2>
                            We do not accept returns in following scenarios:
                        </h2>
                        <p>
                            <ul>
                                <li>
                                    Partially used strips, opened bottles cannot
                                    be returned.
                                </li>
                                <li>
                                    Products which require specific storage
                                    conditions (temperature controlled) cannot
                                    be returned (vials, injections, vaccines,
                                    penfills , etc.)
                                </li>
                                <li>
                                    Products which have expired post purchase.
                                </li>
                                <li>
                                    Second time request against the same
                                    product/order.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className={PolicyClasses["policy-dislaimer"]}>
                        <p>
                            GENIFAST Team reserves all right to activate or
                            Deactivate any Customer’s Account.
                        </p>
                    </div>
                </div>
                <Footer />
            </Layout>
        </>
    );
}

export default ReturnPolicy;
