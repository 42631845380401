import React, { useState } from "react";

import ProfileLayout from "../profileLayout";
import HelpClasses from "./help.module.css";
import Enquiry from "./Enquiry";
import Availability from "./Availability";
import Callback from "./callback";
import Franchise from "./Franchise";
function Help() {
    const [activeTab, setActiveTab] = useState("1");
    const buttonHandler = (e) => {
        setActiveTab(e.currentTarget.getAttribute("btn-id"));
    };
    return (
        <ProfileLayout isactive={"help"}>
            <div className={HelpClasses["help-wrapper"]}>
                <div className={HelpClasses["navbar"]}>
                    <button
                        className={
                            activeTab === "1"
                                ? HelpClasses["active-form"]
                                : HelpClasses["inactive-form"]
                        }
                        onClick={buttonHandler}
                        btn-id={1}>
                        Enquiry
                    </button>
                    <button
                        className={
                            activeTab === "2"
                                ? HelpClasses["active-form"]
                                : HelpClasses["inactive-form"]
                        }
                        onClick={buttonHandler}
                        btn-id={2}>
                        Availability
                    </button>
                    <button
                        className={
                            activeTab === "3"
                                ? HelpClasses["active-form"]
                                : HelpClasses["inactive-form"]
                        }
                        onClick={buttonHandler}
                        btn-id={3}>
                        CallBack
                    </button>
                    <button
                        className={
                            activeTab === "4"
                                ? HelpClasses["active-form"]
                                : HelpClasses["inactive-form"]
                        }
                        onClick={buttonHandler}
                        btn-id={4}>
                        Franchise{" "}
                    </button>
                </div>
                {activeTab === "1" && <Enquiry />}
                {activeTab === "2" && <Availability />}
                {activeTab === "3" && <Callback />}
                {activeTab === "4" && <Franchise />}
            </div>
        </ProfileLayout>
    );
}

export default Help;
