import React, { useState, useEffect } from "react";
import LoginClasses from "./login.module.css";
import LoginLogo from "../../Assets/login/login-office.jpeg";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Services } from "../../services";
import { IoClose } from "react-icons/io5";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
function Login(props) {
    const Navigate = useNavigate();
    const validatePhoneNumber = (value) => {
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        return phoneNumberPattern.test(value) || "Invalid phone number";
    };

    const location = useLocation();
    const [showOTP, setshowOTP] = useState(false);
    const [count, setcount] = useState(60);
    const [disable, setDisable] = useState(true);
    const [message, setMessage] = useState("");
    useEffect(() => {
        ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ID);
    }, []);
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm();
    const loginClose = (e) => {
        props.setlogin("");
    };
    const handleInputChange = (e) => {
        const { value } = e.target;
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        if (value.length > 10) {
            setValue("mobileNumber", String(value).substring(0, 10));
            return;
        }
        if (phoneNumberPattern.test(value)) {
            setValue("mobileNumber", value);
        }
    };
    const formdetails = watch();
    const reseondOtp = () => {
        let formdata = {
            mobileNumber: formdetails.mobileNumber,
        };
        Services.userLoginSendOtp("POST", JSON.stringify(formdata))
            .then((response) => {
                setMessage(response.Message);
                if (response.Status === 1) {
                    setshowOTP(true);

                    setDisable(true);
                }
            })
            .catch((err) => {
                setMessage("something went wrong please try again");
            });
    };
    const resendhandler = () => {
        reseondOtp();

        const interval = setInterval(() => {
            setcount((prev) => prev - 1);
        }, 1000);
        setTimeout(() => {
            setcount(60);
            setDisable(false);
            clearInterval(interval);
        }, 60000);
    };
    const onSubmit = (data) => {
        setMessage("");
        let formdata = {
            mobileNumber: data.mobileNumber,
        };
        if (showOTP) {
            formdata = {
                ...formdata,
                OTP: data.OTP,
            };
            Services.userLoginVerifyOtp("POST", JSON.stringify(formdata))
                .then((response) => {
                    //tracking user login facebook pixel
                    ReactPixel.trackSingle("Contact", {
                        content_name: "Login",
                        content_type: "phone_number",
                        contact_value: data.mobileNumber,
                    });
                    ReactGA.event({
                        category: "Login",
                        action: "User login",
                        label: data.mobileNumber,
                    });
                    if (response.Status === 1) {
                        setshowOTP(true);
                        Navigate(`${location.pathname}`);
                        sessionStorage.setItem("token", response.Token);
                        sessionStorage.setItem(
                            "userdetails",
                            JSON.stringify(response.userDetails)
                        );
                        loginClose();
                    } else {
                        setMessage(response.Message);
                    }
                })
                .catch((err) => {
                    setMessage("something went wrong please try again");
                });
        } else {
            Services.userLoginSendOtp("POST", JSON.stringify(formdata))
                .then((response) => {
                    setMessage(response.Message);
                    if (response.Status === 1) {
                        setshowOTP(true);
                        setDisable(true);
                        const interval = setInterval(() => {
                            setcount((prev) => prev - 1);
                        }, 1000);
                        setTimeout(() => {
                            setcount(60);
                            setDisable(false);
                            clearInterval(interval);
                        }, 60000);
                    }
                })
                .catch((err) => {
                    setMessage("something went wrong please try again");
                });
        }
    };

    return (
        <div className={LoginClasses["login-wrapper"]}>
            <div className={LoginClasses["login-subwrapper"]}>
                <img src={LoginLogo} alt="login-logo" />
                <div className={LoginClasses["close"]}>
                    <button onClick={loginClose}>
                        <IoClose />
                    </button>
                </div>
                <div className={LoginClasses["login-form"]}>
                    <h3>Login</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={LoginClasses["form-control"]}>
                            {/* <label htmlFor="mobileNumber">mobile number</label> */}
                            <input
                                disabled={showOTP}
                                placeholder="Mobile number"
                                type="number"
                                {...register("mobileNumber", {
                                    required: true,
                                    validate: validatePhoneNumber,
                                })}
                                onChange={handleInputChange}
                            />
                            {errors?.mobileNumber && (
                                <p className={LoginClasses.error}>
                                    Enter a valid Number!
                                </p>
                            )}
                        </div>
                        {showOTP && (
                            <div className={LoginClasses["form-control"]}>
                                {/* <label htmlFor="OTP">OTP</label> */}

                                <input
                                    type="OTP"
                                    {...register("OTP", {
                                        required: "OTP is required!",
                                    })}
                                    placeholder="OTP"
                                />
                                {errors?.OTP && (
                                    <p className={LoginClasses.error}>
                                        {errors?.OTP?.message}
                                    </p>
                                )}
                            </div>
                        )}
                        {message.length > 0 && (
                            <p className={LoginClasses.error}>{message}</p>
                        )}
                        <input
                            type="submit"
                            value={showOTP ? "Log in" : "Send OTP"}
                        />
                    </form>
                    {showOTP && (
                        <div className={LoginClasses["resend"]}>
                            <button disabled={disable} onClick={resendhandler}>
                                Resend OTP{disable && <span>({count})</span>}
                            </button>
                        </div>
                    )}
                    {/* <span className={LoginClasses.border}></span> */}

                    <div className={LoginClasses["login-links"]}>
                        {/* <button
                            onClick={() => props.setlogin("forgotPassword")}>
                            forgot your Password?
                        </button> */}
                        <button onClick={() => props.setlogin("signup")}>
                            Create Your Account
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
