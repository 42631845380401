import React, { useState, useEffect } from "react";
import LoginClasses from "./login.module.css";
import LoginLogo from "../../Assets/login/createaccount.jpeg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Services } from "../../services";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import ReactPixel from "react-facebook-pixel";
// import { toast } from "react-toastify";
import ReactGA from "react-ga";
function Login(props) {
    const Navigate = useNavigate();
    const [showOTP, setshowOTP] = useState(false);
    const [message, setMessage] = useState("");
    const [count, setcount] = useState(60);
    const [disable, setDisable] = useState(true);
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm();
    const validatePhoneNumber = (value) => {
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        return phoneNumberPattern.test(value) || "Invalid phone number";
    };

    const formdetails = watch();

    useEffect(() => {
        ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ID);
    }, []);
    const loginClose = (e) => {
        props.setlogin("");
    };
    const resendhandler = () => {
        reseondOtp();

        const interval = setInterval(() => {
            setcount((prev) => prev - 1);
        }, 1000);
        setTimeout(() => {
            setcount(30);
            setDisable(false);
            clearInterval(interval);
        }, 30000);
    };
    const handleInputChange = (e) => {
        const { value } = e.target;
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        if (value.length > 10) {
            setValue("mobileNumber", String(value).substring(0, 10));
            return;
        }
        if (phoneNumberPattern.test(value)) {
            setValue("mobileNumber", value);
        }
    };
    const reseondOtp = () => {
        let formdata = {
            mobileNumber: formdetails.mobileNumber,
        };
        Services.userSignupSendOtp("POST", JSON.stringify(formdata))
            .then((response) => {
                setMessage(response.Message);
                if (response.Status === 1) {
                    setshowOTP(true);

                    setDisable(true);
                }
            })
            .catch((err) => {
                setMessage("something went wrong please try again");
            });
    };
    const onSubmit = (data) => {
        if (showOTP) {
            Services.userSignupVerifyOtp("POST", JSON.stringify(data))
                .then((response) => {
                    //tracking user signup facebook pixel
                    ReactPixel.track("Lead", {
                        content_name: "Signup",
                        content_category: "Signup",
                        content_type: "phone",
                        phone: data.mobileNumber,
                    });
                    ReactGA.event({
                        category: "Signup",
                        action: "User signup",
                        label: data.mobileNumber,
                    });
                    if (response.Status === 1) {
                        setshowOTP(false);
                        props.setlogin(false);

                        Navigate({
                            pathname: "/signup/user-details",
                            search: `mobileNumber=${data.mobileNumber}`,
                        });
                        setMessage(response.Message);
                    } else {
                        setMessage(response.Message);
                    }
                })
                .catch((err) => {
                    toast.error(`something went wrong please try again`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        } else {
            Services.userSignupSendOtp("POST", JSON.stringify(data))
                .then((response) => {
                    setMessage(response.Message);
                    if (response.Status === 1) {
                        setshowOTP(true);

                        setDisable(true);
                        const interval = setInterval(() => {
                            setcount((prev) => prev - 1);
                        }, 1000);

                        setTimeout(() => {
                            setcount(30);
                            setDisable(false);
                            clearInterval(interval);
                        }, 60000);
                    }
                })
                .catch((err) => {
                    toast.error(`something went wrong please try again`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }

        // let formdata = {
        //     mobileNumber: data.mobileNumber,
        // };
        // if (showOTP) {
        //     formdata = {
        //         ...formdata,
        //         OTP: data.OTP,
        //     };
        // } else {
        //     Services.userLoginSendOtp("POST", JSON.stringify(formdata))
        //         .then((response) => {
        //             setMessage(response.Message);
        //             if (response.Status === 1) {
        //                 setshowOTP(true);
        //             }
        //         })
        //         .catch((err) => {
        //             setMessage("something went wrong please try again");
        //         });
        // }
    };
    return (
        <div className={LoginClasses["login-wrapper"]}>
            <div className={LoginClasses["login-subwrapper"]}>
                <img src={LoginLogo} alt="login-logo" />
                <div className={LoginClasses["close"]}>
                    <button onClick={loginClose}>
                        <IoClose />
                    </button>
                </div>
                <div className={LoginClasses["login-form"]}>
                    <h3>Sign up</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={LoginClasses["form-control"]}>
                            {/* <label htmlFor="mobileNumber">mobile number</label> */}
                            <input
                                disabled={showOTP}
                                placeholder="Mobile number"
                                type="number"
                                {...register("mobileNumber", {
                                    validate: validatePhoneNumber,
                                    required: true,
                                })}
                                onChange={handleInputChange}
                            />
                            {errors?.mobileNumber && (
                                <p className={LoginClasses.error}>
                                    Enter a valid Number!
                                </p>
                            )}
                        </div>
                        {showOTP && (
                            <div className={LoginClasses["form-control"]}>
                                {/* <label htmlFor="OTP">OTP</label> */}

                                <input
                                    type="OTP"
                                    {...register("OTP", {
                                        required: "OTP is required!",
                                    })}
                                    placeholder="OTP"
                                />
                                {errors?.OTP && (
                                    <p className={LoginClasses.error}>
                                        {errors?.OTP?.message}
                                    </p>
                                )}
                            </div>
                        )}
                        {message.length > 0 && (
                            <p className={LoginClasses.error}>{message}</p>
                        )}
                        <input
                            type="submit"
                            value={showOTP ? "Log in" : "Send OTP"}
                        />

                        {/* <span className={LoginClasses.border}></span> */}
                    </form>
                    {showOTP && (
                        <div className={LoginClasses["resend"]}>
                            <button disabled={disable} onClick={resendhandler}>
                                Resend OTP{disable && <span>({count})</span>}
                            </button>
                        </div>
                    )}
                    <div className={LoginClasses["login-links"]}>
                        <button onClick={() => props.setlogin("login")}>
                            Already have account?
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
