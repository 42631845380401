import React, { useState } from "react";
import cartclasses from "../../cart/cart.module.css";
import { Services } from "../../../services";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useStore from "../../../store";
import { Handlers } from "../../../Utilities/Handlers";
function Modal(props) {
    const Navigate = useNavigate();
    const cartCount = useStore((state) => state.CartCount);
    const [disable, setdisable] = useState(false);
    const storeSetwarehouseId = useStore((state) => state.SetwarehouseId);
    const storesetcartCount = useStore((state) => state.setcartCount);
    const setwishlistCount = useStore((state) => state.setwishList);
    const token = {
        Authorization: `token ${sessionStorage.getItem("token")}`,
    };

    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setview(false);
        props.setSelectedWarehouse(sessionStorage.getItem("warehouseId"));
        storeSetwarehouseId(sessionStorage.getItem("warehouseId"));
    };
    const proceedHandler = (e) => {
        e.stopPropagation();
        setdisable(true);
        const warehouseid = sessionStorage.getItem("warehouseId");
        const token = {
            Authorization: `token ${sessionStorage.getItem("token")}`,
        };
        Services.getwishlist("GET", null, token, props.selectedWarehouse)
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setwishlistCount(response.wishlist_Products.length);
                } else if (response.Status === 0) {
                    setwishlistCount(0);
                }
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        if (cartCount !== 0) {
            Services.getCart("get", null, token, warehouseid)
                .then((res) => {
                    if (res.Status === 1) {
                        Services.deleteCart(
                            "DELETE",
                            null,
                            token,
                            res.Cart?.cartId
                        ).then((res) => {
                            if (res.Status === 1) {
                                props.setview(false);
                                sessionStorage.setItem(
                                    "warehouseId",
                                    props.selectedWarehouse
                                );

                                storesetcartCount(0);
                                setdisable(false);
                                storeSetwarehouseId(props.selectedWarehouse);
                                // window.location.reload();
                            } else if (res.Status === 0) {
                                toast.error(`${res.Message}`, {
                                    position: "top-center",
                                    autoClose: 1000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                if (res.Message === "Token expired") {
                                    Navigate("/");
                                }
                            }
                        });
                    }
                    if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        //
    };
    const openhandler = (e) => {
        e.stopPropagation();
    };
    return (
        <div className={cartclasses["modal"]} onClick={CancelHandler}>
            <div className={cartclasses["modal-content"]} onClick={openhandler}>
                <div className={cartclasses["modal-content"]}>
                    <button className={cartclasses["close-btn"]}>
                        {/* <FaRegWindowClose size={27} /> */}
                    </button>
                    <p>Changing warehouse will remove cart items.</p>
                    <div className={cartclasses["control-btns"]}>
                        <button
                            className={cartclasses["cancel-btn"]}
                            onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            className={cartclasses["delete-btn"]}
                            onClick={proceedHandler}
                            disabled={disable}>
                            Proceed
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
