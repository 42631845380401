import React, { useState, useEffect } from "react";
import cartclasses from "../Components/cart/cart.module.css";
import { Services } from "../services";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useStore from "../store";
function Modal(props) {
    const Navigate = useNavigate();
    // const cartCount = useStore((state) => state.CartCount);

    const storeSetwarehouseId = useStore((state) => state.SetwarehouseId);
    const [ActiveWareHouses, setActiveWareHouse] = useState([]);
    // const token = {
    //     Authorization: `token ${sessionStorage.getItem("token")}`,
    // };

    const CancelHandler = (e) => {
        e.stopPropagation();
    };
    useEffect(() => {
        if (!sessionStorage.getItem("warehouses")) {
            Services.Activewasrehouse("GET", null)
                .then((res) => {
                    if (res.Status === 1) {
                        setActiveWareHouse(res.Warehouses);
                        sessionStorage.setItem(
                            "warehouses",
                            JSON.stringify(res.Warehouses)
                        );
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            const warehouses = JSON.parse(sessionStorage.getItem("warehouses"));
            setActiveWareHouse(warehouses);
        }
        // eslint-disable-next-line
    }, []);

    const warehouseChangeHandler = (e) => {
        e.stopPropagation();
        storeSetwarehouseId(e.target.value);
        sessionStorage.setItem("warehouseId", e.target.value);
        props.setwarehouse(false);
    };
    const openhandler = (e) => {
        e.stopPropagation();
    };
    return (
        <div
            className={`${cartclasses["modal"]} ${cartclasses["modal-blur"]}`}
            onClick={CancelHandler}>
            <div className={cartclasses["modal-content"]} onClick={openhandler}>
                <div className={cartclasses["modal-content"]}>
                    <p>Please Select Warehouse.</p>
                    <select onChange={warehouseChangeHandler} defaultValue="">
                        <option value="" disabled>
                            Select Warehouse
                        </option>
                        {ActiveWareHouses.map((warehouse) => (
                            <option value={warehouse.warehouseId}>
                                {warehouse.warehouseName}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export default Modal;
