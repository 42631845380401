import React from "react";
import { useForm } from "react-hook-form";
import HelpClasses from "./help.module.css";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Services } from "../../../services";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
import S3FileUpload from "react-s3";
import { config } from "../../../s3config";
import { useState } from "react";
window.Buffer = window.Buffer || require("buffer").Buffer;

function Enquiry() {
    const Navigate = useNavigate();
    const validatePhoneNumber = (value) => {
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        return phoneNumberPattern.test(value) || "Invalid phone number";
    };
    const [fileName, setFileName] = useState("");
    const [base64, setBase64] = useState("");
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    const handleInputChange = (e) => {
        const { value } = e.target;
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        if (value.length > 10) {
            setValue("contactNumber", String(value).substring(0, 10));
            return;
        }
        if (phoneNumberPattern.test(value)) {
            setValue("contactNumber", value);
        }
    };
    const onSubmit = (data) => {
        if (fileName === "") {
            alert("image is required");
            return;
        }
        let formdata = JSON.stringify({ ...data, imageUrl: fileName });
        console.log(formdata);

        Services.Availability("POST", formdata, token)
            .then((response) => {
                if (response.Status === 1) {
                    Navigate("/orders");
                    toast.success(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    const fileuploadDesign = (
        <div className={HelpClasses["add-item"]}>
            <BsCloudUpload className={HelpClasses["upload-icon"]} />
            <h5>Drag Your Image here</h5>
            <p>(Only *.jpeg and *.png images will be accepted)</p>
        </div>
    );
    const base64Handler = (file) => {
        let reader = new FileReader();

        // setfileCount((prev) => prev + 1);
        reader.readAsDataURL(file);
        reader.onload = function () {
            setBase64(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const HandleChange = (file) => {
        if (file.type === "image/png" || file.type === "image/jpeg") {
            const timestamp = Date.now();
            const newfile = new File(
                [file],
                `${timestamp}.${String(file.type).split("/")[1]}`
            );

            base64Handler(newfile);

            S3FileUpload.uploadFile(newfile, config)
                .then((res) => {
                    setFileName(
                        `${timestamp}.${String(file.type).split("/")[1]}`
                    );
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                });
        } else {
            alert("upload file in png and jpeg fromat");
        }
    };
    return (
        <div className={HelpClasses["help-form"]}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3>Availability Form</h3>
                <div className={HelpClasses["item-image-upload"]}>
                    <label htmlFor="Images">Images</label>
                    <div className={HelpClasses["uploaded-data"]}>
                        <FileUploader
                            classes={HelpClasses["upload-image"]}
                            multiple={false}
                            handleChange={HandleChange}
                            name="file"
                            children={fileuploadDesign}
                            // types={fileTypes}
                        />
                    </div>
                    {base64.length > 0 && (
                        <div className={HelpClasses["uploaded-name"]}>
                            <img
                                src={base64}
                                alt="uploaded-file"
                                width={"200px"}
                            />
                        </div>
                    )}
                </div>

                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="Name">Name</label>
                    <div>
                        <input
                            placeholder="name"
                            type="text"
                            {...register("name", {
                                required: true,
                                validate: (value) => {
                                    return !!value.trim();
                                },
                            })}
                        />
                        {errors?.name && (
                            <p className={HelpClasses.error}>
                                Name is required!
                            </p>
                        )}
                    </div>
                </div>
                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="mobileNumber">mobile number</label>
                    <div>
                        {" "}
                        <input
                            placeholder="Mobile number"
                            type="number"
                            {...register("contactNumber", {
                                required: true,
                                validate: validatePhoneNumber,
                            })}
                            onChange={handleInputChange}
                        />
                        {errors?.contactNumber && (
                            <p className={HelpClasses.error}>
                                Mobile number is required!
                            </p>
                        )}
                    </div>
                </div>
                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="EmailID">Email ID</label>
                    <div>
                        <input
                            placeholder="EmailID"
                            type="email"
                            {...register("emailId", {
                                required: true,
                                validate: (value) => {
                                    return !!value.trim();
                                },
                            })}
                        />
                        {errors?.emailId && (
                            <p className={HelpClasses.error}>
                                Email ID is required!
                            </p>
                        )}
                    </div>
                </div>
                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="message">message</label>
                    <div>
                        <textarea
                            rows={5}
                            {...register("message", {
                                required: true,
                                validate: (value) => {
                                    return !!value.trim();
                                },
                            })}></textarea>
                        {errors?.message && (
                            <p className={HelpClasses.error}>
                                message is required!
                            </p>
                        )}
                    </div>
                </div>
                <input type={"submit"} value="Submit" />
            </form>
        </div>
    );
}

export default Enquiry;
