import React, { useState } from "react";
import Product from "../../HomePage/LatestProducts/product";
import productShopclasses from "./products.module.css";
import ReactPaginate from "react-paginate";
import "../../../App.css";
function Products(props) {
    const [pageNumber, setPageNumber] = useState(0);
    const [details, setallDetails] = useState();
    const productsPerPage = 50;
    const pagesVisited = pageNumber * productsPerPage;
    const displayProducts = props.products
        .slice(pagesVisited, pagesVisited + productsPerPage)
        .map((item) => {
            return (
                <Product
                    img={item.imageUrl}
                    cost={item.price}
                    productId={item.productId}
                    heading={item.productName}
                    sellingPrice={item.genifast_sellingPrice}
                    genericName={item.genericName}
                    availableQty={item.availableQty}
                    manufacturedBy={item.manufacturedBy}
                    packing={item.packing}
                    expiry={item.expiry}
                    allData={item}
                    setallDetails={setallDetails}
                    details={details}
                />
            );
        });
    const pageCount = Math.ceil(props.products.length / productsPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
        <div className={productShopclasses["product-container"]}>
            <div className={productShopclasses["products-wrapper"]}>
                {displayProducts}
            </div>
            <ReactPaginate
                previousLabel={"<<prev"}
                nextLabel={"next>>"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttns"}
                nextLinkClassName={"nextBttns"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
            />
        </div>
    );
}

export default Products;
