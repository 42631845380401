import React from "react";
import CustomerClasses from "./customer.module.css";
// import Slider from "../../reusable/slider";
import Latestproducts from "../../HomePage/LatestProducts/Latestproducts";
function Customer(props) {
    return (
        <div className={CustomerClasses["customer-wrapper"]}>
            {/* <Slider items={items} heading={"Customers also bought"} /> */}
            {props.relatedProducts?.length > 0 && (
                <Latestproducts
                    deals={props.relatedProducts}
                    heading={"Customers also bought"}
                />
            )}
        </div>
    );
}

export default Customer;
