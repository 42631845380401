const httpcall = (url, method, body, token) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        method: method,
        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    }).then((response) => response.json());
};

const getCategories = (method, body) => {
    return httpcall("users/webcategorieslist", method, body);
};
const getHomeScreen = (method, body, warehouseid) => {
    let url= `users/webhomepage/${warehouseid}`
    let UserDetails=JSON.parse(sessionStorage.getItem('userdetails'))

    if(UserDetails){
         url=`${url}?userType=${UserDetails?.userType}`
    }
    return httpcall(url, method, body);
};
const getProductsbyCategoryId = (method, body, catid, houseid, state) => {
    let url= `products/webuser/bycategory/${catid}/${houseid}/${state}`
    let UserDetails=JSON.parse(sessionStorage.getItem('userdetails'))
    console.log(UserDetails)
    if(UserDetails){
         url=`${url}?userType=${UserDetails?.userType}`
    }
    return httpcall(
       url,
        method,
        body
    );
};
const userLoginSendOtp = (method, body) => {
    return httpcall(`users/login`, method, body);
};
const userLoginVerifyOtp = (method, body) => {
    return httpcall(`users/login/verifyotp`, method, body);
};
const userSignupSendOtp = (method, body) => {
    return httpcall(`users/signup/sendotp`, method, body);
};
const userSignupVerifyOtp = (method, body) => {
    return httpcall(`users/signup/verifyotp`, method, body);
};
const userSignupFormTwo = (method, body) => {
    return httpcall(`users/signup`, method, body);
};
const getProductByProductId = (method, body, token, prodid, warehouseid) => {
    let url=`products/user/detail/${prodid}/${warehouseid}`
    let UserDetails=JSON.parse(sessionStorage.getItem('userdetails'))
    console.log(UserDetails)
    if(UserDetails){
         url=`${url}?userType=${UserDetails?.userType}`
    }
    return httpcall(
        url,
        method,
        body,
        token
    );
};
const addPorductToCart = (method, body, token) => {
    return httpcall(`cart/addcart`, method, body, token);
};
const getCart = (method, body, token, id) => {
    return httpcall(`cart/mycart/${id}`, method, body, token);
};
const removeCartItem = (method, body, token, prodId) => {
    return httpcall(`cart/cartitem/${prodId}`, method, body, token);
};
const getwishlist = (method, body, token, warehouseid) => {
    return httpcall(
        `products/userwishlist/${warehouseid}`,
        method,
        body,
        token
    );
};
const addTowishList = (method, body, token) => {
    return httpcall(`products/addtowishlist`, method, body, token);
};

const removeWishlistItem = (method, body, token, prodId) => {
    return httpcall(
        `products/removefromwishlist/${prodId}`,
        method,
        body,
        token
    );
};
const getOrder = (method, body, token, order) => {
    return httpcall(`orders/${order}`, method, body, token);
};
const OrderByOrderId = (method, body, token, id) => {
    return httpcall(`orders/myorder/${id}`, method, body, token);
};
const CancelOrder = (method, body, token, id) => {
    return httpcall(`orders/cancelorderuser/${id}`, method, body, token);
};
const addressList = (method, body, token) => {
    return httpcall(`users/addresses/list`, method, body, token);
};
const Addaddress = (method, body, token) => {
    return httpcall(`users/addaddress`, method, body, token);
};
const getAddressbyid = (method, body, token, id) => {
    return httpcall(`users/addressdetail/${id}`, method, body, token);
};
const updateAddress = (method, body, token, id) => {
    return httpcall(`users/updateaddress/${id}`, method, body, token);
};
const DeleteAddress = (method, body, token, id) => {
    return httpcall(`users/deleteaddress/${id}`, method, body, token);
};
const getWalletTransactions = (method, body, token) => {
    return httpcall(`wallet/mytransactions`, method, body, token);
};
const helpForm = (method, body, token, id) => {
    return httpcall(`forms/helpform`, method, body, token);
};
const callBackForm = (method, body, token, id) => {
    return httpcall(`forms/addcallback`, method, body, token);
};
const franchiseForm = (method, body, token, id) => {
    return httpcall(`forms/addfranchiseEnquiry`, method, body, token);
};
const Availability = (method, body, token, id) => {
    return httpcall(`forms/addavailability`, method, body, token);
};
const updateProfile = (method, body, token, id) => {
    return httpcall(`users/update/${id}`, method, body, token);
};
const CreateOrder = (method, body, token) => {
    return httpcall(`orders/createorder`, method, body, token);
};
const OrderPayment = (method, body, token) => {
    return httpcall(`orders/orderpayments`, method, body, token);
};
const verifyPromocode = (method, body, token) => {
    return httpcall(`promocodes/user/verify`, method, body, token);
};
const SearchProductByGenericName = (method, body) => {
    return httpcall(`products/web/user/bygenericname`, method, body);
};
const Activewasrehouse = (method, body) => {
    return httpcall(`warehouses/webuser/activewarehouses`, method, body);
};
const deleteCart = (method, body, token, cartid) => {
    return httpcall(`cart/deletecart/${cartid}`, method, body, token);
};
const reorder = (method, body, token, cartid) => {
    return httpcall(`reorder`, method, body, token);
};
const DeliveryDate = (method, body, token, cartid) => {
    return httpcall(`cart/estimatedelivery`, method, body, token);
};
const NotfiyProduct = (method, body, token, cartid) => {
    return httpcall(`users/addnotifyproducts`, method, body, token);
};
const config = (method, body, token, cartid) => {
    return httpcall(`users/configlist`, method, body, token);
};
const deliveryCharges = (method, body, token) => {
    return httpcall(`cart/deliverycharges`, method, body, token);
};

export const Services = {
    deliveryCharges,
    getCategories,
    getHomeScreen,
    getProductsbyCategoryId,
    userLoginSendOtp,
    userLoginVerifyOtp,
    userSignupFormTwo,
    userSignupSendOtp,
    userSignupVerifyOtp,
    getProductByProductId,
    addPorductToCart,
    getCart,
    removeCartItem,
    getwishlist,
    addTowishList,
    removeWishlistItem,
    getOrder,
    OrderByOrderId,
    CancelOrder,
    addressList,
    Addaddress,
    getAddressbyid,
    updateAddress,
    DeleteAddress,
    getWalletTransactions,
    helpForm,
    callBackForm,
    franchiseForm,
    Availability,
    updateProfile,
    CreateOrder,
    OrderPayment,
    verifyPromocode,
    SearchProductByGenericName,
    Activewasrehouse,
    deleteCart,
    reorder,
    DeliveryDate,
    NotfiyProduct,
    config,
};
