import React from "react";
import PolicyClasses from "./Privacy.module.css";
import Layout from "../../Utilities/layout";
import Footer from "../HomePage/footer/footer";
function Privacy() {
    return (
        <>
            <Layout>
                <div className={PolicyClasses["policy-wrapper"]}>
                    <h1>Privacy Policy</h1>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Your Privacy - Our Commitment</h2>
                        <p>
                            Welcome to www.genifast.in. We care about you and
                            knows that you care how your information is used and
                            or may be used or shared.This website is owned and
                            operated by Vaishnavi Biotech, whose registered
                            office is at #105, GBS Enclave, Plot No A-1. Moti
                            Valley, Trimulgherry. Secunderabad, Hyderabad
                            500015, India.
                        </p>
                        <p>
                            By using this Website, you agree to the terms and
                            conditions of this Privacy Policy. If you do not
                            agree with the Terms and Conditions of this Privacy
                            Policy, please do not proceed further to use this
                            Website. This Privacy Policy is subject to change at
                            any time without notice. To make sure you are aware
                            of any changes, please review this policy
                            periodically. This Privacy Policy is incorporated
                            into and subject to the Terms of Use. For the
                            purpose of this Privacy Policy, wherever the context
                            so requires "You" or "User" shall mean any natural
                            or legal person who has agreed to become a buyer on
                            the Website by providing Personal Information
                            (defined below) while registering on the Website as
                            a registered User using the computer systems.
                        </p>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2 className={PolicyClasses["points-header"]}>
                            1. Absolute Confidentiality{" "}
                        </h2>
                        <p>
                            <span> 1.1</span> &nbsp;www.genifast.in is totally
                            committed to protecting your privacy and ensuring
                            the security of any personal information received
                            from you ("Customer"). At genifast.in, the
                            confidentiality of our Customers is a top priority.
                            We strictly adhere to the requirements of the data
                            protection law established in India and this Privacy
                            Policy is published in accordance with:
                            <ul>
                                <li>
                                    Section 43 A of the Information Technology
                                    Act, 2000;
                                </li>
                                <li>
                                    Regulation 4 of the Information Technology
                                    (Reasonable Security Practices and
                                    Procedures and Sensitive Personal
                                    Information) Rules, 2011 (the "SPI Rules")
                                    and
                                </li>
                                <li>
                                    Regulation 3(1) of the Information
                                    Technology (Intermediaries Guidelines)
                                    Rules, 2011.
                                </li>
                            </ul>
                        </p>
                        <p>
                            {" "}
                            <span> 1.2</span> &nbsp;The purpose of this privacy
                            policy statement is to explain to you what personal
                            information We collect from your visit(s) to our
                            Website, and how We protect the information that you
                            provide to us when you use our Website. You can be
                            assured that www.genifast.in will use such
                            information sensibly and carefully, and only in
                            accordance with this privacy statement. We maintain
                            compliance with the highest standards of website
                            privacy, so our visitors/Customers can securely
                            place orders on our Website. This Privacy Policy is
                            effective from 03/06/2020. Any information that you
                            provide to www.genifast.in is subject to our Privacy
                            Policy.
                        </p>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2 className={PolicyClasses["points-header"]}>
                            2. Your Consent{" "}
                        </h2>

                        <p>
                            {" "}
                            <span> 2.1</span> &nbsp;By accessing this Website
                            and whenever you submit any information to
                            www.genifast.in, or use products and services
                            offered by our Website, you agree to be bound by the
                            terms of this Privacy Policy. Please read below
                            carefully the entire Privacy Policy before using
                            genifast.in website.
                        </p>
                        <p>
                            {" "}
                            <span> 2.2</span> &nbsp;As part of providing our
                            Services to you, We may need to send routine
                            communications to you, such as order transaction
                            emails and other administrative messages through
                            email and notifications posted on the Website or
                            through other means available, including text and
                            other forms of messaging. Though we prefer to
                            communicate with our Customers via email, as may be
                            deemed necessary, you agree to receive our phone
                            calls, related to your orders/request on
                            www.genifast.in.
                        </p>
                        <p>
                            {" "}
                            <span> 2.3</span> &nbsp;When you sign up to receive
                            our newsletters, We may periodically send emails
                            about new products, special offers or other
                            information, which you may find it interesting and
                            beneficial as well. If you do not wish to receive
                            promotional information from us, you can, at any
                            time, choose to opt out of receiving GENIFAST
                            promotional newsletters. To unsubscribe from our
                            newsletters, you can follow the directions included
                            at the newsletter.
                        </p>
                        <p>
                            {" "}
                            <span> 2.4</span> &nbsp;You must ensure to provide
                            us with the correct or up-to-date Personal
                            Information. This will allow us to contact you for
                            further correspondences with respect to your order
                            at genifast.in. We are not responsible for any
                            inaccurate information you provide to us, which may
                            affect your shopping experience on our website.
                        </p>
                        <p>
                            {" "}
                            <span> 2.5</span> &nbsp;If, at any time, you want to
                            access, update, modify or eliminate any of your
                            account information at www.genifast.in, you may do
                            so by clicking "Your Account", and then
                            review/change your sensitive personal information as
                            you want it to be.
                        </p>
                        <p>
                            {" "}
                            <span> 2.6</span> &nbsp;www.genifast.in may, in
                            future, request other optional information from you
                            in order to customise the Website to deliver
                            personalised service to our Customers.
                        </p>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2 className={PolicyClasses["points-header"]}>
                            3. What Information We Collect About You
                        </h2>
                        <p>
                            {" "}
                            <span>3.1</span> &nbsp;We do not automatically
                            collect your personally identifiable information.
                            However, you might provide us with your personal and
                            demographic information – including your name,
                            gender, age, address, phone number, email address
                            and your medical prescription(s) – in the following
                            ways:
                            <ul>
                                <li>
                                    As a New Customer, when you register/create
                                    an account, place your order, or upload your
                                    medical prescription at www.genifast.in.
                                </li>
                                <li>
                                    As an Existing Customer, when you update
                                    your existing profile on www.genifast.in.
                                </li>
                                <li>
                                    When you sign up for newsletters at
                                    www.genifast.in
                                </li>
                                <li>
                                    When you use Refer & Earn programme, ask Our
                                    Pharmacist service, Contact Us or submit
                                    your Testimonials at www.genifast.in.
                                </li>
                            </ul>
                        </p>
                        <p>
                            <span>3.2</span> &nbsp;During your visit to our
                            Website, We may automatically receive technical
                            information about your computer/device, including
                            your IP address, your computer operating system,
                            time-zone, browser type and browser plug-in details,
                            due to the communication protocol settings on the
                            Internet.
                        </p>
                        <p>
                            <span>3.3</span> &nbsp;When you browse our Website,
                            We may also collect information regarding the pages
                            you viewed, the web addresses from which you arrive
                            or click through to, time spent on certain pages,
                            download errors and page response times. The
                            information we learn from customers helps us analyse
                            Customers' trends, personalise and continuously
                            improve your experience at genifast.in.
                        </p>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2 className={PolicyClasses["points-header"]}>
                            4. How We Use the Information Collected from You
                        </h2>
                        <p>
                            <span>3.3</span> &nbsp;When you browse our Website,
                            We will collect and retain your personally
                            identifiable information only to the extent that it
                            is necessary to fulfil our services to you. Any
                            Personal information submitted by you to the Website
                            or collected by us will be used ONLY for the
                            purposes mentioned below:
                            <ul>
                                <li>
                                    To carry out our obligations arising from
                                    your requests for our products and services.
                                </li>
                                <li>
                                    To improve our Website's functionality, and
                                    to customise your future shopping experience
                                    with us.
                                </li>
                                <li>
                                    To make sure that our Website content is
                                    presented in the manner that is most
                                    effective for you.
                                </li>
                                <li>
                                    To communicate with you about any changes to
                                    our services
                                </li>
                                <li>
                                    To verify your identity and perform checks
                                    to prevent fraud.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2 className={PolicyClasses["points-header"]}>
                            5. With Whom We Share Your Information
                        </h2>
                        <p>
                            <span>5.1</span> &nbsp;When you browse our Website,
                            We do not sell, rent or exchange your any personally
                            identifiable information that you provide to us
                            through this Website with any third party for
                            commercial reasons. However, We engage trusted
                            online payment service partners to perform payment
                            processing and authorisation services (for net
                            banking or credit/debit card validation). Through
                            your use of the genifast.in Services, you consent to
                            our collection and transfer of your information to
                            our payment service partner websites
                        </p>

                        <p>
                            <span>5.2</span> &nbsp;As with any business, it is
                            possible that as our business develops, we might
                            sell or buy other businesses or assets. In such
                            transactions, User/Customer information is typically
                            one of the transferred business assets. Accordingly,
                            in the event that genifast.in, or substantially all
                            of our assets, is acquired by a third party, such
                            information may be one of the assets that is
                            transferred or acquired by a third party. Under such
                            circumstances, genifast.in would, to the extent
                            possible, require the acquiring party to follow the
                            practices described in this Privacy Policy. You
                            acknowledge that such transfers may occur, and that
                            any acquirer of www. genifast.in may continue to
                            process your personal information as set forth in
                            this Privacy Policy.
                        </p>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2 className={PolicyClasses["points-header"]}>
                            6. Who Has Access to Your Information
                        </h2>
                        <p>
                            Your personally identifiable information can be
                            accessed only on a need-to-know basis by certain
                            genifast.in employees who are designated to carry
                            out your requested activity, and all such employees
                            are bound to strict confidentiality obligations.
                        </p>
                    </div>

                    <div className={PolicyClasses.policy}>
                        <h2 className={PolicyClasses["points-header"]}>
                            7. How We Use Cookies
                        </h2>
                        <p>
                            “Cookies” are small pieces of information placed by
                            a website onto your computer’s hard drive. Cookies
                            do not collect any sensitive personal information
                            from you. We use cookies to analyse data about our
                            web page traffic, which helps us save your
                            preferences for your future visits. This allows us
                            to customise our website according to your
                            interests, which will enable us to deliver a more
                            personalised service to our customers. You may
                            choose to accept or decline cookies. Please be aware
                            that by declining cookies you may be unable to use
                            our website to its fullest capability.
                        </p>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2 className={PolicyClasses["points-header"]}>
                            8. Security Policy
                        </h2>
                        <p>
                            We guarantee that all your personal information with
                            us is secure. genifast.in takes careful precaution
                            to protect our Customers' personal information from
                            unauthorised access, improper use or disclosure or
                            unauthorised modification. To prevent unauthorised
                            access, we have put in place the latest
                            industry-standard security technology and procedures
                            to safeguard the information we collect online. Your
                            personal information is encrypted and is protected
                            with 256-bit Secure Sockets Layer (SSL) software,
                            which encrypts all information you input. We store
                            your personally identifiable information on the
                            computer servers placed in a secure environment.
                            Even though we have taken significant steps to
                            protect your personally identifiable information, no
                            company, including our Website, can fully eliminate
                            security risks associated with Personal Information.
                        </p>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2 className={PolicyClasses["points-header"]}>
                            9. Maintain Confidentiality of Your Prescription
                        </h2>
                        <p>
                            We understand the confidential nature of the
                            prescription you provide us to process your
                            medication order and we treat your prescription as a
                            protected information.
                        </p>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2 className={PolicyClasses["points-header"]}>
                            10. Changes to Our Privacy Policy
                        </h2>
                        <p>
                            We may revise this Privacy Policy from time to time
                            by updating this page, and so we urge you to review
                            it periodically to ensure that you agree with any
                            such changes that we make. We will make best efforts
                            to inform you of any important amendments by e-mail,
                            message on the Website or notification in Your
                            Account. However, it is your responsibility to check
                            the Policy regularly to ensure that you agree with
                            it and your continued use of the Website will be
                            deemed to be your acceptance of any changes that we
                            make. Last updated Date: 03/06/2020.
                        </p>
                    </div>
                    <div className={PolicyClasses.policy}>
                        <h2 className={PolicyClasses["points-header"]}>
                            11. Contact Information
                        </h2>
                        <p>
                            If you have questions about this Privacy Policy,
                            please contact our customer service
                            at info@genifast.in or call 7036-3344-00 or
                            7036-3344-11.
                        </p>
                    </div>
                </div>
                <Footer />
            </Layout>
        </>
    );
}

export default Privacy;
