import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import HelpClasses from "./help.module.css";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Services } from "../../../services";
function Enquiry() {
    const Navigate = useNavigate();
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [pincode, setpincode] = useState("");
    const [pincodeError, setpincodeError] = useState();
    const pincoderef = useRef();
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    const validatePhoneNumber = (value) => {
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        return phoneNumberPattern.test(value) || "Invalid phone number";
    };
    const handleInputChange = (e) => {
        const { value } = e.target;
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        if (value.length > 10) {
            setValue("contactNumber", String(value).substring(0, 10));
            return;
        }
        if (phoneNumberPattern.test(value)) {
            setValue("contactNumber", value);
        }
    };
    const onSubmit = (data) => {
        if (pincode.trim().length != 6 || pincode.slice(0, 1) == 0) {
            setpincodeError(true);
            pincoderef.current.focus();
            return;
        }
        let formdata = JSON.stringify({ ...data, pincode: pincode });

        Services.franchiseForm("POST", formdata, token)
            .then((response) => {
                if (response.Status === 1) {
                    Navigate("/orders");
                    toast.success(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    const pincodeChange = (e) => {
        if (e.target.value.trim().length <= 6) {
            setpincodeError(false);
            setpincode(e.target.value);
        }
    };
    return (
        <div className={HelpClasses["help-form"]}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3>Franchise Form</h3>
                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="Name">Name</label>
                    <div>
                        <input
                            placeholder="name"
                            type="text"
                            {...register("name", {
                                required: true,
                                validate: (value) => {
                                    return !!value.trim();
                                },
                            })}
                        />
                        {errors?.name && (
                            <p className={HelpClasses.error}>
                                Name is required!
                            </p>
                        )}
                    </div>
                </div>
                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="mobileNumber">mobile number</label>
                    <div>
                        {" "}
                        <input
                            placeholder="Mobile number"
                            type="number"
                            {...register("contactNumber", {
                                required: true,
                                validate: validatePhoneNumber,
                            })}
                            onChange={handleInputChange}
                        />
                        {errors?.contactNumber && (
                            <p className={HelpClasses.error}>
                                Mobile number is required!
                            </p>
                        )}
                    </div>
                </div>
                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="EmailID">Email ID</label>
                    <div>
                        <input
                            placeholder="EmailID"
                            type="email"
                            {...register("emailId", {
                                required: true,
                                validate: (value) => {
                                    return !!value.trim();
                                },
                            })}
                        />
                        {errors?.emailId && (
                            <p className={HelpClasses.error}>
                                Email is required!
                            </p>
                        )}
                    </div>
                </div>
                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="city">City</label>
                    <div>
                        <input
                            placeholder="City"
                            type="text"
                            {...register("city", {
                                required: true,
                                validate: (value) => {
                                    return !!value.trim();
                                },
                            })}
                        />
                        {errors?.city && (
                            <p className={HelpClasses.error}>
                                City is required
                            </p>
                        )}
                    </div>
                </div>
                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="state">State</label>
                    <div>
                        <input
                            placeholder="state"
                            type="text"
                            {...register("state", {
                                required: true,
                                validate: (value) => {
                                    return !!value.trim();
                                },
                            })}
                        />
                        {errors?.state && (
                            <p className={HelpClasses.error}>
                                State is required
                            </p>
                        )}
                    </div>
                </div>
                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="Pincode">Pincode</label>
                    <div>
                        <input
                            placeholder="Pincode"
                            type="number"
                            onChange={pincodeChange}
                            value={pincode}
                            ref={pincoderef}
                        />
                        {pincodeError && (
                            <p className={HelpClasses.error}>
                                Enter a valid pincode
                            </p>
                        )}
                    </div>
                </div>

                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="description">Description</label>
                    <div>
                        <textarea
                            rows={5}
                            {...register("message", {
                                required: true,
                                validate: (value) => {
                                    return !!value.trim();
                                },
                            })}></textarea>
                        {errors?.message && (
                            <p className={HelpClasses.error}>
                                Message is required!
                            </p>
                        )}
                    </div>
                </div>
                <input type={"submit"} value="Submit" />
            </form>
        </div>
    );
}

export default Enquiry;
