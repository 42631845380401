import React, { useEffect, useState } from "react";
import Loader from "../../../Utilities/loader";
import { toast } from "react-toastify";
import { Services } from "../../../services";

import profileClasses from "./profile.module.css";

import emptyCart from "../../../Assets/emptycart.webp";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import ProfileLayout from "../profileLayout";
function Profile() {
    const Navigate = useNavigate();

    const [orderState, setOrdserState] = useState("currentorders");
    const [orders, setOrders] = useState([]);
    const [load, setload] = useState(true);
    const orderStateHandler = (e) => {
        const state = e.currentTarget.getAttribute("id");
        setOrdserState(state);
    };
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    useEffect(() => {
        Services.getOrder("GET", null, token, orderState)
            .then((response) => {
                setload(false);
                if (response.Status === 1) {
                    setOrders(response.Orders);
                } else if (response.Status === 0) {
                    if (response.Message === "No Orders Found") {
                        setOrders([]);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        // eslint-disable-next-line
    }, [orderState]);
    return (
        <>
            <ProfileLayout isactive={"profile"}>
                <div className={profileClasses["orders-header"]}>
                    <button
                        className={
                            orderState === "currentorders"
                                ? profileClasses["Order-btn-active"]
                                : profileClasses["Order-btn-inactive"]
                        }
                        id="currentorders"
                        onClick={orderStateHandler}>
                        Current Orders
                    </button>
                    <button
                        id="pastorders"
                        className={
                            orderState === "pastorders"
                                ? profileClasses["Order-btn-active"]
                                : profileClasses["Order-btn-inactive"]
                        }
                        onClick={orderStateHandler}>
                        Past Orders
                    </button>
                </div>
                {load ? (
                    <Loader />
                ) : orders.length > 0 ? (
                    <div className={profileClasses["order-details-wrapper"]}>
                        {orders.map((order) => {
                            const orderedDate = new Date(order.updatedAt);
                            return (
                                <div
                                    className={profileClasses["order-details"]}
                                    onClick={() =>
                                        Navigate(`/orders/${order.orderId}`)
                                    }>
                                    <h3>Order Number #{order.orderNumber}</h3>
                                    <p>
                                        <Moment format="DD-MM-YYYY hh:mm a">
                                            {orderedDate}
                                        </Moment>
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className={profileClasses["emptyCart-wrapper"]}>
                        <div className={profileClasses["emptyCart"]}>
                            <img src={emptyCart} alt="emptycart" />
                            <h2>No Orders Found!</h2>

                            <button
                                onClick={() => {
                                    Navigate("/shop");
                                }}>
                                Shop now
                            </button>
                        </div>
                    </div>
                )}
            </ProfileLayout>
        </>
    );
}

export default Profile;
