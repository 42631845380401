import React, { useEffect, useState } from "react";
import Search from "../Components/HomePage/SearchBar/Search";
import Navbar from "../Components/HomePage/Navbar/navbar";
import SignUp from "../Components/Login/signup";
import Login from "../Components/Login/login";
import { toast } from "react-toastify";
import ForgotPassword from "../Components/Login/forgotPassword";
import { Services } from "../services";
import useStore from "../store";
import Warehousemodal from "./warehouseModal";
import { useNavigate } from "react-router-dom";
function Layout({ children, searchStyle, NavStyle }) {
    const Navigate = useNavigate();
    const resetState = useStore((state) => state.resetState);
    const setAuth = useStore((state) => state.setIsauth);
    const auth = useStore((state) => state.isAuth);
    const login = useStore((state) => state.loginModal);
    const [warehose, setwarehouse] = useState(false);
    const setLogin = useStore((state) => state.setLoginModal);
    const [genericNames, setGenericNames] = useState([]);
    const [search, setSearch] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const storeSetwarehouseId = useStore((state) => state.SetwarehouseId);
    const [showMenu, setShowMenu] = useState(false);
    useEffect(() => {
        const Token = sessionStorage.getItem("token");
        const warehouseid = sessionStorage.getItem("warehouseId");

        if (!warehouseid) {
            // add below line later for warehouse select pop up
            // setwarehouse(true);
            // remove below if added warehouse popup line
            Services.Activewasrehouse("GET", null)
                .then((res) => {
                    if (res.Status === 1) {
                        console.log(res);
                        storeSetwarehouseId(res.Warehouses[0].warehouseId);
                        sessionStorage.setItem(
                            "warehouseId",
                            res.Warehouses[0].warehouseId
                        );
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        if (Token) {
            setAuth(true);
        }

        // eslint-disable-next-line
    }, [login]);
    const outsideHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        if (id !== "search") {
            setSearch(false);
        }
        if (id !== "account-settings" && showSettings) {
            setShowSettings(false);
        }
        if (id !== "show-menu" && showMenu) {
            setShowMenu(false);
        }
    };
    const Blocked = (data) => {
        sessionStorage.clear();
        resetState();
        Navigate("/");
    };
    return (
        <div onClick={outsideHandler}>
            {login === "forgotPassword" && (
                <ForgotPassword setlogin={setLogin} />
            )}
            {login === "signup" && <SignUp setlogin={setLogin} />}
            {login === "login" && <Login setlogin={setLogin} />}
            {warehose && <Warehousemodal setwarehouse={setwarehouse} />}

            <div
                style={{
                    position: "sticky",
                    top: "0",
                    background: "white",
                    zIndex: "20",
                }}>
                {" "}
                <Search
                    setShowSettings={setShowSettings}
                    showSettings={showSettings}
                    genericNames={genericNames}
                    setlogin={setLogin}
                    auth={auth}
                    searchStyle={searchStyle}
                    setGenericNames={setGenericNames}
                    outsideHandler={outsideHandler}
                    setSearch={setSearch}
                    search={search}
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                />
                <Navbar NavStyle={NavStyle} />
            </div>
            {children}
        </div>
    );
}

export default Layout;
