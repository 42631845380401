import React, { useEffect, useState } from "react";
import Layout from "../../Utilities/layout";
import Footer from "../HomePage/footer/footer";
import cartclasses from "./cart.module.css";
import image from "../../Assets/image.png";
import Loader from "../../Utilities/loader";
import { toast } from "react-toastify";
import { Services } from "../../services";
import DeleteModal from "./deleteModal";
import emptyCart from "../../Assets/emptycart.webp";
import { useNavigate } from "react-router-dom";
import useStore from "../../store";
import CircleLoad from "../../Utilities/circleLoad";
import Coupen from "../../Assets/coupen.png";
import { IoMdClose } from "react-icons/io";
import PAY from "./pay";
import Payment from "./payment";
import Error from "../../Utilities/error";
import { Handlers } from "../../Utilities/Handlers";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import CartSkeleton from "./CartSkeleton";
function Cart() {
    const SetStorePrice = useStore((state) => state.setPrice);
    const auth = useStore((state) => state.isAuth);
    const setLogin = useStore((state) => state.setLoginModal);
    const setWishlistCount = useStore((state) => state.setwishList);
    // const [totalPrice,useState]
    const setpromocode = useStore((state) => state.setpromocode);
    const [PromocodeState, setPromocodeState] = useState("");
    const promocode = useStore((state) => state.promocode);
    const Navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const cartCount = useStore((state) => state.CartCount);
    const setcartCount = useStore((state) => state.setcartCount);
    const [prodId, setprodId] = useState();
    const [addDisable, setaddDisable] = useState(false);
    const [config, setconfig] = useState([]);
    const storewarehouseId = useStore((state) => state.warehouseId);
    const [load, setload] = useState(true);
    const [cartId, setCartId] = useState("");
    const [items, setItems] = useState([]);
    const [error, seterror] = useState("");
    const [paymentDetails, setPaymentDetails] = useState([]);
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    const [view, setview] = useState(false);
    const [cartUpdateload, setCartupdateload] = useState(false);
    const warehouseid = sessionStorage.getItem("warehouseId");
    const [deliveryError, setdeliveryError] = useState("");
    const [deliveryDate, setDeliveryDate] = useState("");
    const [deliveryCharges, setdeliveryCharges] = useState(0);

    const [addressShow, setAddressShow] = useState(false);
    const [DeliveryAddress, setDeliveryAddress] = useState(null);
    const [itemData, setItemData] = useState({});
    const [payment, setPayment] = useState(false);
    const [outofStock, setoutofStock] = useState(false);

    const [errorMessage, seterrorMessage] = useState("");
    const [errorModal, setErrorModal] = useState(false);

    const userDetails = JSON.parse(sessionStorage.getItem("userdetails"));
    // initilize react pixel

    useEffect(() => {
        const advanceMatching = {
            em: userDetails.emailId,
            ph: userDetails.mobileNumber,
            fn: userDetails.ownerName,
            ct: userDetails.city,
            st: userDetails.state,
            zp: userDetails.pincode,
            country: "IN",
            external_id: userDetails.userId,
        };
        ReactPixel.init(
            process.env.REACT_APP_FACEBOOK_PIXEL_ID,
            advanceMatching,
            false
        );
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ID);
        ReactGA.set({
            userId: userDetails.userId,
            email: userDetails.emailId,
            firstName: userDetails.ownerName,
            phone: userDetails.mobileNumber,
        });
    }, []);

    const updateCart = (id, quantity) => {
        setCartupdateload(true);
        let updateCartitem = JSON.stringify({
            productId: id,
            quantity: quantity,
        });
        Services.addPorductToCart("POST", updateCartitem, token)
            .then((response) => {
                if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    getCart();
                }
                setTimeout(() => {
                    setCartupdateload(false);
                }, 500);
            })
            .catch((err) => {
                setCartupdateload(false);
                alert("something went wrong try again later");
                console.log(err);
            });
    };
    const itemCountHandler = (e) => {
        e.stopPropagation();
        setpromocode({ code: "", amount: 0 });
        setPromocodeState("");
        const buttonType = e.currentTarget.getAttribute("buttonType");
        const productID = e.currentTarget.getAttribute("prodid");
        let currqty = e.currentTarget.getAttribute("qty");
        let availableQty = e.currentTarget.getAttribute("availableQty");

        const productIndex = items.findIndex(
            // eslint-disable-next-line
            (item) => item.productId == productID
        );
        const product = [...items];

        if (buttonType === "plus" && productIndex !== -1) {
            if (availableQty < Number(currqty) + 1) {
                setaddDisable(true);
                return;
            }
            let latestprice =
                paymentDetails.price + product[productIndex].price;
            let latestsellingPrice =
                paymentDetails.Sellingprice +
                product[productIndex].sellingPrice;
            product[productIndex] = {
                ...product[productIndex],
                quantity: product[productIndex].quantity + 1,
            };

            // if (product[productIndex].quantity == 11) {
            //     alert("maximum allowed quantity is 10");
            //     return;
            // }
            updateCart(productID, product[productIndex].quantity);
            console.log(product);
            setItems(product);
            setPaymentDetails({
                price: latestprice,
                Sellingprice: latestsellingPrice,
            });
            SetStorePrice(Number(latestsellingPrice));

            return;
        } else if (buttonType === "minus" && productIndex !== -1) {
            if (product[productIndex].quantity > 1) {
                let latestprice =
                    paymentDetails.price - product[productIndex].price;
                let latestsellingPrice =
                    paymentDetails.Sellingprice -
                    product[productIndex].sellingPrice;
                product[productIndex] = {
                    ...product[productIndex],
                    quantity: product[productIndex].quantity - 1,
                };
                setItems(product);
                setPaymentDetails({
                    price: latestprice,
                    Sellingprice: latestsellingPrice,
                });

                updateCart(productID, product[productIndex].quantity);
                SetStorePrice(Number(latestsellingPrice));
            }

            return;
        }
    };

    const getCart = () => {
        const warehouseid = sessionStorage.getItem("warehouseId");
        Services.getCart("GET", null, token, warehouseid)
            .then((response) => {
                setCartupdateload(false);
                setCartId(response?.Cart?.cartId);

                if (response.Status === 1) {
                    let price = response?.Cart?.cartItems
                        ?.map((item) => item?.price * item?.quantity)
                        .reduce((prev, curr) => prev + curr, 0);
                    let Sellingprice = response?.Cart?.cartItems
                        ?.map(
                            (item) =>
                                item?.genifast_sellingPrice * item?.quantity
                        )
                        .reduce((prev, curr) => prev + curr, 0);

                    setPaymentDetails({
                        price: price,
                        Sellingprice: Sellingprice,
                    });
                    SetStorePrice(Number(Sellingprice));

                    setProducts(response.Cart.cartItems);
                    const cartdata = response?.Cart?.cartItems?.map((item) => ({
                        productId: item.productId,
                        quantity: item.quantity,
                        price: item.price,
                        sellingPrice: item.genifast_sellingPrice,
                    }));
                    setItems(cartdata);
                    const json = JSON.stringify({
                        warehouseId: Number(warehouseid),
                    });
                    Services.config("GET", null, token).then((configData) => {
                        if (configData.Status == 1) {
                            let deliveryDiscountThreshold =
                                configData.config?.find(
                                    (item) =>
                                        item.configKey ==
                                        "deliveryDiscountThreshold"
                                );
                            console.log(deliveryDiscountThreshold);
                            Services.deliveryCharges("POST", json, token).then(
                                (res) => {
                                    setload(false);
                                    if (res.Status === 1) {
                                        //configValue
                                        if (
                                            Sellingprice >
                                            deliveryDiscountThreshold.configValue
                                        ) {
                                            setdeliveryCharges(0);
                                        } else {
                                            setdeliveryCharges(
                                                res.deliveryCharges
                                            );
                                        }
                                    }
                                }
                            );
                        }
                    });
                }
                if (
                    response.Status == 0 &&
                    response.Message === "No Items added to cart"
                ) {
                    setload(false);
                    setProducts([]);
                }
            })
            .catch((err) => {
                setCartupdateload(false);
                setload(false);
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    useEffect(() => {
        getCart();
        // eslint-disable-next-line
    }, [storewarehouseId]);
    const moveTowishlist = () => {
        setload(true);
        Handlers.wishlist(prodId, auth, setLogin, setWishlistCount);

        deleteHandler();
    };
    const deleteHandler = (e) => {
        Services.removeCartItem("DELETE", null, token, prodId)
            .then((response) => {
                setview(false);
                if (response.Status === 1) {
                    ReactPixel.track("RemoveFromCart", {
                        content_ids: [prodId],
                        content_name: itemData.prodName,
                        content_type: "product",
                        value: itemData.price,
                        currency: "INR",
                    });
                    ReactGA.event({
                        category: "Cart",
                        action: "Remove from cart",
                        label: `${itemData.prodName}-${prodId}`,
                        value: itemData.price,
                    });
                    setcartCount(cartCount - 1);
                    setload(true);
                    getCart();
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setview(false);
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    const removeCartItemHandler = (e) => {
        e.stopPropagation();
        setview(true);
        setprodId(e.currentTarget.getAttribute("prodid"));
        setItemData({
            prodName: e.currentTarget.getAttribute("prodName"),
            price: e.currentTarget.getAttribute("price"),
        });
    };
    const carthandler = (e) => {
        e.stopPropagation();
        const prodid = e.currentTarget.getAttribute("prodid");
        const heading = e.currentTarget.getAttribute("heading");
        let pathstring = heading.replace(/\s+/g, "-").toLowerCase();
        Navigate(`/product-details?id=${prodid}&name=${pathstring}`);
    };
    const onSubmit = (e) => {
        e.preventDefault();
        const json = JSON.stringify({ promoCode: PromocodeState });
        Services.verifyPromocode("POST", json, token)
            .then((res) => {
                console.log(res);
                if (res.status === 1) {
                    seterror("");
                    if (
                        paymentDetails.Sellingprice >
                        res.PromoCode.minOrdervalue
                    ) {
                        if (res.PromoCode.type === 2) {
                            setpromocode({
                                code: res.PromoCode.promoCode,
                                amount: res.PromoCode.offerValue,
                            });
                        } else if (res.PromoCode.type === 1) {
                            const discountAmount =
                                (res.PromoCode.offerValue / 100) *
                                paymentDetails.Sellingprice;
                            if (discountAmount < res.PromoCode.maxDiscount) {
                                setpromocode({
                                    code: res.PromoCode.promoCode,
                                    amount: Math.round(discountAmount),
                                });
                            } else if (
                                discountAmount > res.PromoCode.maxDiscount
                            ) {
                                setpromocode({
                                    code: res.PromoCode.promoCode,
                                    amount: res.PromoCode.maxDiscount,
                                });
                            } else {
                                seterror("Promocode not applicable");
                            }
                        }
                    } else {
                        seterror("Promocode not applicable");
                    }
                } else {
                    seterror(res.Message);
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
            });
    };
    const PlaceOrderHandler = (e) => {
        if (!DeliveryAddress) {
            seterrorMessage("Please add Delivery Address");
            setErrorModal(true);
            return;
        } else if (outofStock) {
            alert("some Items in your  cart are not currently available.");
            return;
        }
        Services.config("GET", null, token)
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    setconfig(res.config);
                    seterror("");
                    let key = res.config.find(
                        (item) => item.configKey == "minorderamount"
                    );

                    let total = Number(
                        paymentDetails.Sellingprice -
                            promocode.amount +
                            deliveryCharges
                    ).toFixed(2);

                    if (Number(key.configValue) > total) {
                        seterrorMessage(
                            `min purchase order should be ${key.configValue}`
                        );
                        setErrorModal(true);
                        return;
                    } else {
                        console.log(products);
                        ReactPixel.track("InitiateCheckout", {
                            contents: products.map((item) => ({
                                id: item.productId,
                                quantity: item.quantity,
                                price: item.genifast_sellingPrice,
                                currency: "INR",
                            })),
                            content_type: "product",
                        });
                        ReactGA.event({
                            category: "Checkout",
                            action: "Initiate checkout",
                            label: "Checkout started",
                            value: products.reduce(
                                (total, product) =>
                                    total + product.genifast_sellingPrice,
                                0
                            ),
                            dimension1: products.map((item) => ({
                                id: item.productId,
                                quantity: item.quantity,
                                price: item.genifast_sellingPrice,
                                currency: "INR",
                            })),
                        });
                        setPayment(true);
                    }
                } else {
                    seterror(res.Message);
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
            });
    };
    return (
        <>
            {errorModal && (
                <Error setmodal={setErrorModal} errorMessage={errorMessage} />
            )}
            {cartUpdateload && <CircleLoad />}
            {view && (
                <DeleteModal
                    prodid={prodId}
                    setview={setview}
                    getcart={getCart}
                    load={load}
                    setload={setload}
                    deleteHandler={deleteHandler}
                    moveTowishlist={moveTowishlist}
                />
            )}
            {addressShow && (
                <PAY
                    setmodal={setAddressShow}
                    setDeliveryAddress={setDeliveryAddress}
                    setDeliveryDate={setDeliveryDate}
                    setdeliveryError={setdeliveryError}
                    setdeliveryCharges={setdeliveryCharges}
                />
            )}
            {payment && (
                <Payment
                    config={config}
                    deliveryCharges={deliveryCharges}
                    setmodal={setPayment}
                    deliveryAddress={DeliveryAddress}
                    cartid={cartId}
                    deliveryDate={deliveryDate}
                    // storePrice={storePrice}
                />
            )}
            <Layout>
                {load ? (
                    <CartSkeleton />
                ) : (
                    <div className={cartclasses["cart-container"]}>
                        {products?.length === 0 ? (
                            <div className={cartclasses["emptyCart-wrapper"]}>
                                <div className={cartclasses["emptyCart"]}>
                                    <h1>My Cart</h1>
                                    <img src={emptyCart} alt="emptycart" />
                                    <h2>Your Cart is Empty!</h2>
                                    <p>Add items to it now</p>
                                    <button
                                        onClick={() => {
                                            Navigate("/shop");
                                        }}>
                                        Shop now
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className={cartclasses["cart"]}>
                                <div className={cartclasses["cart-items"]}>
                                    <h1>My Cart({products?.length})</h1>
                                    {products?.length > 0 ? (
                                        products.map((item) => {
                                            if (
                                                !outofStock &&
                                                item.availableQty <
                                                    item.quantity
                                            ) {
                                                setoutofStock(true);
                                            }
                                            const quantity = items.find(
                                                (prduct) =>
                                                    // eslint-disable-next-line
                                                    prduct.productId ==
                                                    item.productId
                                            ).quantity;

                                            return (
                                                <div
                                                    className={
                                                        cartclasses["cart-item"]
                                                    }
                                                    onClick={carthandler}
                                                    prodid={item.productId}
                                                    heading={item.productName}>
                                                    <img
                                                        src={item.imageUrl}
                                                        onError={({
                                                            currentTarget,
                                                        }) => {
                                                            currentTarget.onerror =
                                                                null; // prevents looping
                                                            currentTarget.src =
                                                                image;
                                                        }}
                                                        alt="product"
                                                    />
                                                    <div
                                                        className={
                                                            cartclasses[
                                                                "cart-item-desc"
                                                            ]
                                                        }>
                                                        <h3>
                                                            {item.productName}
                                                        </h3>
                                                        <p
                                                            className={
                                                                cartclasses[
                                                                    "company"
                                                                ]
                                                            }>
                                                            {item.genericName}
                                                        </p>
                                                        <p
                                                            className={
                                                                cartclasses[
                                                                    "company"
                                                                ]
                                                            }>
                                                            {" "}
                                                            Company:{" "}
                                                            {
                                                                item.manufacturedBy
                                                            }
                                                        </p>
                                                        <p
                                                            className={
                                                                cartclasses[
                                                                    "company"
                                                                ]
                                                            }>
                                                            {" "}
                                                            Packing Size:{" "}
                                                            {item.packing}
                                                        </p>
                                                        <p
                                                            className={
                                                                cartclasses[
                                                                    "company"
                                                                ]
                                                            }>
                                                            {" "}
                                                            Expiry:{" "}
                                                            {item.expiry}
                                                        </p>
                                                        <p
                                                            className={
                                                                cartclasses[
                                                                    "cost"
                                                                ]
                                                            }>
                                                            Price ₹
                                                            {Number(
                                                                item.genifast_sellingPrice
                                                            ).toFixed(2)}
                                                        </p>
                                                        <div
                                                            className={
                                                                cartclasses[
                                                                    "mrp"
                                                                ]
                                                            }>
                                                            <p>
                                                                MRP{" "}
                                                                <del>
                                                                    ₹{" "}
                                                                    {Math.round(
                                                                        item.price
                                                                    )}
                                                                </del>
                                                            </p>
                                                            <p
                                                                className={
                                                                    cartclasses[
                                                                        "save"
                                                                    ]
                                                                }>
                                                                You save ₹
                                                                {Math.round(
                                                                    item.price -
                                                                        item.genifast_sellingPrice
                                                                )}
                                                            </p>
                                                        </div>
                                                        {item.availableQty >=
                                                        item.quantity ? (
                                                            <div
                                                                className={
                                                                    cartclasses[
                                                                        "add-del-quantity"
                                                                    ]
                                                                }>
                                                                <button
                                                                    prodid={
                                                                        item.productId
                                                                    }
                                                                    availableQty={
                                                                        item.availableQty
                                                                    }
                                                                    qty={
                                                                        item.quantity
                                                                    }
                                                                    buttonType={
                                                                        "minus"
                                                                    }
                                                                    onClick={
                                                                        itemCountHandler
                                                                    }>
                                                                    -
                                                                </button>
                                                                <p>
                                                                    {quantity}
                                                                </p>
                                                                <button
                                                                    prodid={
                                                                        item.productId
                                                                    }
                                                                    buttonType={
                                                                        "plus"
                                                                    }
                                                                    qty={
                                                                        item.quantity
                                                                    }
                                                                    availableQty={
                                                                        item.availableQty
                                                                    }
                                                                    onClick={
                                                                        itemCountHandler
                                                                    }
                                                                    disabled={
                                                                        item.availableQty <
                                                                        item.quantity +
                                                                            1
                                                                    }>
                                                                    +
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={
                                                                    cartclasses[
                                                                        "out-of-stock"
                                                                    ]
                                                                }>
                                                                <p>
                                                                    Out of stock
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            cartclasses[
                                                                "remove-btn"
                                                            ]
                                                        }>
                                                        <button
                                                            prodid={
                                                                item.productId
                                                            }
                                                            price={
                                                                item.genifast_sellingPrice
                                                            }
                                                            prodName={
                                                                item.productName
                                                            }
                                                            onClick={
                                                                removeCartItemHandler
                                                            }>
                                                            Delete
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <p>Cart is empty</p>
                                    )}
                                </div>
                                <div
                                    className={
                                        cartclasses["cart-bill-wrapper"]
                                    }>
                                    <div className={cartclasses["coupen"]}>
                                        <h1>Delivery </h1>
                                        {DeliveryAddress && (
                                            <div
                                                className={
                                                    cartclasses[
                                                        "delivery-address-selected"
                                                    ]
                                                }>
                                                <p>{DeliveryAddress.name}</p>
                                                <p>
                                                    {DeliveryAddress.address1},
                                                </p>
                                                <p>
                                                    {DeliveryAddress.address2}
                                                </p>
                                                <p>{DeliveryAddress.city}</p>
                                                <p>{DeliveryAddress.pincode}</p>
                                            </div>
                                        )}
                                        <button
                                            type="submit"
                                            className={
                                                cartclasses[
                                                    "address-select-btn"
                                                ]
                                            }
                                            onClick={() => {
                                                setAddressShow(true);
                                            }}>
                                            {DeliveryAddress
                                                ? "Change Address"
                                                : "Add Address"}
                                        </button>

                                        {deliveryError && (
                                            <p
                                                className={
                                                    cartclasses["deliveryError"]
                                                }
                                                style={{ color: "red" }}>
                                                {deliveryError}
                                            </p>
                                        )}
                                        {deliveryDate.length == 0 ? (
                                            <div
                                                className={
                                                    cartclasses["delivery-data"]
                                                }>
                                                {/* <h3>
                                                    Usually delivered in 7
                                                    working days
                                                </h3> */}
                                                <p>
                                                    Please select the address to
                                                    see estimated delivery date
                                                </p>
                                            </div>
                                        ) : (
                                            <p
                                                className={
                                                    cartclasses["deliveryError"]
                                                }
                                                style={{
                                                    color: "red",
                                                    margin: 0,
                                                }}>
                                                Shipment will be delivered by{" "}
                                                {deliveryDate}
                                            </p>
                                        )}
                                    </div>
                                    {promocode.amount > 0 ? (
                                        <div
                                            className={
                                                cartclasses["coupen-applied"]
                                            }>
                                            <img src={Coupen} alt="coupen" />
                                            <p>
                                                YAY..You save ₹
                                                {promocode.amount}
                                            </p>
                                            <button
                                                onClick={() => {
                                                    setpromocode({
                                                        code: "",
                                                        amount: 0,
                                                    });
                                                }}>
                                                <IoMdClose />
                                            </button>
                                        </div>
                                    ) : (
                                        <div className={cartclasses["coupen"]}>
                                            <h1>Apply Coupon</h1>
                                            <form onSubmit={onSubmit}>
                                                <input
                                                    type={"text"}
                                                    placeholder={"Enter Coupon"}
                                                    value={PromocodeState}
                                                    onChange={(e) =>
                                                        setPromocodeState(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <button type="submit">
                                                    Apply
                                                </button>
                                            </form>
                                            {error && (
                                                <p style={{ color: "red" }}>
                                                    {error}
                                                </p>
                                            )}
                                        </div>
                                    )}

                                    <div className={cartclasses["cart-bill"]}>
                                        <h1>Price Details</h1>
                                        <div
                                            className={
                                                cartclasses["bill-data"]
                                            }>
                                            <p>Price</p>
                                            <p>
                                                ₹
                                                {Number(
                                                    paymentDetails.Sellingprice
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                cartclasses["bill-data"]
                                            }>
                                            <p>Discount</p>
                                            <p>
                                                ₹
                                                {Number(
                                                    promocode.amount
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                        {/* <div
                                            className={
                                                cartclasses["bill-data"]
                                            }>
                                            <p>Promocode</p>
                                            <p>₹{promocode.amount}</p>
                                        </div> */}
                                        <div
                                            className={
                                                cartclasses["bill-data"]
                                            }>
                                            <p>Delivery Charges</p>
                                            <p>
                                                ₹
                                                {Number(
                                                    deliveryCharges
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                        <div
                                            className={`${cartclasses["bill-data"]} ${cartclasses["total"]}`}>
                                            <p>Total Amount</p>
                                            <p>
                                                ₹{" "}
                                                {Number(
                                                    paymentDetails.Sellingprice -
                                                        promocode.amount +
                                                        deliveryCharges
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                        <p>
                                            You will save ₹
                                            {Number(
                                                paymentDetails.price -
                                                    paymentDetails.Sellingprice +
                                                    promocode.amount
                                            ).toFixed(0)}{" "}
                                            on this order
                                        </p>
                                    </div>

                                    <button
                                        className={cartclasses["order-button"]}
                                        onClick={PlaceOrderHandler}>
                                        Place Order
                                    </button>
                                </div>
                            </div>
                        )}
                        <Footer />
                    </div>
                )}
            </Layout>
        </>
    );
}

export default Cart;
