import { useState } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import latestClasses from "./latest.module.css";
import Product from "./HomePageProducts";
import { BsShieldCheck } from "react-icons/bs";
import AddtoCart from "./AddToCart";
import QuantityModal from "./quantityModal";
function Latestproducts(props) {
    const warehouseid = sessionStorage.getItem("warehouseId");
    const [notifyModal, setNotifyModal] = useState(false);
    const [cartModal, setCartModal] = useState(false);
    const [prodid, setprodId] = useState();
    const [availableQty, setavailableQty] = useState();
    const [notifyProductId, setNotifyProductId] = useState();
    const [allDetails, setAllDetails] = useState();
    const items = props.deals.map((item) => {
        return (
            <Product
                img={item.imageUrl}
                cost={item.price}
                productId={item.productId}
                heading={item.productName}
                sellingPrice={item.genifast_sellingPrice}
                genericName={item.genericName}
                availableQty={item.availableQty}
                manufacturedBy={item.manufacturedBy}
                packing={item.packing}
                expiry={item.expiry}
                setmodal={setCartModal}
                prodid={prodid}
                setprodid={setprodId}
                setavailableQty={setavailableQty}
                setNotifyProductId={setNotifyProductId}
                setNotifyModal={setNotifyModal}
                alldata={item}
                setAllDetails={setAllDetails}
            />
        );
    });
    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
        1225: { items: 4 },
        1400: { items: 5 },
    };

    return (
        <>
            {notifyModal && (
                <QuantityModal
                    productId={notifyProductId}
                    setmodal={setNotifyModal}
                    warehouseid={warehouseid}
                />
            )}
            {cartModal && (
                <AddtoCart
                    allDetails={allDetails}
                    setmodal={setCartModal}
                    prodid={prodid}
                    setprodid={setprodId}
                    availableQty={availableQty}
                    setavailableQty={setavailableQty}
                />
            )}
            <div className={latestClasses["product-Container"]}>
                <h2>{props.heading}</h2>
                <div
                    className={`${latestClasses["slider"]} ${latestClasses["multi-slider"]} multi-slider`}>
                    <AliceCarousel
                        mouseTracking
                        items={items}
                        responsive={responsive}
                        controlsStrategy="responsive"
                        disableDotsControls={true}
                        infinite={false}
                    />
                </div>
            </div>
        </>
    );
}

export default Latestproducts;
