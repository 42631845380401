import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./Components/HomePage/homePageMain";
import "./App.css";
import Shop from "./Components/shop/shop";
import ProductDetails from "./Components/productDetails/productDetails";
import Privacy from "./Components/Policy/Privacy";
import Termsandconditions from "./Components/Policy/termsAndConditions";
import ReturnPolicy from "./Components/Policy/ReturnPolicy";
import ShippingPolicy from "./Components/Policy/Shippingpolicy";
import Categories from "./Components/Categories/categories";
import SignupDetails from "./Components/Login/signup-data";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cart from "./Components/cart/cart";
import Wishlist from "./Components/wishlist/wishlist";
import Profile from "./Components/Profile/Order/profile";
import OrderDetails from "./Components/Profile/OrderDetails/orderDetails";
import ManageAddress from "./Components/Profile/manageAddress/address";
import Refer from "./Components/Profile/ReferAndEarn/refer";
import Points from "./Components/Profile/Points/points";
import Help from "./Components/Profile/help/help";
import EditProfile from "./Components/Profile/EditProfile/edit";
import OrderSuccessful from "./Components/OrderSucess/success";
import Checkout from "./Components/cart/pay";
import ProtectedRoutes from "./Protected";
import Contact from "./Components/contactus/contact";
import { Handlers } from "./Utilities/Handlers";
import Aboutus from "./Components/aboutUs/Aboutus";
import Blog from "./Components/blog/blog";
import useStore from "./store";
import SignupSuccess from "./Components/Login/signupSuccess";
import "react-loading-skeleton/dist/skeleton.css";
import PageNotFound from "./Components/404Page/index";
function App() {
    const auth = useStore((state) => state.isAuth);
    const setWishlistCount = useStore((state) => state.setwishList);
    const setcartCount = useStore((state) => state.setcartCount);

    useEffect(() => {
        if (auth) {
            Handlers.getCart(setcartCount);
            Handlers.getWishlist(setWishlistCount);
        }
    });
    return (
        <>
            <ToastContainer />
            <Routes onUpdate={() => window.scrollTo(0, 0)}>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/shop" element={<Shop />} />
                <Route exact path="/shop/:title" element={<Shop />} />
                <Route exact path="/about-us" element={<Aboutus />} />
                <Route exact path="/blog" element={<Blog />} />
                <Route exact path="/Categories" element={<Categories />} />
                <Route exaact path="/contact-us" element={<Contact />} />
                <Route exact path="/privacy-policy" element={<Privacy />} />
                <Route exact path="/return-policy" element={<ReturnPolicy />} />
                <Route
                    exact
                    path="/shipping-policy"
                    element={<ShippingPolicy />}
                />
                <Route
                    exact
                    path="/terms-conditions"
                    element={<Termsandconditions />}
                />

                <Route
                    exact
                    path="/signup/user-details"
                    element={<SignupDetails />}
                />
                <Route
                    exact
                    path="/SignupSuccess"
                    element={<SignupSuccess />}
                />
                {/* -------protected routes------- */}

                <Route element={<ProtectedRoutes />}>
                    <Route
                        exact
                        path="/product-details"
                        element={<ProductDetails />}
                    />
                    <Route exact path="/Cart" element={<Cart />} />
                    <Route exact path="/orders" element={<Profile />} />
                    <Route exact path="/Cart" element={<Cart />} />
                    <Route exact path="/wishlist" element={<Wishlist />} />

                    <Route
                        exact
                        path="/orders/:id"
                        element={<OrderDetails />}
                    />

                    <Route
                        exact
                        path="/manage-Address"
                        element={<ManageAddress />}
                    />
                    <Route exact path="/refer" element={<Refer />} />
                    <Route exact path="/points" element={<Points />} />
                    <Route exact path="/help" element={<Help />} />
                    <Route
                        exact
                        path="/editprofile"
                        element={<EditProfile />}
                    />
                    <Route
                        exact
                        path="/OrderSuccessful/:id"
                        element={<OrderSuccessful />}
                    />
                    <Route
                        exact
                        path="/checkout/:cartid"
                        element={<Checkout />}
                    />
                </Route>
                <Route path="*" exact={true} element=<PageNotFound /> />
            </Routes>
        </>
    );
}

export default App;
