import React, { useEffect, useRef } from "react";
import addressclasses from "./address.module.css";
import { IoClose } from "react-icons/io5";

import { useForm } from "react-hook-form";
// import { TagsInput } from "react-tag-input-component";
import { Services } from "../../../services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { State } from "country-state-city";
function AddAddress(props) {
    const validatePhoneNumber = (value) => {
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        return phoneNumberPattern.test(value) || "Invalid phone number";
    };
    const alphaOnly = /^[A-Za-z]+$/i;
    const Navigate = useNavigate();
    let states = State.getStatesOfCountry("IN");
    // const numberRef = useRef();
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    // const [address, setAddress] = useState({});
    const [disable, setDisable] = useState(false);
    const [ContactNumber, setContactNumber] = useState("");
    const [contactError, setContactError] = useState(false);
    const [pincode, setpincode] = useState("");
    const [pincodeError, setpincodeError] = useState();
    const pincoderef = useRef();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();
    //warehouseId
    const warehouseId = sessionStorage.getItem("warehouseId");
    useEffect(() => {
        if (props.editable) {
            Services.getAddressbyid("GET", null, token, props.id)
                .then((response) => {
                    if (response.Status === 1) {
                        // setAddress(response?.Address);
                        setpincode(response?.Address.pincode);
                        setContactNumber(response?.Address.contactNumber);
                        reset({
                            ...response?.Address,

                            Status: response?.Address?.status,
                        });
                    } else if (response.Status === 0) {
                        toast.error(`${response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (response.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    const handleInputChange = (e) => {
        const { value } = e.target;
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        if (value.length > 10) {
            setValue("contactNumber", String(value).substring(0, 10));
            return;
        }
        if (phoneNumberPattern.test(value)) {
            setValue("contactNumber", value);
        }
    };
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const pincodeChange = (e) => {
        if (e.target.value.trim().length <= 6) {
            setpincodeError(false);
            setpincode(e.target.value);
        }
    };
    const onSubmit = (data) => {
        setDisable(true);

        if (pincode.trim().length != 6 || pincode.slice(0, 1) == 0) {
            setpincodeError(true);
            setDisable(false);
            pincoderef.current.focus();
            return;
        }

        let method = JSON.stringify({
            warehouseId: warehouseId,
            pincode: pincode,
            ...data,
        });

        if (!props.editable) {
            Services.Addaddress("POST", method, token)
                .then((res) => {
                    setDisable(false);
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.addressfetch();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    setDisable(false);
                    console.log(err);
                });
        } else if (props.editable) {
            Services.updateAddress("PUT", method, token, props.id)
                .then((res) => {
                    setDisable(false);
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.addressfetch();
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                    setDisable(false);
                });
        }
    };
    const numberChangeHandler = (e) => {
        if (e.target.value.length <= 10) {
            setContactError(false);
            setContactNumber(e.target.value);
            return;
        }
    };
    return (
        <div className={addressclasses.modal} onClick={CancelHandler}>
            <div
                className={addressclasses["modal-content"]}
                onClick={openhandler}>
                <div className={addressclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable ? "Update Address" : "Add Address "}
                        </h3>
                    </div>
                    <button
                        className={addressclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={addressclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={addressclasses["item-image-upload"]}>
                        <label htmlFor="Name">Name</label>
                        <div className={addressclasses["select"]}>
                            <input
                                type="text"
                                {...register("name", {
                                    required: true,
                                    validate: (value) => {
                                        return !!value.trim();
                                    },
                                })}
                            />

                            {errors?.name && (
                                <p className={addressclasses["error"]}>
                                    Name is required!
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={addressclasses["item-image-upload"]}>
                        <label htmlFor="addressAliasName">
                            Address Alias Name
                        </label>
                        <div className={addressclasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("addressAliasName", {
                                    required: true,
                                    validate: (value) => {
                                        return !!value.trim();
                                    },
                                })}>
                                <option value="">Type</option>
                                <option value="Home">Home</option>
                                <option value="Office">Office</option>
                                <option value="Others">Others</option>
                            </select>

                            {errors?.addressAliasName && (
                                <p className={addressclasses["error"]}>
                                    Address Alias Name is required!
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={addressclasses["item-image-upload"]}>
                        <label htmlFor="address1">Address 1</label>
                        <div className={addressclasses["select"]}>
                            <input
                                type="text"
                                {...register("address1", {
                                    required: true,
                                    validate: (value) => {
                                        return !!value.trim();
                                    },
                                })}
                            />

                            {errors?.address1 && (
                                <p className={addressclasses["error"]}>
                                    Enter a valid Address!
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={addressclasses["item-image-upload"]}>
                        <label htmlFor="address2">Address 2</label>
                        <div className={addressclasses["select"]}>
                            <input type="text" {...register("address2")} />

                            {errors?.address2 && (
                                <p className={addressclasses["error"]}>
                                    {errors?.address2?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={addressclasses["item-image-upload"]}>
                        <label htmlFor="landmark">Landmark</label>
                        <div className={addressclasses["select"]}>
                            <input type="text" {...register("landmark")} />

                            {errors?.landmark && (
                                <p className={addressclasses["error"]}>
                                    {errors?.landmark?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={addressclasses["item-image-upload"]}>
                        <label htmlFor="city">City</label>
                        <div className={addressclasses["select"]}>
                            <input
                                type="text"
                                {...register("city", {
                                    required: true,
                                    pattern: alphaOnly,
                                    validate: (value) => {
                                        return !!value.trim();
                                    },
                                })}
                            />

                            {errors?.city && (
                                <p className={addressclasses["error"]}>
                                    Enter a valid City!
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={addressclasses["item-image-upload"]}>
                        <label htmlFor="state">State</label>
                        <div className={addressclasses["select"]}>
                            <select
                                {...register("state", {
                                    required: "state is required!",
                                })}
                                defaultValue="">
                                <option value="" disabled>
                                    Select State
                                </option>
                                {states.length > 0 &&
                                    states.map((item) => (
                                        <option value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                            {errors?.state && (
                                <p className={addressclasses["error"]}>
                                    {errors?.state?.message}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={addressclasses["item-image-upload"]}>
                        <label htmlFor="pincode">Pincode</label>
                        <div className={addressclasses["select"]}>
                            <input
                                type={"number"}
                                onChange={pincodeChange}
                                value={pincode}
                                ref={pincoderef}
                            />

                            {pincodeError && (
                                <p className={addressclasses["error"]}>
                                    Enter a valid pincode
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={addressclasses["item-image-upload"]}>
                        <label htmlFor="contactNumber">Contact Numbers</label>
                        {/* contactNumber */}
                        <div className={addressclasses["select"]}>
                            <input
                                type="number"
                                {...register("contactNumber", {
                                    validate: validatePhoneNumber,
                                    required: true,
                                })}
                                onChange={handleInputChange}
                            />

                            {errors?.contactNumber && (
                                <p className={addressclasses["error"]}>
                                    Enter a valid number
                                </p>
                            )}
                        </div>
                    </div>

                    <div className={addressclasses["add-btn"]}>
                        <button
                            type="button"
                            className={addressclasses["cancel"]}
                            onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={disable}
                            className={addressclasses["add-category-btn"]}>
                            Deliver to this Address
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddAddress;
