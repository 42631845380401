import latestClasses from "./latest.module.css";
import "../../../App.css";
import image from "../../../Assets/image.png";
import Skeleton from "react-loading-skeleton";
function Product(props) {
    return (
        <>
            <div
                className={latestClasses["product"]}
                style={{ height: "20rem" }}>
                <div className={latestClasses["product-image"]}>
                    <Skeleton style={{ width: "100%", height: "10rem" }} />
                </div>
                <div
                    className={latestClasses["product-desc"]}
                    style={{ justifyContent: "center" }}>
                    <Skeleton
                        width={150}
                        count={4}
                        style={{ marginBottom: "0.6rem" }}
                    />
                </div>
            </div>
        </>
    );
}

export default Product;
