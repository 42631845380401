import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import useStore from "./store";
const Useauth = () => {
    const token = sessionStorage.getItem("token");
    console.log(token !== null);
    return token !== null;
};

const ProtectedRoutes = () => {
    const setLogin = useStore((state) => state.setLoginModal);
    const isAuth = Useauth();
    if (isAuth == false) {
        setLogin(true);
    }
    return isAuth ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
