import React from "react";
import { toast } from "react-toastify";
import { Services } from "../../../services";
import { useForm } from "react-hook-form";
import cancelClasses from "./orderDetails.module.css";
function Modal(props) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };
    const onSubmit = (data) => {
        props.setCancelLoad(true);
        const body = JSON.stringify({ ...data });
        Services.CancelOrder("PUT", body, token, props.orderid)
            .then((response) => {
                props.setmodal(false);
                props.setCancelLoad(false);
                if (response.Status === 1) {
                    props.getOrderdetails();
                    toast.success(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                props.setCancelLoad(false);
                props.setview(false);
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    return (
        <div className={cancelClasses["modal"]} onClick={CancelHandler}>
            <div
                className={cancelClasses["modal-content"]}
                onClick={openhandler}>
                <button className={cancelClasses["close-btn"]}>
                    {/* <FaRegWindowClose size={27} /> */}
                </button>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={cancelClasses["form-control"]}>
                        <label htmlFor="Cancellation Reason">
                            Reason for Cancellation
                        </label>
                        <textarea
                            placeholder="reason"
                            rows={5}
                            {...register("cancellationReason", {
                                required: "Cancellation Reason is required!",
                            })}
                        />
                        {errors?.CancellationReason && (
                            <p className={cancelClasses.error}>
                                {errors?.CancellationReason?.message}
                            </p>
                        )}
                    </div>
                    {props.type == 2 && (
                        <p className={cancelClasses["cancel-msg"]}>
                            If you already paid for this order, refund will be
                            processed with in 5-7 business day after deducting
                            the bank charges of Rs{" "}
                            {Number(props.payment).toFixed(2)}
                        </p>
                    )}
                    <div className={cancelClasses["control-btns"]}>
                        <button
                            className={cancelClasses["delete-btn"]}
                            type="submit">
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Modal;
