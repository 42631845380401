import React, { useEffect, useState } from "react";
import Loader from "../../../Utilities/loader";
import { toast } from "react-toastify";
import { Services } from "../../../services";
import OrderClasses from "./orderDetails.module.css";
import ProfileLayout from "../profileLayout";
import image from "../../../Assets/image.png";
import { AiOutlineDownload } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import Moment from "react-moment";
import Processed from "./Processed";
import Cancelled from "./Cancelled";
import CancelModal from "./CancelModal";
import useStore from "../../../store";
import { Handlers } from "../../../Utilities/Handlers";
import Cancel from "../../../Utilities/circleLoad";
//getCart
function OrderDetails() {
    const Navigate = useNavigate();
    const { id } = useParams();
    const [cancelLoad, setCancelLoad] = useState(false);
    const setcartCount = useStore((state) => state.setcartCount);
    const [modal, setModal] = useState(false);
    const [orderDetails, setOrderDetails] = useState([]);
    const [orderStatus, setOrderStatus] = useState(0);
    const [load, setload] = useState(true);
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };

    const getOrderdetails = () => {
        Services.OrderByOrderId("GET", null, token, id)
            .then((response) => {
                if (response.Status === 1) {
                    setOrderDetails(response.Orders);
                    if (response.Orders.orderStatus === 1) {
                        setOrderStatus(3);
                    } else if (response.Orders.orderStatus === 2) {
                        setOrderStatus(1);
                    } else if (response.Orders.orderStatus === 3) {
                        setOrderStatus(2);
                    }
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setload(false);
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    useEffect(() => {
        getOrderdetails();
        // eslint-disable-next-line
    }, []);
    const downloadHandler = () => {
        const url = orderDetails.invoiceFile;
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.download = url.split("/").pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    const reOrderHandler = (e) => {
        let body = JSON.stringify({
            orderId: id,
            warehouseId: orderDetails.warehouseId,
        });
        Services.reorder("POST", body, token)
            .then((response) => {
                if (response.Status === 1) {
                    Handlers.getCart(setcartCount);
                    toast.success(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setload(false);
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    return (
        <>
            {cancelLoad && <Cancel />}
            {modal && (
                <CancelModal
                    payment={orderDetails?.bankCharges}
                    setmodal={setModal}
                    orderid={orderDetails.orderId}
                    getOrderdetails={getOrderdetails}
                    setCancelLoad={setCancelLoad}
                    type={orderDetails?.paymentType}
                />
            )}
            <ProfileLayout isactive={"profile"}>
                {load ? (
                    <Loader />
                ) : (
                    <div className={OrderClasses["order-details-wrapper"]}>
                        <div className={OrderClasses["order-details-back"]}>
                            <button
                                onClick={() => {
                                    Navigate(-1);
                                }}>
                                Back
                            </button>
                            <div className={OrderClasses["re-order"]}>
                                <button onClick={reOrderHandler}>
                                    Re order
                                </button>

                                {orderDetails.isShipmentCreated != 0 && (
                                    <button
                                        onClick={() => {
                                            window.open(
                                                orderDetails.trackingUrl,
                                                "_blank"
                                            );
                                        }}>
                                        Track Shipment
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className={OrderClasses["order-placed"]}>
                            <p>
                                Ordered Placed on{" "}
                                <span>
                                    {" "}
                                    <Moment format=" MMMM Do YYYY, h:mm a">
                                        {new Date(orderDetails.updatedAt)}
                                    </Moment>
                                </span>
                            </p>
                            <p>
                                Expected Delivery on{" "}
                                <span>{orderDetails.expectedDelivery}</span>
                            </p>
                        </div>
                        <div className={OrderClasses["order-status"]}>
                            <h3>Order Status</h3>
                            {orderDetails.orderStatus === 1 ||
                            orderDetails.orderStatus === 2 ||
                            orderDetails.orderStatus === 3 ||
                            orderDetails.orderStatus === 4 ? (
                                <Processed orderStatus={orderStatus} />
                            ) : (
                                <Cancelled
                                    status={
                                        orderDetails.isRefundinitiated == 1
                                            ? 2
                                            : 1
                                    }
                                />
                            )}
                        </div>
                        <div className={OrderClasses["order-items"]}>
                            <h3>Order List</h3>
                            {orderDetails?.orderitems?.map((items) => (
                                <div className={OrderClasses["order-item"]}>
                                    <img
                                        src={
                                            items.imageUrl !== "NA"
                                                ? items.imageUrl
                                                : image
                                        }
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = image;
                                        }}
                                        alt="product"
                                    />
                                    <div className={OrderClasses["order-desc"]}>
                                        <h3>{items.productName}</h3>
                                        <p>Quantity:{items.quantity}</p>
                                        <p>Company: {items?.manufacturedBy}</p>
                                        <h3>
                                            Price:₹{items.sellingPrice}
                                            <span>
                                                (Inclusive of all taxes)
                                            </span>
                                        </h3>
                                        <p>
                                            MRP <del>₹{items.price}</del>{" "}
                                            <span>
                                                You Save ₹
                                                {Number(
                                                    items.price -
                                                        items.sellingPrice
                                                ).toFixed(2)}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={OrderClasses["delivery-address"]}>
                            <h3>Delivery Address</h3>
                            <p>{orderDetails?.name}</p>
                            <p>
                                {orderDetails?.address1}
                                {orderDetails?.address2}{" "}
                                {orderDetails?.landmark} {orderDetails?.pincode}{" "}
                            </p>
                            <p>{orderDetails?.contactNumber}</p>
                        </div>
                        {(orderDetails.orderStatus == 5 ||
                            orderDetails.orderStatus == 7) && (
                            <div className={OrderClasses["delivery-address"]}>
                                <h3>Cancellation</h3>
                                <div className={OrderClasses["price"]}>
                                    <p style={{ alignSelf: "flex-start" }}>
                                        Reason
                                    </p>
                                    <p
                                        style={{
                                            maxWidth: "300px",
                                            width: "100%",
                                            wordBreak: "break-all",
                                        }}>
                                        {orderDetails?.cancellationReason}
                                    </p>
                                </div>
                                <div className={OrderClasses["price"]}>
                                    <p>Refund Amount</p>
                                    <p
                                        style={{
                                            maxWidth: "300px",
                                            width: "100%",
                                        }}>
                                        {" "}
                                        ₹
                                        {Number(
                                            orderDetails.refundAmount
                                        )?.toFixed(2)}
                                    </p>
                                </div>
                            </div>
                        )}
                        <div className={OrderClasses["delivery-address"]}>
                            <h3>Price Details</h3>
                            <div className={OrderClasses["price"]}>
                                <p>SubToal</p>
                                <p>₹{orderDetails?.subTotal}</p>
                            </div>
                            <div className={OrderClasses["price"]}>
                                <p>Discount</p>
                                <p>₹{orderDetails.promoAmount}</p>
                            </div>
                            {/* <div className={OrderClasses["price"]}>
                                <p>Points Redeemed (200)</p>
                                <p>₹0</p>
                            </div> */}
                            <div className={OrderClasses["price"]}>
                                <p>Delivery Charges</p>
                                <p>₹{orderDetails.deliveryCharges}</p>
                            </div>

                            <div className={OrderClasses["price"]}>
                                <p>COD Charges</p>
                                <p>
                                    ₹
                                    {Number(orderDetails.codCharges).toFixed(2)}
                                </p>
                            </div>
                            <div
                                className={`${OrderClasses["price"]} ${OrderClasses.total}`}>
                                <p style={{ fontWeight: "bold" }}>Total</p>
                                <p style={{ fontWeight: "bolder" }}>
                                    ₹{orderDetails.grandTotal}
                                </p>
                            </div>
                        </div>
                        <div className={OrderClasses["order-btns"]}>
                            <button onClick={downloadHandler}>
                                Download Invoice <AiOutlineDownload />
                            </button>
                            {orderDetails.orderStatus !== 1 &&
                                orderDetails.orderStatus !== 3 &&
                                orderDetails.orderStatus !== 5 &&
                                orderDetails.orderStatus !== 6 &&
                                orderDetails.orderStatus !== 7 && (
                                    <button
                                        onClick={() => {
                                            setModal(true);
                                        }}>
                                        Cancel Order{" "}
                                    </button>
                                )}
                        </div>
                    </div>
                )}
            </ProfileLayout>
        </>
    );
}

export default OrderDetails;
