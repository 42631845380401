import React from "react";
import Stepper from "react-stepper-horizontal";
import OrderClasses from "./orderDetails.module.css";
function Processed(props) {
    return (
        <div className={OrderClasses["stepper2"]}>
            {" "}
            <Stepper
                defaultBarColor={"grey"}
                activeColor={"green"}
                completeColor={"green"}
                activeTitleColor={"green"}
                completeTitleColor={"green"}
                defaultBorderWidth={10}
                steps={[
                    {
                        title: (
                            <p className={OrderClasses["title"]}>Processing</p>
                        ),
                    },
                    {
                        title: (
                            <p className={OrderClasses["title"]}>Cancelled</p>
                        ),
                    },
                    {
                        title: (
                            <p
                                className={OrderClasses["title"]}
                                style={{ padding: 0, margin: 0 }}>
                                Refund initiated
                            </p>
                        ),
                    },
                ]}
                activeStep={2}
            />
        </div>
    );
}

export default Processed;
