import React from "react";
import Navbar from "../HomePage/Navbar/navbar";
import Layout from "../../Utilities/layout";
import Footer from "../HomePage/footer/footer";
import PolicyClasses from "./Privacy.module.css";
function termsAndConditions() {
    return (
        <>
            <Layout>
                <div className={PolicyClasses["policy-wrapper"]}>
                    <h1>Terms and conditions</h1>
                    <div className={PolicyClasses["policy"]}>
                        <p>
                            BY CONTINUING TO ACCESS AND USE THIS WEBSITE/MOBILE
                            APPLICATION YOU CONFIRM THAT YOU ACCEPT OUR TERMS
                            AND CONDITIONS OF USE SET OUT BELOW. IF YOU DO NOT
                            ACCEPT THE TERMS AND CONDITIONS, YOU MUST LEAVE THIS
                            WEBSITE/MOBILE APPLICATION.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>About us</h2>
                        <p>
                            genifast.in website and the mobile application is
                            owned and provided by Vaishnavi Biotech having its
                            registered address at No. #105, First Floor, Plot No
                            A-1, GBS Enclave, Moti Valley, Trimulgherry,
                            Secunderabad, Hyderabad – 500015, Telangana
                            (hereinafter “GeniFast”. If you need any information
                            or have a complaint about this website/mobile
                            application or any of our services please contact us
                            by one of the following methods. This document is
                            published in accordance with the provisions of Rule
                            3 (1) of the Information Technology (Intermediaries
                            Guidelines) Rules, 2011 that require publishing the
                            rules and regulations, privacy policy and Terms of
                            Use for access or usage of www.genifast.in or the
                            mobile application ‘GeniFast’.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Professional Standards</h2>
                        <p>
                            GeniFast services are rendered either from its own
                            licensed premises or from the licensed premises of
                            its associates / network pharmacies which are
                            undertaken under strict supervision of the qualified
                            and registered pharmacists. All pharmacists are
                            bound by codes of professional ethics and conduct. 
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Privacy</h2>
                        <p>
                            Your privacy and that of any other person whose
                            information you provide to us is important to us.
                            Please see our privacy policy for details of what
                            information we collect and how we will use and
                            protect it. We do everything possible to use it
                            carefully and sensibly. This information is never
                            shared with other companies or third party service
                            providers
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Registration</h2>
                        <p>
                            By registering and using the services you agree that
                            you are eighteen years or above and you are not
                            debarred by any law to contract and you agree to
                            have read and accepted the terms and conditions
                            provided herein  Visitors to this website/mobile
                            application are required to register in order to use
                            its facilities/ services. We are not under any
                            obligation to accept a request for registration and
                            reserve the right to suspend or terminate access at
                            any time if your continued use is believed to
                            prejudice us or other users. By registering to use
                            this website /mobile application you confirm that
                            the information you provide during the registration
                            process is accurate and complete. You agree to
                            update your registration details promptly if there
                            are any changes. All registration information you
                            provide will be kept secure and processed in
                            accordance with our privacy policy 
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Username and password</h2>
                        <p>
                            During the registration process, you may be
                            allocated, or invited to choose, your own username
                            and password which will be unique to you. You are
                            responsible for keeping your username and password
                            confidential and for ensuring that they are not used
                            by any other person.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Offers, promotions, software and downloads</h2>
                        <p>
                            GeniFast and other third parties with whom we have a
                            business relationship may occasionally promote their
                            goods or services on the website or mobile
                            application or through other direct marketing
                            initiatives or may make software and other materials
                            available for you to purchase or download. Whilst we
                            try to encourage third parties to offer good quality
                            products, and services and materials at competitive
                            prices we have no control over them or other third
                            parties, we do not endorse the products or services
                            they offer, or give you any assurance that they will
                            be suitable for your needs. It is your
                            responsibility to satisfy yourself in this regard
                            and we have no liability in connection with the
                            same. All promotions are for limited period and
                            subject to special terms and conditions, which are
                            in addition and not to the terms and condition
                            stated herein.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Ownership of materials and licence terms</h2>
                        <p>
                            This website or mobile application, the materials
                            and software on it, or provided to you through it
                            are protected by copyright, trade mark and other
                            intellectual property rights and laws throughout the
                            world and are owned by, or are licensed to GeniFast
                            and/or third parties. You are permitted to display
                            the materials on this mobile application on a
                            computer screen/mobile screen and, save for
                            restricted access documents, to download and print a
                            hard copy for your personal use or for obtaining
                            products or services from us provided you do not
                            alter or remove any of the content or any part of
                            the website/mobile application without our express
                            permission to do so and that you do not change or
                            delete any copyright, trade mark or other
                            proprietary notices.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>You agree not to:</h2>
                        <ul>
                            <li>
                                Copy, reproduce, store (in any medium or
                                format), distribute, transmit, modify, create
                                derivate works from all or any part of this
                                website/mobile application or the materials or
                                software on it, or provided to you through it
                                without our prior written consent (which may be
                                given or withheld in our absolute discretion)
                            </li>
                            <li>
                                Use this website/mobile application or any of
                                the materials or software on it, or provided to
                                you through it, for:
                                <ul>
                                    <li>
                                        Any unlawful purpose or in contravention
                                        of applicable law
                                    </li>
                                    <li>
                                        Commercial exploitation without our
                                        prior written consent
                                    </li>
                                    <li>
                                        Any purpose or in any manner that may
                                        give a false or misleading impression of
                                        us, our staff or our services
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Use, upload or transmit
                                <ul>
                                    <li>
                                        Any material that is defamatory,
                                        offensive, obscene or otherwise
                                        unlawful, or which may cause offence or
                                        distress, or which may affect or
                                        infringe the rights of any other person
                                    </li>
                                    <li>
                                        Any device, software, file or mechanism
                                        which may interfere with the proper
                                        operation of this website or our systems
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Establish a link to this mobile application from
                                any other website, intranet or extranet site
                                without our prior written consent
                            </li>
                            <li>
                                Decompile, disassemble or reverse engineer (or
                                attempt to do any of them) any of the software
                                or other materials provided on or through this
                                website/mobile application
                            </li>
                            <li>
                                Do anything that may interfere with or disrupt
                                this website/mobile application or our service
                            </li>
                            <li>
                                Encourage or permit others to do any of the
                                above
                            </li>
                            <li>
                                In the event that you do not comply with the
                                above restrictions, any person affected by your
                                actions may bring a claim against you and/or
                                GeniFast will pursue a claim against you for any
                                losses and costs (including legal costs) we may
                                suffer as a result of your actions.
                            </li>
                        </ul>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Suitability of materials</h2>
                        <p>
                            We do not give any assurance that the materials
                            provided or available to you on or through this
                            website/mobile application are suitable for your
                            requirements or that they will be secure, error or
                            virus free and we will have no liability in respect
                            of those materials.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Mobile application availability</h2>
                        <p>
                            This mobile application is provided free of charge
                            and we make no guarantee that it will be
                            uninterrupted or error free. We reserve the right to
                            modify, suspend or withdraw the whole or any part of
                            the website/mobile application or any of its content
                            at any time without notice and without incurring any
                            liability.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Monitoring</h2>
                        <p>
                            We may monitor activity and content on this
                            website/mobile application and may take any action
                            we consider appropriate if we suspect you may be in
                            breach of these Terms and Conditions including
                            suspending, attaching conditions to or terminating
                            your access and/or notifying the authorities or
                            relevant regulators of your activities.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Security</h2>
                        <p>
                            We employ security technology as detailed in
                            our privacy policy however, Internet transmissions
                            are never completely private or secure and there is
                            a risk, therefore, that any message or information
                            you send to us from this website may be intercepted
                            and potentially read by others. We will have no
                            liability in respect of any transmissions you send
                            to us and you do so entirely at your own risk.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Accuracy of Information</h2>
                        <p>
                            We take care to ensure that all information
                            available on our website/mobile application about
                            our business, services and any products mentioned is
                            accurate. However, these are continually developing
                            and, occasionally, the information may be out of
                            date. Medical, commercial and legal practice change
                            frequently and the content on this website/mobile
                            application, in of any newsletters and in other
                            items offering guidance have been prepared for
                            general interest only and are not a substitute for
                            specific medical, legal or other professional advice
                            and should not be read or used as such. For accurate
                            up-to-date information you should contact us and/or
                            your doctor directly.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Disclaimer</h2>
                        <p>
                            GENIFAST DOES NOT WARRANT OR REPRESENT THAT THE
                            MATERIAL ON THIS WEBSITE/MOBILE APPLICATION IS
                            ACCURATE, COMPLETE OR CURRENT OR THAT THE WEBSITE
                            WILL BE FREE OF DEFECTS OR VIRUSES. NOTHING
                            CONTAINED IN THE PAGES OF THIS WEBSITE/MOBILE
                            APPLICATION SHOULD BE CONSTRUED AS MEDICAL,
                            COMMERCIAL, LEGAL OR OTHER PROFESSIONAL ADVICE.
                            DETAILED PROFESSIONAL ADVICE SHOULD BE OBTAINED
                            BEFORE TAKING OR REFRAINING FROM ANY ACTION BASED ON
                            ANY OF THE INFORMATION OR MATERIAL CONTAINED IN THIS
                            WEBSITE/MOBILE APPLICATION OR ANY COMMUNICATIONS
                            PROVIDED TO YOU AS A RESULT OF YOUR REGISTRATION.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Liability</h2>
                        <p>
                            WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE (IN
                            CONTRACT, NEGLIGENCE OR OTHERWISE) WHERE:
                            <ul>
                                <li>
                                    THERE IS NO BREACH OF A LEGAL DUTY OF CARE
                                    OWED TO YOU BY US.
                                </li>
                                <li>
                                    THE LOSS OR DAMAGE IS NOT A REASONABLY
                                    FORESEEABLE RESULT OF ANY SUCH BREACH; OR
                                </li>
                                <li>
                                    ANY LOSS OR DAMAGE OR INCREASE IN LOSS OR
                                    DAMAGE RESULTS FROM A BREACH BY YOU OF THESE
                                    TERMS AND CONDITIONS.THE MAXIMUM LIABIITY OF
                                    GENIFAST SHALL BE LIMITED TO THE COST OF THE
                                    PRODUCTS PURCHASED OR SERVIES AVAILED BY THE
                                    CUSTOMER, GEBIFAST OR ITS DIRECTORS SHALL
                                    NOT BE LIABLE FOR ANY INDRECT, CONSEQUENTIAL
                                    OR OTHER DAMAGES CAUSED TO THE USER
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Third party rights</h2>
                        <p>
                            Nothing in these Terms and Conditions is intended to
                            nor shall it confer a benefit on any third party
                            under the Contracts and a person who is not a party
                            to these Terms and Conditions has no rights to
                            enforce them.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Waiver</h2>
                        <p>
                            No delay or decision not to enforce rights under
                            these terms and conditions will constitute a waiver
                            of the right to do so and will not affect rights in
                            relation to any subsequent breach.
                        </p>
                        <p>
                            WE RESERVE THE RIGHT TO CHANGE THESE TERMS AND
                            CONDITIONS AT ANY TIME. THE NEW VERSION WILL BE
                            POSTED ON THIS WEBSITE/MOBILE APPLICATION AND WILL
                            TAKE EFFECT IMMEDIATELY UPON POSTING
                        </p>
                        <p>
                            IF YOU USE THE WEBSITE/MOBILE APPLICATION AFTER THE
                            NEW TERMS AND CONDITIONS HAVE COME INTO EFFECT, YOU
                            WILL BE INDICATING YOUR AGREEMENT TO BE BOUND BY THE
                            NEW TERMS AND CONDITIONS
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>PRESCRIPTION ORDER</h2>
                        <p>
                            THE FOLLOWING TERMS APPLY TO YOUR USE OF THE
                            GENIFAST PRESCRIPTION ORDERING SERVICE. THESE ARE
                            ADDITIONAL TO AND SUPPLEMENT OUR GENERAL
                            WEBSITE/MOBILE APPLICATION TERMS AND CONDITIONS
                            WHICH ALSO APPLY. IF YOU HAVE ANY QUESTIONS
                            CONCERNING THE SERVICE, PLEASE CONTACT US.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Prescriptions for persons other than yourself:</h2>
                        <p>
                            If the prescription is of a person other than
                            yourself, you will need to have the authority of the
                            person whose prescription it is to have it dispensed
                            by us. By placing an order to dispense another
                            person’s prescription you confirm that you have
                            their authority to do so.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Age Restrictions:</h2>
                        <p>
                            We only accept Prescription Orders from customers
                            who are 18 years old or over although they may order
                            prescriptions for persons who are under 18. By
                            placing an order, you confirm that you are at least
                            18 and legally eligible to contract.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Residency:</h2>
                        <p>
                            We accept Orders from and despatch orders to
                            addresses in selected locations in India. 
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Binding agreement:</h2>
                        <p>
                            No Order placed by you will be binding on us until
                            we have confirmed the order to you by phone, email
                            or in writing. We reserve the right to reject any
                            order. All orders are delivered subject to receipt
                            of valid prescription and availability.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Verification of prescription:</h2>
                        <p>
                            When we receive an original prescription from you,
                            our pharmacist will verify it against the
                            information provided to us at the time the order was
                            placed. In the event that the information does not
                            match with your original order as per the
                            prescription, we may try to contact you using the
                            information in your registration. If we cannot
                            contact you and we are unable to dispense the
                            item(s) on your Prescription Order we reserve the
                            right to return your prescription to you.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Non-Receipt of Prescription:</h2>
                        <p>
                            If you place a Prescription Order and we do not
                            receive the relevant original prescription, we will
                            not have any liability to you and it will be your
                            responsibility to contact us within 7 days regarding
                            your prescription order.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Validation Of Prescription:</h2>
                        <p>
                            Validation of prescription services through our
                            partner Registered Medical Practitioner shall be
                            made available to the User only upon request of the
                            User and in the following situations:
                            <ol>
                                <li>
                                    The existing prescription has expired and
                                    the User is unable to get an appointment
                                    with his Registered Medical Practitioner or
                                </li>
                                <li>
                                    The prescription is not legible and the
                                    prescribing Registered Medical Practitioner
                                    is unavailable to validate the prescription;
                                    or
                                </li>
                                <li>
                                    The prescription is not as per the standards
                                    defined by Medical Council of India,
                                    including, missing Doctor details, patient
                                    details etc; or
                                </li>
                                <li>
                                    If the prescription contains schedule X
                                    drugs / unusual quantities.
                                </li>
                            </ol>
                        </p>
                        <p>
                            The User understands and agrees that the validation
                            of prescription services provided herein are only on
                            request and are intended for general purpose only
                            and should not be used in case of medical
                            emergencies and serious illness issue where physical
                            consultation is recommended and at no point these
                            services are intended to replace the physical
                            consultation.{" "}
                        </p>
                        <p>
                            The User also agrees and understands that for
                            providing the validation of prescription service
                            GeniFast will have to share the prescription with
                            its partner Registered Medical Practitioner and by
                            requesting the services the User confirms its
                            consent to share his/her prescription with
                            GeniFast's partner Registered Medical Practitioner.
                            Upon the request of the User, the prescription of
                            the User shall be shared with GeniFast's partner
                            Registered Medical Practitioner and post
                            consultation or review of the prescription, if the
                            Registered Medical Practitioner deems fit, he/she
                            may validate the prescription shared with him/her of
                            the User. However, at all time the Registered
                            Medical Practitioner has the discretion to refuse
                            consultation or validation of the prescription if
                            the Registered Medical Practitioner is of the
                            opinion that a physical consultation is required
                            before a prescription is validated. User agrees that
                            the Registered Medical Practitioner will use the
                            technology of GeniFast for rendering the above
                            services and they share the information with
                            GeniFast of its affiliated partners for rendering
                            the services.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Disclaimer:</h2>
                        <p>
                            The User understands and agrees GeniFast is
                            providing a technology service as an intermediary
                            and that all the services are provided to the User
                            directly by the Registered Medical Practitioner with
                            regard to any health issues, consultation,
                            validation, unsatisfactory services etc. availed by
                            the User under the above mentioned clause services
                            are between the User and the Registered Medical
                            Practitioner and that at no point in time GeniFast
                            shall be held responsible for the same.{" "}
                        </p>
                        <p>
                            Further, notwithstanding anything contained herein,
                            GeniFast shall not be liable for:
                            <ul>
                                <li>
                                    any medical negligence, prescription of any
                                    wrong medication or treatment on part of the
                                    Registered Medical Practitioner.
                                </li>
                                <li>
                                    any type of inconvenience suffered by the
                                    User due to a failure on the part of the
                                    Registered Medical Practitioner in providing
                                    the agreed services or to make
                                    himself/herself available at the appointed
                                    time, inappropriate treatment, or similar
                                    difficulties.
                                </li>
                                <li>
                                    any misconduct or inappropriate behaviour by
                                    the Registered Medical Practitioner.
                                </li>
                                <li>
                                    cancellation or rescheduling of booked
                                    appointment.
                                </li>
                                <li>
                                    any medical eventualities that might occur
                                    subsequent to using the services of the
                                    Registered Medical Practitioner.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Subscription:</h2>
                        <p>
                            Under the subscription model, User can subscribe for
                            his/her/its medicine requirement by choosing a
                            subscription plan that best suit the User's need and
                            the User can customise the same according to the
                            User's requirement. All orders under the
                            subscription plan shall be automatically placed in
                            accordance with the chosen subscription plan. All
                            orders under the subscription model shall be subject
                            to all the other applicable terms and conditions
                            mentioned herein along with all statutory
                            requirements for processing an order.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Clubbing of offers:</h2>
                        <p>
                            Any ongoing offer or promotional scheme cannot be
                            clubbed with the orders placed under the
                            subscription plan. All orders under the subscription
                            plan shall be processed based on the standard
                            discount and promotional scheme available at the
                            time of processing any order under the subscription
                            plan. GeniFast shall have the sole discretion to
                            decide with regard to the offers or scheme that
                            shall be applicable to the orders placed under the
                            subscription plans.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Non-Availability/Suitability of Products</h2>
                        <p>
                            If any of the items on your prescription are not
                            available, or are not suitable for dispensing
                            through this service, we will try to contact you
                            using the information in your registration to inform
                            you about the same.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Delivery of prescriptions drugs:</h2>
                        <p>
                            Prescription items can only be dispensed once we
                            have received your original paper prescription or
                            online prescription from GeniFast registered doctor.
                            All items are delivered to the address provided by
                            you in your registration or to an alternative
                            address if directed by you. You acknowledge and
                            accept that items will need to be signed for on
                            delivery and authorise any person at your chosen
                            delivery address to sign for the items as your
                            authorised representative. In the event that the
                            packaging is opened after delivery, we will have no
                            liability to you for lost or damaged items or for
                            what third parties find out about you as a result.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>No Warranties: </h2>
                        <p>
                            GeniFast makes no warranty that:
                            <ul>
                                <li>
                                    the application will meet your requirements;{" "}
                                </li>
                                <li>
                                    the application will be available on an
                                    uninterrupted, timely, secure, or error-free
                                    basis;
                                </li>
                                <li>
                                    the any results that may be obtained from
                                    the use of the application or any services
                                    offered through the Website will be accurate
                                    or reliable
                                </li>
                                <li>
                                    GeniFast disclaims all warranties as to the
                                    accuracy, completeness or adequacy of such
                                    information.
                                </li>
                            </ul>
                        </p>
                        <p>
                            GeniFast disclaims all liabilities arising from
                            manufacturing defects and the claims if any with
                            regard to defect in manufacturing or otherwise shall
                            be made directly on the manufacturer whose
                            information in provided on the product. GeniFast may
                            at its sole discretion assist User in providing any
                            information that may be required with regard to the
                            claim.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Order, Acceptance & Delivery: </h2>

                        <p>
                            Placement of an order either directly (in case of a
                            repeat order or continuous drug administration as
                            prescribed) shall constitute an agreement with us.
                            All orders are subject to acceptance and
                            confirmation of delivery date and time. Mere receipt
                            of the prescription or an order either directly from
                            you or from the registered medical practitioner does
                            not amount to acceptance of your order nor guarantee
                            that the drugs will be delivered as per your
                            requirement within a specific time. All orders are
                            subject to availability of stocks. 
                        </p>
                        <p>
                            All orders made by the customers shall be subject to
                            various laws, including Drugs and Cosmetics Act (as
                            may be amended from time to time). We do not accept
                            orders for certain drugs and cosmetics, unless the
                            same is prescribed by the registered medical
                            practitioner licensed by the Medical Counsel of
                            India. We do not verify the authenticity of the
                            orders placed by you, where the prescriptions are
                            received directly from a registered medical
                            practitioner licensed by the Medical Counsel of
                            India or your family doctor, who has placed the
                            order on your behalf after your authorization. All
                            drugs and cosmetics will be deliver through a
                            delivery agent without disclosing your personal
                            information or prescriptions in compliance with the
                            applicable laws either directly to the patient or
                            its authorised person. On delivery GeniFast shall be
                            entitled to full payment for the same.
                        </p>
                        <p>
                            You agree that the prices mentioned on our website
                            or mobile app are approximate estimates of the
                            actual/exact price of the drugs. The actual/exact
                            price of the drug will depend upon its batch number
                            and will be mentioned in the invoice dispatched to
                            you along with your order. In case a pre-payment has
                            been made by you basis the price estimate at the
                            time of placing the order, should there be a
                            shortfall upon generation of invoice, you undertake
                            to pay the shortfall through any of the payment
                            modes made available to you by us. In case the
                            payment made by you basis the price estimate at the
                            time of placing the order is more than the price as
                            per the generated invoice, the excess payment will
                            be refunded back to you in your bank account, credit
                            card, debit card or wallet which may have been used
                            by you at the time of making the payment.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Notification of errors:</h2>
                        <p>
                            You should check the items dispensed to you
                            carefully promptly upon receipt. If you believe
                            there may have been a dispensing error, you should
                            contact us immediately and should not take or use
                            any of the items.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Delivery of fulfilled prescriptions:</h2>
                        <p>
                            Prescription medicines will require a doorstep
                            signature. Prescription items can only be dispensed
                            once we have received your original paper
                            prescription or online prescription from GeniFast
                            registered doctor. All items are delivered to the
                            address provided by you in your registration or to
                            an alternative address if directed by you. You
                            acknowledge and accept that items will need to be
                            signed for on delivery and authorise any person at
                            your chosen delivery address to sign for the items
                            as your authorised representative. In the event that
                            the packaging is opened after delivery, we will have
                            no liability to you for lost or damaged items or for
                            what third parties find out about you as a result.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Delivery and Packaging Costs:</h2>
                        <ul>
                            <li>
                                The Delivery charges for the shipment will be
                                displayed at the time of placing the Order. The
                                Delivery charges are calculated as per the
                                weight of the Medicines.
                            </li>
                            <li>
                                Any delivery charges paid by the customer shall
                                not be eligible for refund of the order is
                                modified or replaced by the customer due to
                                change in prescription or otherwise. 
                            </li>
                        </ul>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Acceptance of the Goods: </h2>
                        <p>
                            We request you to verify the complete details and
                            the documents provided to you at before acceptance
                            of the Goods. Any complaints with regard to shortage
                            of Goods, defects or otherwise will not be
                            entertained by GeniFast once the same are accepted
                            at the time of delivery. 
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>WARNING:</h2>
                        <p>
                            YOU MUST CHECK ALL ITEMS DISPENSED TO YOU AND SHOULD
                            NOT TAKE ANY MEDICATION THAT APPEARS TO HAVE BEEN
                            TAMPERED WITH OR WHICH MAY HAVE BEEN DISPENSED IN
                            ERROR. FAILURE TO ABIDE BY THIS WARNING COULD
                            SERIOUSLY DAMAGE YOUR HEALTH AND GeniFast SHALL NOT
                            BE HELD LIABLE.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Information Technology Act:</h2>
                        <p>
                            Certain laws in India prohibit and impose
                            restriction on use of the Website and you are
                            advised to make familiar with those laws and not to
                            post any information or messages that are, or that
                            may be construed, as being malicious, defamatory,
                            inappropriate, slanderous, pornographic or otherwise
                            sexually oriented or that makes attacks on or the
                            otherwise opines or comments on any individuals or
                            groups of individuals, educational institutions or
                            any other entities whatsoever (whether companies,
                            firms, or any other institutions). You also agree
                            not to post any information to which you do not have
                            copyrights or other appropriate permissions to post
                            in public forum. Your failure to comply with these
                            terms may result in removal of your postings without
                            prior notice to User. The IP address of all posts is
                            recorded to aid in enforcing these conditions.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Other Laws:</h2>
                        <p>
                            Certain laws require to maintain data with respect
                            to the services, Goods and other personal
                            information in a prescribed format and GeniFast will
                            use all the information to the extent required in
                            compliance with the applicable laws and as may be
                            directed or amended from time to time.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Force Majeure:</h2>
                        <p>
                            GeniFast shall not be liable and to the extent, that
                            the performance or delay in performance of any of
                            its obligations are prevented, restricted, delayed
                            or interfered with due to circumstances beyond the
                            reasonable control and without the fault or
                            negligence of such Party, including but not limited
                            to change in legislation, fire, flood, explosion,
                            epidemic, accident, act of God, war, riot, strike,
                            lockout, traffic or other concerted act of workmen
                            and/or act of Government. GeniFast may at its sole
                            discretion withdraw the services or Goods if a Force
                            Majeure event occurs.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Governing Law and Jurisdiction:</h2>
                        <p>
                            The Terms of Use are governed by and constructed in
                            accordance with the laws of India, without reference
                            to conflict of laws principles and you irrevocably
                            and unconditionally submit to the exclusive
                            jurisdiction of the courts located in Hyderabad,
                            India.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Acceptance of Terms and Conditions:</h2>
                        <p>
                            By Clicking on the "I have read and accept the terms
                            and conditions" box at the bottom of the
                            Registration Form, You indicate your acceptance of
                            the above Terms and Conditions and you agree to be
                            bound by these terms and conditions set forth below
                            including any additional guidelines and future
                            modifications. If at any time you do not agree to
                            these terms and conditions or you wish terminate
                            your registration as an Affiliate, you may not
                            access or use the information and notify your
                            intention to block your registration.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Customer Support Policy</h2>
                        <p>
                            It is clarified that the Company is purely an online
                            intermediary connecting the sellers listed on
                            GeniFast platform with consumers and we neither
                            control nor have any ownership of the products sold
                            through our platform. Thus, all concerns with the
                            fitness, merchantability, and adequacy of the
                            products are the responsibility of the seller/retail
                            partners and we will employ our best efforts to
                            assist in getting all such product-related concerns
                            resolved through them.{" "}
                        </p>
                        <p>
                            You can contact our customer service team via a call
                            to 7036-3344-00 or 7036-3344-11 or send an email to
                            info@genifast.in which provides the online
                            resolution to your queries/complaints across
                            channels. The team will respond to your
                            concern/query within 24 business hours from receipt
                            of your query/concern
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Return Policy - GeniFast</h2>
                        <p>
                            At genifast.in, we strive to ensure your orders are
                            delivered to your satisfaction. However, in the rare
                            event that your order is unsatisfactory, we are
                            happy to accept returns for orders, if:
                            <ul>
                                <li>
                                    you've received an item(s) that does not
                                    match your order
                                </li>
                                <li>
                                    the item(s) were damaged in transit or
                                    shortage (delivery of such order to be
                                    refused at doorstep)
                                </li>
                                <li>
                                    the item(s) is due to expire within 3 months
                                    where the shelf life of the item is more
                                    than 3 months
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>
                            IN ANY OTHER SCENERIOS, NO RETURNS WILL BE
                            ENTERNATINED
                        </h2>
                        <p>
                            Note: If you notice the package is damaged at the
                            time of delivery, please do not accept the order. If
                            you could not check that the order is damaged at the
                            time of delivery, raise a return request within 48
                            hours of delivery by calling us at 888-666-2150.
                            Replacement for the damaged item (s) is subject to
                            availability in stock. In absence of a replacement,
                            a refund will be issued.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>
                            Returns are subject to the following conditions:
                        </h2>
                        <ul>
                            <li>
                                Items being returned should only be in their
                                original manufacturer's packaging i.e. with
                                labels, bar-code.
                            </li>
                            <li>
                                Only fully unused strips/bottles can be returned
                            </li>
                            <li>
                                Batch number of the product being returned
                                should match as mentioned on the sales invoice
                            </li>
                            <li>
                                Original sales invoice has to be returned for
                                processing returns and refunds.
                            </li>
                        </ul>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>
                            We do not accept returns in following scenarios:
                        </h2>
                        <ul>
                            <li>
                                Partially used strips, opened bottles cannot be
                                returned.
                            </li>
                            <li>
                                Products which require specific storage
                                conditions (temperature controlled) cannot be
                                returned (vials, injections, vaccines, penfills,
                                etc.)
                            </li>
                            <li>Products which have expired post purchase.</li>
                            <li>
                                Second time request against the same
                                product/order.
                            </li>
                        </ul>
                        <p>
                            If you're order is suitable for a return based on
                            the conditions above, you can raise a request within
                            seven (7) days from the date of delivery. For
                            details on how to contact us for a return. Please
                            note that all returns are reviewed for eligibility.
                            All refunds are subject to the verification and
                            review by our Returns team.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>REFUNDS</h2>
                        <p>
                            Refunds for all eligible Returns are issued through
                            the payment method used at the time of purchase,
                            except for Cash on Delivery. The time frame for
                            different payment modes is given below:  
                        </p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Mode of Payment</th>
                                    <th>Mode of Refund</th>
                                    <th>Time frame</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Credit cards / Debit Cards</td>
                                    <td>Credit cards / Debit Cards</td>
                                    <td>5-7 business days*</td>
                                </tr>
                                <tr>
                                    <td>Net Banking</td>
                                    <td>Net Banking</td>
                                    <td>5-7 business days*</td>
                                </tr>
                                <tr>
                                    <td>Cash on Delivery</td>
                                    <td>Cash on Delivery</td>
                                    <td>5-7 business days*</td>
                                </tr>
                                <tr>
                                    <td>E-Wallets</td>
                                    <td>E-Wallets</td>
                                    <td>5-7 business days*</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            PREPAID Orders if returned by the Customer will be
                            refunded only after deducting Rs 100 towards the
                            Handling Charges.{" "}
                        </p>
                        <p>
                            Handling Charges will be added the Customers wallet
                            and the customer has to clear the Handling Charges
                            to proceed with the new order.
                        </p>
                        <p>
                            Refund timelines depend on bank turnaround times and
                            RBI guidelines. This may change from time to time.
                            Business days shall mean the working days on which
                            GeniFast corporate office operates.
                        </p>
                        <p>
                            For orders placed using Cash on Delivery as the
                            payment method, refunds can be processed to your
                            bank account via National Electronic Funds Transfer
                            (NEFT). You will need to update following
                            information to enable us to process a refund to your
                            account.
                        </p>
                        <ul>
                            <li>The Bank Account Number</li>
                            <li>IFSC Code</li>
                            <li>Account Holder's Name</li>
                            <li>Bank Branch</li>
                            <li>Bank Name</li>
                        </ul>
                        <p>
                            Important:Refunds cannot be processed to third-party
                            accounts. The name on your GeniFast account should
                            match with the name of the bank account holder. If
                            you have paid the bills using a third party account,
                            refunds will be made to the same account. NEFT
                            refunds will typically be initiated in three
                            business days from our end and will be credited to
                            your account in another two to three business days.
                            GeniFast will not be liable for any delay caused in
                            refunds due to delay by third party affiliates
                            (including banks), in providing information by the
                            customer, technical issues and other reasons beyond
                            its control.
                        </p>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>Cancellation</h2>
                        <p>
                            We understand that there will be genuine reasons
                            when you decide to cancel an order. You may cancel
                            your order by calling customer care number
                            888-666-0201/07 or writing a mail to
                            info@medbuuzz.in
                        </p>
                        <p>
                            Only the order which are yet to be dispatched can be
                            cancelled. Order once dispatched cannot be
                            cancelled.
                        </p>
                        <p>
                            For any Order once dispatched and cancelled by the
                            Customer,handling charges of Rs 99 will have to paid
                            by the{" "}
                        </p>
                        <ol>
                            <li>
                                During order delivery, GeniFast’s delivery team
                                requests every customer to verify the contents
                                and provide an acknowledgement confirming the
                                correctness and completeness of the order.
                            </li>
                            <li>
                                In case of any discrepancies including tampered
                                order box or contents, please alert the delivery
                                team or call us on our customer care number
                                7036-3344-00 and 7036-3344-11 or write to us at
                                info@genifast.in for resolution.
                            </li>
                            <li>
                                Once the order is verified and acknowledged by
                                the customer, the order in full or part cannot
                                be returned unless there is a defect in the
                                delivered order which is highly unlikely.
                                However, should you receive an incomplete order,
                                damaged or incorrect product(s), please notify
                                GeniFast Customer Support immediately or within
                                10 working days of receiving the products, to
                                ensure prompt resolution.
                            </li>
                            <li>
                                Refunds will be processed only for defective and
                                incorrect deliveries.
                            </li>
                            <li>
                                All refunds will be made only through NEFT to
                                the account which is provided by the customer.
                                Refunds for Credit Card payments will take 10
                                days to get processed.
                            </li>
                            <li>
                                Full Refund Possible If:
                                <ul>
                                    <li>you received a defective item;</li>
                                    <li>
                                        {" "}
                                        the ordered item(s) is lost or damaged
                                        during transit;
                                    </li>
                                    <li>
                                        the ordered item(s) is past its expiry
                                        date.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                We also understand that various circumstances
                                may arise leading you to want to return a
                                product or products that are not defective. In
                                these cases, we may allow the return of
                                unopened, unused products after deducting a 30%
                                restocking charge, ONLY if you notify us within
                                7 working days of receipt.
                            </li>
                        </ol>
                    </div>
                    <div className={PolicyClasses["policy"]}>
                        <h2>
                            We do not accept returns in following scenarios:
                        </h2>
                        <ul>
                            <li>
                                Partially used strips, opened bottles cannot be
                                returned.
                            </li>
                            <li>
                                Products which require specific storage
                                conditions (temperature controlled) cannot be
                                returned (vials, injections, vaccines, penfills,
                                etc.)
                            </li>
                            <li>Products which have expired post purchase.</li>
                            <li>
                                Second time request against the same
                                product/order.
                            </li>
                        </ul>
                    </div>
                    <div className={PolicyClasses["policy-dislaimer"]}>
                        <p>
                            GeniFast Team reserves all right to activate or
                            Deactivate any Customer’s Account.
                        </p>
                    </div>
                </div>
                <Footer />
            </Layout>
        </>
    );
}

export default termsAndConditions;
