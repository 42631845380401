import React, { useEffect, useState } from "react";
import Loader from "../../../Utilities/loader";
import { toast } from "react-toastify";
import { Services } from "../../../services";
import ProfileLayout from "../profileLayout";
import pointsClasses from "./points.module.css";
import emptyCart from "../../../Assets/emptycart.webp";
import { useNavigate } from "react-router-dom";
function Points() {
    const Navigate = useNavigate();
    const [load, setload] = useState(true);
    const [balance, setBalance] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    useEffect(() => {
        Services.getWalletTransactions("GET", null, token)
            .then((response) => {
                setload(false);
                if (response.Status === 1) {
                    setBalance(response.WalletBalance);
                    setTransactions(response.Transactions);
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        // eslint-disable-next-line
    }, []);
    return (
        <ProfileLayout isactive={"points"}>
            {load ? (
                <Loader />
            ) : (
                <div className={pointsClasses["points-wrapper"]}>
                    <header>
                        <h2>Your Balance</h2>
                        <h3>
                            {balance} <span>Points</span>
                        </h3>
                    </header>
                    <div className={pointsClasses["walletTransaction"]}>
                        <h3>Wallet Transactions</h3>
                        {transactions.length > 0 ? (
                            transactions.map((transaction) => (
                                <div
                                    className={
                                        pointsClasses["transaction-data"]
                                    }>
                                    <div
                                        className={
                                            pointsClasses["transaction-desc"]
                                        }>
                                        <p>OrderID: #{transaction.orderId}</p>
                                        {transaction.type === 1 ? (
                                            <p>
                                                Redeem for{" "}
                                                {transaction.redeemedPoints}{" "}
                                                points
                                            </p>
                                        ) : (
                                            <p>
                                                {transaction.redeemedPoints}{" "}
                                                points redeemed
                                            </p>
                                        )}
                                    </div>
                                    <h4
                                        className={
                                            transaction.type === 1
                                                ? pointsClasses["points-earned"]
                                                : pointsClasses[
                                                      "points-redeemed"
                                                  ]
                                        }>
                                        {transaction.type === 1
                                            ? `+${transaction.redeemedPoints} points Earned`
                                            : `-${transaction.redeemedPoints} points used`}
                                    </h4>
                                </div>
                            ))
                        ) : (
                            <div className={pointsClasses["emptyCart-wrapper"]}>
                                <div className={pointsClasses["emptyCart"]}>
                                    <img src={emptyCart} alt="emptycart" />
                                    <h2>No Transactions found!</h2>
                                    <button
                                        onClick={() => {
                                            Navigate("/shop");
                                        }}>
                                        Shop now
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </ProfileLayout>
    );
}

export default Points;
