import React from "react";
import modalClasses from "./utilities.module.css";
import { IoClose } from "react-icons/io5";
import FadeLoader from "react-spinners/FadeLoader";
import { BiError } from "react-icons/bi";
function Modal(props) {
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    return (
        <>
            <div className={modalClasses["modal"]}>
                <div
                    className={modalClasses["modal-content"]}
                    onClick={openhandler}>
                    <div className={modalClasses["header"]}>
                        <div>
                            <BiError color="white" size={26} />
                            <p>Error </p>
                        </div>
                        <button
                            className={modalClasses["close-btn"]}
                            onClick={CancelHandler}>
                            <IoClose
                                size={27}
                                color="white"
                                className={modalClasses["close-icon"]}
                            />
                        </button>
                    </div>
                    <p>{props.errorMessage}</p>
                    <div className={modalClasses["control-btns"]}>
                        <button
                            className={modalClasses["delete-btn"]}
                            type="button"
                            onClick={CancelHandler}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Modal;
