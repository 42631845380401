import React from "react";
import footerclasses from "./footer.module.css";
import logo from "../../../Assets/logo.png";
import { TiSocialFacebook } from "react-icons/ti";
import { AiOutlineInstagram } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import { FaPinterestP } from "react-icons/fa";
import { RiLinkedinFill } from "react-icons/ri";
import { BsTelephone } from "react-icons/bs";
import { IoMailOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdShoppingBag } from "react-icons/md";
// import { MdOutlineLocalShipping } from "react-icons/md";
import useStore from "../../../store";
import { useNavigate } from "react-router-dom";
function Footer() {
    const Navigate = useNavigate();
    const auth = useStore((state) => state.isAuth);
    const setLogin = useStore((state) => state.setLoginModal);
    const navigatehandler = (e) => {
        const to = e.currentTarget.getAttribute("to");

        if (auth) {
            Navigate(to);
        } else if (!auth) {
            setLogin("login");
        }
    };
    return (
        <>
            <div className={footerclasses["footer-container"]}>
                <div className={footerclasses.address}>
                    <img src={logo} alt="logo" width={"150px"} />
                    <p>
                        #105, Plot No A-1, GBS Enclave,
                        <br /> Moti Valley, Trimulgherry,
                        <br />
                        Secunderabad - 500015
                        <br />
                        Hyderabad, Telangana, India
                    </p>
                    <div className={footerclasses["social-links"]}>
                        <a
                            href="https://www.facebook.com/profile.php?id=100087206570903"
                            target={"_blank"}
                            rel="noreferrer">
                            <TiSocialFacebook size={26} color="#5b6c87" />
                        </a>
                        <a
                            href="https://www.instagram.com/genifast_online/"
                            target={"_blank"}
                            rel="noreferrer">
                            <AiOutlineInstagram size={26} color="#5b6c87" />
                        </a>
                    </div>
                </div>
                <div className={footerclasses["need-help"]}>
                    <h3>Need Help</h3>
                    <div className={footerclasses["phone"]}>
                        <p className={footerclasses["number"]}>
                            <BsTelephone color="#fd8d27" /> 8886-6602-01
                        </p>
                        <p className={footerclasses["timings"]}>
                            Monday – Friday: 9:00-20:00
                        </p>
                        <p className={footerclasses["timings"]}>
                            Saturday: 11:00 – 15:00
                        </p>
                    </div>
                    <h1>
                        <IoMailOutline /> info@genifast.in
                    </h1>
                </div>
                <div className={footerclasses["links"]}>
                    <div className={footerclasses["products-col"]}>
                        <h3>Information</h3>
                        <NavLink to="/about-us">About us</NavLink>
                        <NavLink to="/shipping-policy">Shipping Policy</NavLink>
                        <NavLink to="/privacy-policy">Privacy policy</NavLink>
                        <NavLink to="/return-policy">Return Policy</NavLink>
                        <NavLink to="/terms-conditions">
                            Terms and conditions
                        </NavLink>
                    </div>
                    <div className={footerclasses["products-col"]}>
                        <h3>Account</h3>
                        <button to="/editProfile" onClick={navigatehandler}>
                            My Account
                        </button>
                        <button to="/orders" onClick={navigatehandler}>
                            My Orders
                        </button>
                        {/* <NavLink to="/">Returns</NavLink> */}
                        {/* <NavLink to="/">Shipping</NavLink> */}
                        <button to="/wishlist" onClick={navigatehandler}>
                            Wishlist
                        </button>
                    </div>
                </div>
            </div>
            <div className={footerclasses["copyright"]}>
                <div>
                    <p>Genifast Is A Product Of Vaishanvi Biotech</p>
                    <p>Copyright © 2021 Genifast All Right Reserved</p>
                </div>
            </div>
        </>
    );
}

export default Footer;
