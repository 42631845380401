import React from "react";
import modalClasses from "./extrapay.module.css";
import { IoClose } from "react-icons/io5";
import FadeLoader from "react-spinners/FadeLoader";
import { AiOutlineShoppingCart } from "react-icons/ai";
function Modal(props) {
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const submithandler = () => {
        let data = JSON.stringify(props.json);
        props.createOrder(data);
    };
    return (
        <>
            <div className={modalClasses["modal"]}>
                <div
                    className={modalClasses["modal-content"]}
                    onClick={openhandler}>
                    <div className={modalClasses["header"]}>
                        <div>
                            <AiOutlineShoppingCart color="white" size={26} />
                            <p>COD </p>
                        </div>
                        <button
                            className={modalClasses["close-btn"]}
                            onClick={CancelHandler}>
                            <IoClose
                                size={27}
                                color="white"
                                className={modalClasses["close-icon"]}
                            />
                        </button>
                    </div>
                    <p>Are you sure you want to pay extra Rs50 for COD?</p>
                    <div className={modalClasses["control-btns"]}>
                        <button
                            className={modalClasses["delete-btn"]}
                            type="button"
                            onClick={CancelHandler}>
                            Close
                        </button>
                        <button
                            className={modalClasses["submit-btn"]}
                            type="button"
                            onClick={submithandler}>
                            Pay
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Modal;
