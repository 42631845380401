import React, { useState } from "react";
import { toast } from "react-toastify";
// import { Services } from "../../../services";
import { Services } from "../../../services";
import { useForm } from "react-hook-form";
import quantityClasses from "./quantity.module.css";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
function Modal(props) {
    const Navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };
    const onSubmit = (data) => {
        setloading(true);
        let json = JSON.stringify({
            productId: props.productId,
            quantity: data.quantity,
            warehouseId: props.warehouseid,
        });
        Services.NotfiyProduct("POST", json, token)
            .then((response) => {
                if (response.Status === 1) {
                    // Message
                    props.setmodal(false);
                    toast.success(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    return (
        <>
            {loading ? (
                <div className={quantityClasses["modal"]}>
                    <div className={quantityClasses["spinner"]}>
                        <FadeLoader color="#36d7b7" />
                    </div>
                </div>
            ) : (
                <div className={quantityClasses["modal"]}>
                    <div
                        className={quantityClasses["modal-content"]}
                        onClick={openhandler}>
                        <div className={quantityClasses["header"]}>
                            <p>Notify </p>
                            <button
                                className={quantityClasses["close-btn"]}
                                onClick={CancelHandler}>
                                <IoClose
                                    size={27}
                                    color="white"
                                    style={{ cursor: "pointer" }}
                                />
                            </button>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={quantityClasses["form-control"]}>
                                <label htmlFor="Cancellation Reason">
                                    Quantity
                                </label>
                                <input
                                    type={"number"}
                                    placeholder="Enter Quantity"
                                    {...register("quantity", {
                                        required: "Quantity is required!",
                                    })}
                                />
                                {errors?.quantity && (
                                    <p className={quantityClasses.error}>
                                        {errors?.quantity?.message}
                                    </p>
                                )}
                            </div>
                            <div className={quantityClasses["control-btns"]}>
                                <button
                                    className={quantityClasses["delete-btn"]}
                                    type="button"
                                    onClick={CancelHandler}>
                                    Cancel
                                </button>
                                <button
                                    className={quantityClasses["submit-btn"]}
                                    type="submit">
                                    Notify
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default Modal;
