import React, { useState, useEffect, useRef } from "react";
import Payclasses from "./pay.module.css";
import { toast } from "react-toastify";
import { Services } from "../../services";
import CircleLoad from "../../Utilities/circleLoad";
import AddAddress from "../Profile/manageAddress/addAddress";
import DeleteModal from "../../Components/Profile/manageAddress/DeleteAddress";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { State } from "country-state-city";
import useStore from "../../store";
function Pay(props) {
    const validatePhoneNumber = (value) => {
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        return phoneNumberPattern.test(value) || "Invalid phone number";
    };
    const alphaOnly = /^[A-Za-z]+$/i;
    const Navigate = useNavigate();
    const storePrice = useStore((state) => state.price);
    let states = State.getStatesOfCountry("IN");

    const warehouseid = sessionStorage.getItem("warehouseId");
    const [ContactNumber, setContactNumber] = useState("");
    const [contactError, setContactError] = useState(false);
    const [pincode, setpincode] = useState("");
    const [pincodeError, setpincodeError] = useState();
    const pinref = useRef();
    const [address, setaddress] = useState([]);
    const [load, setLoad] = useState(false);
    const [addAddress, setAddAdress] = useState(false);
    const [edit, setedit] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const [deletemodal, setDeleteModal] = useState(false);
    const [id, setid] = useState();
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    const addressfetch = () => {
        setLoad(true);
        Services.addressList("GET", null, token)
            .then((response) => {
                setLoad(false);
                if (response.Status === 1) {
                    setaddress(response.Address);
                } else if (response.Status === 0) {
                    if (response.Message === "No Saved Addresses") {
                        setaddress([]);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setLoad(false);
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    useEffect(() => {
        if (storePrice === 0) {
            Navigate("/cart");
        }
        addressfetch();
        // eslint-disable-next-line
    }, []);
    const deleteclick = (e) => {
        setid(e.currentTarget.getAttribute("addId"));
        setDeleteModal(true);
    };
    const deleteHandler = () => {
        Services.DeleteAddress("DELETE", null, token, id)
            .then((response) => {
                setDeleteModal(false);
                if (response.Status === 1) {
                    setLoad(true);
                    addressfetch();
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setDeleteModal(false);
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    const editClick = (e) => {
        setid(e.currentTarget.getAttribute("addId"));
        setedit(true);
        setAddAdress(true);
    };

    const onSubmit = (data) => {
        if (pincode.trim().length != 6 || pincode.slice(0, 1) == 0) {
            setpincodeError(true);
            pinref.current.focus();
            return;
        }
        let method = JSON.stringify({
            warehouseId: warehouseid,
            pincode: pincode,

            ...data,
        });
        Services.Addaddress("POST", method, token)
            .then((res) => {
                if (res.Status === 1) {
                    Services.addressList("GET", null, token).then(
                        (response) => {
                            const address = response.Address.find(
                                (address) =>
                                    data.name === address.name &&
                                    data.address1 === address.address1 &&
                                    data.address2 === address.address2
                            );
                            props.setDeliveryAddress(address);

                            props.setmodal(false);
                            // setPayment(true);
                        }
                    );
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");

                console.log(err);
            });
    };
    const DeliveryHandler = (e) => {
        const id = e.currentTarget.getAttribute("addId");
        let selectedAddress = address.find(
            (adress) => adress.userAddressId === Number(id)
        );
        props.setDeliveryAddress(selectedAddress);
        // setPayment(true);
        const json = JSON.stringify({
            pincode: Number(selectedAddress.pincode),
            warehouseId: Number(warehouseid),
        });
        Services.DeliveryDate("POST", json, token)
            .then((res) => {
                if (res.Status === 1) {
                    props.setDeliveryDate(res.expectedDelivery);
                } else {
                    props.setdeliveryError(res.Message);
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
            });
        //pincode

        props.setmodal(false);
    };
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const pincodeHandler = (e) => {
        if (e.target.value.trim().length <= 6) {
            setpincodeError(false);
            setpincode(e.target.value);
        }
    };
    const handleInputChange = (e) => {
        const { value } = e.target;
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        if (value.length > 10) {
            setValue("contactNumber", String(value).substring(0, 10));
            return;
        }
        if (phoneNumberPattern.test(value)) {
            setValue("contactNumber", value);
        }
    };
    return (
        <div className={Payclasses.modal} onClick={CancelHandler}>
            <div className={Payclasses["modal-content"]} onClick={openhandler}>
                {/* {payment && (
                    <Payment
                        setmodal={setPayment}
                        deliveryAddress={deliveryAddress}
                        cartid={cartid}
                        storePrice={storePrice}
                    />
                )} */}
                {addAddress && (
                    <AddAddress
                        setmodal={setAddAdress}
                        editable={edit}
                        id={id}
                        setEditable={setedit}
                        addressfetch={addressfetch}
                    />
                )}
                {deletemodal && (
                    <DeleteModal
                        setview={setDeleteModal}
                        deleteHandler={deleteHandler}
                    />
                )}
                <div className={Payclasses["pay-wrapper"]}>
                    {load ? (
                        <CircleLoad />
                    ) : (
                        address.length > 0 && (
                            <>
                                <h3>Select Address</h3>
                                <div className={Payclasses["selectAddresses"]}>
                                    {address.map((address) => (
                                        <div className={Payclasses["address"]}>
                                            <p>{address.name}</p>
                                            <p style={{ width: "340px" }}>
                                                {address?.address1}{" "}
                                                {address?.address2}
                                            </p>
                                            <p> {address?.landmark}</p>
                                            <p> {address?.city}</p>
                                            <p> {address?.state}</p>
                                            <p> {address?.pincode}</p>
                                            <p>{address?.contactNumber}</p>
                                            <button
                                                addId={address?.userAddressId}
                                                onClick={DeliveryHandler}>
                                                Deliver to this Address
                                            </button>
                                            <div
                                                className={
                                                    Payclasses["edit-address"]
                                                }>
                                                <button
                                                    onClick={editClick}
                                                    addId={
                                                        address?.userAddressId
                                                    }>
                                                    {" "}
                                                    Edit Address
                                                </button>
                                                <button
                                                    onClick={deleteclick}
                                                    addId={
                                                        address?.userAddressId
                                                    }>
                                                    Delete Address
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )
                    )}
                    <div className={Payclasses["add-address"]}>
                        <h3>Add a new address</h3>
                        <form
                            className={Payclasses["add-items"]}
                            onSubmit={handleSubmit(onSubmit)}>
                            <div className={Payclasses["item-image-upload"]}>
                                <label htmlFor="Name">Name</label>
                                <div className={Payclasses["select"]}>
                                    <input
                                        type="text"
                                        {...register("name", {
                                            required: true,
                                            validate: (value) => {
                                                return !!value.trim();
                                            },
                                        })}
                                    />

                                    {errors?.name && (
                                        <p className={Payclasses["error"]}>
                                            Name is required!
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className={Payclasses["item-image-upload"]}>
                                <label htmlFor="addressAliasName">
                                    Address Alias Name
                                </label>
                                <div className={Payclasses["select"]}>
                                    <select
                                        defaultValue=""
                                        {...register("addressAliasName", {
                                            required:
                                                "Address Alias Name is required",
                                        })}>
                                        <option value="">Type</option>
                                        <option value="Home">Home</option>
                                        <option value="Office">Office</option>
                                        <option value="Others">Others</option>
                                    </select>

                                    {errors?.addressAliasName && (
                                        <p className={Payclasses["error"]}>
                                            {errors?.addressAliasName?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className={Payclasses["item-image-upload"]}>
                                <label htmlFor="address1">Address 1</label>
                                <div className={Payclasses["select"]}>
                                    <input
                                        type="text"
                                        {...register("address1", {
                                            required: true,
                                            validate: (value) => {
                                                return !!value.trim();
                                            },
                                        })}
                                    />

                                    {errors?.address1 && (
                                        <p className={Payclasses["error"]}>
                                            Enter a valid Address!
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className={Payclasses["item-image-upload"]}>
                                <label htmlFor="address2">Address 2</label>
                                <div className={Payclasses["select"]}>
                                    <input
                                        type="text"
                                        {...register("address2")}
                                    />

                                    {errors?.address2 && (
                                        <p className={Payclasses["error"]}>
                                            {errors?.address2?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className={Payclasses["item-image-upload"]}>
                                <label htmlFor="landmark">Landmark</label>
                                <div className={Payclasses["select"]}>
                                    <input
                                        type="text"
                                        {...register("landmark")}
                                    />

                                    {errors?.landmark && (
                                        <p className={Payclasses["error"]}>
                                            {errors?.landmark?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className={Payclasses["item-image-upload"]}>
                                <label htmlFor="city">City</label>
                                <div className={Payclasses["select"]}>
                                    <input
                                        type="text"
                                        {...register("city", {
                                            required: true,
                                            pattern: alphaOnly,
                                            validate: (value) => {
                                                return !!value.trim();
                                            },
                                        })}
                                    />

                                    {errors?.city && (
                                        <p className={Payclasses["error"]}>
                                            Enter a valid City!
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className={Payclasses["item-image-upload"]}>
                                <label htmlFor="state">State</label>
                                <div className={Payclasses["select"]}>
                                    <select
                                        {...register("state", {
                                            required: "state is required!",
                                        })}
                                        defaultValue="">
                                        <option value="" disabled>
                                            Select State
                                        </option>
                                        {states.length > 0 &&
                                            states.map((item) => (
                                                <option value={item.name}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </select>
                                    {errors?.state && (
                                        <p className={Payclasses["error"]}>
                                            {errors?.state?.message}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div className={Payclasses["item-image-upload"]}>
                                <label htmlFor="pincode">Pincode</label>
                                <div className={Payclasses["select"]}>
                                    <input
                                        type={"number"}
                                        value={pincode}
                                        onChange={pincodeHandler}
                                        ref={pinref}
                                    />

                                    {pincodeError && (
                                        <p className={Payclasses["error"]}>
                                            Enter a valid pincode
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className={Payclasses["item-image-upload"]}>
                                <label htmlFor="contactNumber">
                                    Contact Number
                                </label>
                                {/* contactNumber */}
                                <div className={Payclasses["select"]}>
                                    <input
                                        type={"number"}
                                        {...register("contactNumber", {
                                            validate: validatePhoneNumber,
                                            required: true,
                                        })}
                                        onChange={handleInputChange}
                                        // ref={numberRef}
                                    />

                                    {errors?.contactNumber && (
                                        <p className={Payclasses["error"]}>
                                            Enter a valid number
                                        </p>
                                    )}
                                </div>
                            </div>
                            <button
                                type="submit"
                                className={Payclasses["cancel"]}>
                                Use this Address
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pay;
