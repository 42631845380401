import React, { useState } from "react";
import categorieClasses from "./categories.module.css";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
function Categories(props) {
    // eslint-disable-next-line
    const [max, setmax] = useState(20000);
    const Navigate = useNavigate();

    return (
        <div className={categorieClasses["categories-wrapper"]}>
            <div className={categorieClasses["categories-link"]}>
                <h3>Categories</h3>
                {props?.category?.length > 0 ? (
                    props.category.map((item) => {
                        return (
                            <button
                                className={
                                    // eslint-disable-next-line
                                    props.SelectedCategory == item.categoryName
                                        ? categorieClasses.activeLink
                                        : undefined
                                }
                                onClick={() => {
                                    let categoryParam = item.categoryName
                                        .replace(/\s+/g, "-")
                                        .replace("/", "");

                                    // Navigate(
                                    //     `/shop/${item.categoryId}/${categoryParam}`
                                    // );
                                    Navigate({
                                        pathname: `/shop/${categoryParam}`,
                                        search: `id=${item?.categoryId}`,
                                    });
                                    props.setSelectedCategory(
                                        item.categoryName
                                    );
                                    props.setCategoriesMetaData(item);
                                    sessionStorage.setItem(
                                        "categoryName",
                                        item.categoryName
                                    );
                                    props.getProducts(item.categoryId);
                                }}>
                                {item.categoryName}
                            </button>
                        );
                    })
                ) : (
                    <Skeleton
                        count={15}
                        style={{ marginBottom: "0.6rem" }}
                        width={200}
                    />
                )}
            </div>
            {/* <div className={categorieClasses["price-slider"]}>
                <h3>By Price</h3>
                <MultiRangeSlider
                    min={0}
                    max={1000}
                    onChange={({ min, max }) => {
                        console.log("111");
                        setmin(min);
                        setmax(max);
                    }}
                />
            </div> */}
            {/* <div className={categorieClasses["categories-checkbox"]}>
                <h3>Brands</h3>
                <div className={categorieClasses["check-box"]}>
                    <input type={"checkbox"} />
                    <label htmlFor="pharm">BestPharm</label>
                </div>
                <div className={categorieClasses["check-box"]}>
                    <input type={"checkbox"} />
                    <label htmlFor="Heart Rate">Heart Rate</label>
                </div>
                <div className={categorieClasses["check-box"]}>
                    <input type={"checkbox"} />
                    <label htmlFor="iheart">iheart</label>
                </div>
                <div className={categorieClasses["check-box"]}>
                    <input type={"checkbox"} />
                    <label htmlFor="medialarm">medialarm</label>
                </div>
                <div className={categorieClasses["check-box"]}>
                    <input type={"checkbox"} />
                    <label htmlFor="pharma">pharma</label>
                </div>
                <div className={categorieClasses["check-box"]}>
                    <input type={"checkbox"} />
                    <label htmlFor="wetakecare">wetakecare</label>
                </div>
            </div> */}
            {/* <div className={categorieClasses["categories-checkbox"]}>
                <h3>By Rating</h3>
                <div className={categorieClasses["check-box"]}>
                    <input type={"checkbox"} />
                    <div className={categorieClasses["star-rating"]}>
                        <ReactStars
                            count={5}
                            value={5}
                            size={24}
                            color2={"#ff9923"}
                            edit={false}
                        />
                        <p>(35)</p>
                    </div>
                </div>
                <div className={categorieClasses["check-box"]}>
                    <input type={"checkbox"} />
                    <div className={categorieClasses["star-rating"]}>
                        <ReactStars
                            count={5}
                            value={4}
                            size={24}
                            color2={"#ff9923"}
                            edit={false}
                        />
                        <p>(60)</p>
                    </div>
                </div>
                <div className={categorieClasses["check-box"]}>
                    <input type={"checkbox"} />
                    <div className={categorieClasses["star-rating"]}>
                        <ReactStars
                            count={5}
                            value={3}
                            size={24}
                            color2={"#ff9923"}
                            edit={false}
                        />
                        <p>(25)</p>
                    </div>
                </div>
                <div className={categorieClasses["check-box"]}>
                    <input type={"checkbox"} />
                    <div className={categorieClasses["star-rating"]}>
                        <ReactStars
                            count={5}
                            value={2}
                            size={24}
                            color2={"#ff9923"}
                            edit={false}
                        />
                        <p>(70)</p>
                    </div>
                </div>
                <div className={categorieClasses["check-box"]}>
                    <input type={"checkbox"} />
                    <div className={categorieClasses["star-rating"]}>
                        <ReactStars
                            count={5}
                            value={1}
                            size={24}
                            color2={"#ff9923"}
                            edit={false}
                        />
                        <p>(11)</p>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default Categories;
