import React, { useEffect, useState } from "react";
import Loader from "../../../Utilities/loader";
import { toast } from "react-toastify";
import { Services } from "../../../services";
import AddressClasses from "./address.module.css";
import ProfileLayout from "../profileLayout";
import { RiEditFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import emptyCart from "../../../Assets/emptycart.webp";
import AddAddress from "./addAddress";
import DeleteModal from "./DeleteAddress";
function Address() {
    const [address, setaddress] = useState([]);
    const [load, setload] = useState(true);
    const [id, setid] = useState();
    const [edit, setedit] = useState(false);
    const [modal, setModal] = useState(false);
    const [deletemodal, setDeleteModal] = useState(false);
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    const addressfetch = () => {
        setload(true);
        Services.addressList("GET", null, token)
            .then((response) => {
                setload(false);
                if (response.Status === 1) {
                    setaddress(response.Address);
                } else if (response.Status === 0) {
                    if (response.Message === "No Saved Addresses") {
                        setaddress([]);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setload(false);
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    useEffect(() => {
        addressfetch();
        // eslint-disable-next-line
    }, []);
    const deleteclick = (e) => {
        setid(e.currentTarget.getAttribute("addId"));
        setDeleteModal(true);
    };
    const deleteHandler = () => {
        Services.DeleteAddress("DELETE", null, token, id)
            .then((response) => {
                setDeleteModal(false);
                if (response.Status === 1) {
                    setload(true);
                    addressfetch();
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setDeleteModal(false);
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    return (
        <>
            {modal && (
                <AddAddress
                    setmodal={setModal}
                    editable={edit}
                    id={id}
                    setEditable={setedit}
                    addressfetch={addressfetch}
                />
            )}
            {deletemodal && (
                <DeleteModal
                    setview={setDeleteModal}
                    deleteHandler={deleteHandler}
                />
            )}
            <ProfileLayout isactive={"address"}>
                {load ? (
                    <Loader />
                ) : (
                    <div className={AddressClasses["address-wrapper"]}>
                        <div className={AddressClasses["address-header"]}>
                            <h3>Saved Address </h3>
                            {address.length > 0 && (
                                <button onClick={() => setModal(true)}>
                                    Add New Address
                                </button>
                            )}
                        </div>
                        {address.length > 0 ? (
                            <>
                                {/* <div className={AddressClasses["address"]}>
                                    <header>
                                        {" "}
                                        <h3>Default Address</h3>
                                        <div
                                            className={
                                                AddressClasses["edit-address"]
                                            }>
                                            <button
                                                onClick={() => setModal(true)}>
                                                {" "}
                                                <RiEditFill size={23} />
                                                Edit Address
                                            </button>
                                            <button>
                                                <MdDelete size={23} />
                                                Delete Address
                                            </button>
                                        </div>
                                    </header>
                                    <p>.name</p>
                                    <p>
                                        orderDetails?.address1 <br />
                                        orderDetails?.address2
                                        <br />
                                        orderDetails?.landmark
                                        <br /> orderDetails?.pincode
                                    </p>
                                    <p>orderDetails?.contactNumber</p>
                                </div> */}
                                {address.map((address) => (
                                    <div className={AddressClasses["address"]}>
                                        <header
                                            className={
                                                AddressClasses[
                                                    "general-address"
                                                ]
                                            }>
                                            <div
                                                className={
                                                    AddressClasses[
                                                        "edit-address"
                                                    ]
                                                }>
                                                <button
                                                    onClick={(e) => {
                                                        setedit(true);
                                                        setModal(true);
                                                        setid(
                                                            e.currentTarget.getAttribute(
                                                                "addId"
                                                            )
                                                        );
                                                    }}
                                                    addId={
                                                        address?.userAddressId
                                                    }>
                                                    {" "}
                                                    <RiEditFill size={23} />
                                                    Edit
                                                </button>
                                                <button
                                                    onClick={deleteclick}
                                                    addId={
                                                        address?.userAddressId
                                                    }>
                                                    <MdDelete size={23} />
                                                    Delete
                                                </button>
                                            </div>
                                        </header>
                                        <p>{address.name}</p>
                                        <p style={{ maxWidth: "340px" }}>
                                            {address?.address1},
                                            {address?.address2}
                                        </p>
                                        <p> {address?.landmark}</p>
                                        <p> {address?.state}</p>
                                        <p> {address?.pincode}</p>
                                        <p>{address?.contactNumber}</p>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <div
                                className={AddressClasses["emptyCart-wrapper"]}>
                                <div className={AddressClasses["emptyCart"]}>
                                    <img src={emptyCart} alt="emptycart" />

                                    <button onClick={() => setModal(true)}>
                                        Add Address
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </ProfileLayout>
        </>
    );
}

export default Address;
