import React from "react";
import listClasses from "./wishlist.module.css";
import ProductsSkeleton from "../HomePage/LatestProducts/homePageProductSkeleton";
function wishlistSkeleton() {
    return (
        <div className={listClasses["wishlist-wrapper"]}>
            {Array(2)
                .fill(0)
                .map((item) => (
                    <ProductsSkeleton />
                ))}
        </div>
    );
}

export default wishlistSkeleton;
