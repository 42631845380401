import React, { useState, useEffect } from "react";
import Search from "../HomePage/SearchBar/Search";
import Navbar from "../HomePage/Navbar/navbar";
import PopularClasses from "../HomePage/popularProducts/Popular.module.css";
import useStore from "../../store";
import { Services } from "../../services";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { AiOutlineRight } from "react-icons/ai";
import categoryclasses from "./categories.module.css";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function Popular() {
    const Navigate = useNavigate();
    const [categories, setcategories] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const setCategorisMetaData = useStore(
        (state) => state.setCategorisMetaData
    );
    const productsPerPage = 25;
    const pagesVisited = pageNumber * productsPerPage;
    useEffect(() => {
        Services.getCategories("GET", null)
            .then((res) => {
                if (res.Status === 1) {
                    setcategories(res.categories);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        // eslint-disable-next-line
    }, []);
    const productHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        console.log(categories.find((item) => item.categoryId == id));

        sessionStorage.setItem(
            "categoryName",
            e.currentTarget.getAttribute("name")
        );
        let categoryParam = e.currentTarget
            .getAttribute("name")
            .replace(/\s+/g, "-")
            .replace("/", "");
        Navigate({
            pathname: `/shop/${categoryParam}`,
            search: `id=${e.currentTarget.getAttribute("id")}`,
        });
    };
    const displayProducts = categories
        .slice(pagesVisited, pagesVisited + productsPerPage)
        .map((item) => {
            return (
                <div
                    className={PopularClasses["popular-item"]}
                    name={item.categoryName}
                    id={item.categoryId}
                    onClick={productHandler}>
                    <img src={item.imageUrl} alt="mask" />
                    <h3>{item.categoryName}</h3>
                </div>
            );
        });
    const pageCount = Math.ceil(categories.length / productsPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <>
            <Search />
            <Navbar />
            <div className={PopularClasses["popularProducts-wrapper"]}>
                <div className={categoryclasses["links"]}>
                    <NavLink className={categoryclasses["home-link"]} to="/">
                        Home
                    </NavLink>
                    <p>
                        <AiOutlineRight size={12} color="#5b6c8f" />
                    </p>
                    <p className={categoryclasses["shop"]}>Categories</p>
                </div>
                <div className={PopularClasses["product-wrapper"]}>
                    {displayProducts}
                </div>
                <ReactPaginate
                    previousLabel={"<<prev"}
                    nextLabel={"next>>"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttns"}
                    nextLinkClassName={"nextBttns"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                />
            </div>
        </>
    );
}

export default Popular;
