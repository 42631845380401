import create from "zustand";

const initialState = {
    loginModal: false,
    isAuth: false,
    categories: [],

    price: 0,
    wishlistCount: 0,

    CartCount: 0,
    promocode: { code: "", amount: 0 },
    categoriesMetaData: {},
    searchString: "",
    warehouseId: "",
    load: false,
};
const useStore = create((set) => ({
    ...initialState,

    setIsauth: (auth) => set((state) => ({ ...state, isAuth: auth })),
    setCategoris: (categories) =>
        set((state) => ({ ...state, categories: categories })),
    setPrice: (price) => set((state) => ({ ...state, price: price })),
    setwishList: (whislist) =>
        set((state) => ({ ...state, wishlistCount: whislist })),
    setcartCount: (count) => set((state) => ({ ...state, CartCount: count })),
    setpromocode: (code) => set((state) => ({ ...state, promocode: code })),
    SetSearchStrind: (search) =>
        set((state) => ({ ...state, searchString: search })),
    resetState: () => set(initialState),
    setLoginModal: (login) => set((state) => ({ ...state, loginModal: login })),
    SetwarehouseId: (id) => set((state) => ({ ...state, warehouseId: id })),
    setload: (boolean) => set((state) => ({ ...state, load: boolean })),
    setCategorisMetaData: (metadata) =>
        set((state) => ({ ...state, categoriesMetaData: metadata })),
}));

export default useStore;
