import React, { useEffect, useState } from "react";
import listClasses from "./wishlist.module.css";
import Layout from "../../Utilities/layout";
import { Services } from "../../services";
import Loader from "../../Utilities/loader";
import { toast } from "react-toastify";
import useStore from "../../store";
import emptyCart from "../../Assets/emptycart.webp";
import { useNavigate } from "react-router-dom";
import Product from "../HomePage/LatestProducts/product";
import { Helmet } from "react-helmet";
import WishlistSkeleton from "./wishlistSkeleton";
function Wishlist() {
    const Navigate = useNavigate();
    const storewarehouseId = useStore((state) => state.warehouseId);
    const [alldetails, setallDetails] = useState();
    const [products, setproducts] = useState([]);
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    const [load, setload] = useState(true);
    const [empty, setEmpty] = useState(false);
    const getWishlist = (e) => {
        console.log(storewarehouseId);
        setload(true);
        console.log("111sad1");
        Services.getwishlist("GET", null, token, storewarehouseId)
            .then((response) => {
                setTimeout(() => {
                    setload(false);
                }, 100);
                if (response.Status === 1) {
                    setEmpty(false);
                    setproducts(response.wishlist_Products);
                } else if (response.Status === 0) {
                    if (response.message === "No Products added to Wishlist") {
                        setEmpty(true);
                        return;
                    }
                    toast.error(`${response.message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    useEffect(() => {
        if (storewarehouseId) {
            getWishlist();
        }

        // eslint-disable-next-line
    }, [storewarehouseId]);

    return (
        <>
            <Helmet defer={false}>
                <title>Wish list</title>
            </Helmet>
            <Layout />
            <h1 className={listClasses["heading"]}>My Wishlist</h1>
            {load ? (
                <WishlistSkeleton />
            ) : empty ? (
                <div className={listClasses["emptyCart-wrapper"]}>
                    <div className={listClasses["emptyCart"]}>
                        <img src={emptyCart} alt="emptycart" />
                        <h2>Your wishlist is Empty!</h2>
                        <p>Add items to it now</p>
                        <button
                            onClick={() => {
                                Navigate("/shop");
                            }}>
                            Shop now
                        </button>
                    </div>
                </div>
            ) : (
                <div className={listClasses["wishlist-wrapper"]}>
                    {products.map((item) => (
                        <Product
                            wishlist={true}
                            img={item.imageUrl}
                            cost={item.price}
                            productId={item.productId}
                            heading={item.productName}
                            sellingPrice={item.genifast_sellingPrice}
                            genericName={item.genericName}
                            availableQty={item.availableQty}
                            manufacturedBy={item.manufacturedBy}
                            packing={item.packing}
                            expiry={item.expiry}
                            getwishlist={getWishlist}
                            setload={setload}
                            allData={item}
                            setallDetails={setallDetails}
                            details={alldetails}
                        />
                    ))}
                </div>
            )}
        </>
    );
}

export default Wishlist;
