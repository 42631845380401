import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Services } from "../../services";
import cartclasses from "./cart.module.css";
import extrapayClasses from "./extrapay.module.css";
import { useNavigate } from "react-router-dom";
import useStore from "../../store";
import ExtraPay from "./extraPay";
import FadeLoader from "react-spinners/FadeLoader";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
function Modal(props) {
    const setcartCount = useStore((state) => state.setcartCount);
    const [Json, setJson] = useState({});
    const [paymodal, setPaymodal] = useState(false);
    const [paymentLoading, setpaymentLoading] = useState(false);
    const storePrice = useStore((state) => state.price);
    const promocode = useStore((state) => state.promocode);
    const Navigate = useNavigate();
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };

    const userDetails = JSON.parse(sessionStorage.getItem("userdetails"));
    // initilize react pixel

    useEffect(() => {
        const advanceMatching = {
            em: userDetails.emailId,
            ph: userDetails.mobileNumber,
            fn: userDetails.ownerName,
            ct: userDetails.city,
            st: userDetails.state,
            zp: userDetails.pincode,
            country: "IN",
            external_id: userDetails.userId,
        };
        ReactPixel.init(
            process.env.REACT_APP_FACEBOOK_PIXEL_ID,
            advanceMatching,
            false
        );
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ID);
        ReactGA.set({
            userId: userDetails.userId,
            email: userDetails.emailId,
            firstName: userDetails.ownerName,
            phone: userDetails.mobileNumber,
        });
    }, []);
    const setpromocode = useStore((state) => state.setpromocode);
    const [type, setType] = useState("ONLINE");
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const changeHandler = (e) => {
        setType(e.target.value);
    };
    const data = {
        orderFrom: "web",
        warehouseId: sessionStorage.getItem("warehouseId"),
        cartId: props.cartid,
        addressId: props.deliveryAddress.userAddressId,
        name: props.deliveryAddress.name,
        address1: props.deliveryAddress.address1,
        address2: props.deliveryAddress.address2,
        city: props.deliveryAddress.city,
        state: props.deliveryAddress.state,
        pincode: props.deliveryAddress.pincode,
        contactNumber: props.deliveryAddress.contactNumber,
        subTotal: Number(storePrice).toFixed(2),
        OrderItems: {},
        deliveryCharges: props.deliveryCharges,
        promocode: promocode.code,
        promoAmount: promocode.amount,
        grandTotal: Number(
            storePrice - promocode.amount + props.deliveryCharges
        ).toFixed(2),
        paymentType: 1,
        razorpay_payment_id: "",
        razorpayOrderId: "",
        razorpay_signature: "",
        codCharges: 0,
        expectedDelivery: props.deliveryDate,
    };
    const [Cartitems, setcartItems] = useState([]);
    useEffect(() => {
        const warehouseid = sessionStorage.getItem("warehouseId");
        Services.getCart("GET", null, token, warehouseid)
            .then((response) => {
                if (response.Status === 1) {
                    setcartItems(response.Cart.cartItems);
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        // eslint-disable-next-line
    }, []);

    const createOrder = (body) => {
        let data = JSON.parse(body);
        console.log(data);
        setpaymentLoading(true);
        Services.CreateOrder("POST", body, token)
            .then((response) => {
                setpaymentLoading(false);
                if (response.Status === 1) {
                    ReactPixel.track("Purchase", {
                        value: data.grandTotal,
                        currency: "INR",
                        contents: data.OrderItems.map((item) => ({
                            id: item.productId,
                            quantity: item.quantity,
                            price: item.genifast_sellingPrice,
                        })),
                        content_type: "product",
                        order_id: response.OrderID,
                    });
                    // send event to Google Analytics
                    ReactGA.event({
                        category: "Ecommerce",
                        action: "Purchase",
                        label: response.OrderID,
                        value: data.OrderItems.reduce(
                            (total, product) =>
                                total + product.genifast_sellingPrice,
                            0
                        ),
                        dimension1: data.OrderItems.map((item) => ({
                            id: item.productId,
                            quantity: item.quantity,
                            price: item.genifast_sellingPrice,
                            currency: "INR",
                        })),
                    });
                    props.setmodal(false);
                    setcartCount(0);
                    setpromocode({ code: "", amount: 0 });
                    Navigate(
                        `/OrderSuccessful/${response.OrderID}?orderNo=${response.orderNumber}`
                    );
                } else {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setpaymentLoading(false);
                alert("something went wrong try later");
                Navigate("/cart");
            });
    };
    const submitHandler = (e) => {
        e.preventDefault();
        const items = Cartitems.map((item) => {
            return {
                ...item,
                totalAmount: item?.quantity * item?.genifast_sellingPrice,
            };
        });
        // return;

        if (type === "COD") {
            setJson({
                ...data,
                OrderItems: items,
                codCharges: 50,
                grandTotal: Number(
                    storePrice - promocode.amount + 50 + props.deliveryCharges
                ).toFixed(2),
            });
            setPaymodal(true);
            // createOrder(JSonDATA);
        } else if (type === "ONLINE") {
            const jsondata = JSON.stringify({
                orderAmount: data.grandTotal,
                cart: {
                    ...data,
                    OrderItems: items,
                    paymentType: 2,

                    grandTotal: Number(
                        storePrice -
                            promocode.amount +
                            50 +
                            props.deliveryCharges
                    ).toFixed(2),
                },
            });
            Services.OrderPayment("POST", jsondata, token).then((response) => {
                if (response.Status === 1) {
                    let razorpaykey = props.config.find(
                        (item) => item.configKey == "razorpayaccesskey"
                    );
                    // console.log();
                    var options = {
                        key: razorpaykey.configValue,
                        amount: Number(data.grandTotal),
                        currency: "INR",
                        name: "Genifast",
                        description: "Order Payment",
                        order_id: response.razorpayOrderId,
                        handler: function (response) {
                            console.log(response);
                            if (response.razorpay_payment_id !== null) {
                                data.paymentType = 2;
                                //razorpay_payment_id
                                data.razorpay_payment_id =
                                    response.razorpay_payment_id;
                                data.razorpayOrderId =
                                    response.razorpay_order_id;
                                data.razorpay_signature =
                                    response.razorpay_signature;
                                const jsondata = JSON.stringify({
                                    ...data,
                                    OrderItems: items,
                                });
                                createOrder(jsondata);
                            } else {
                                alert("some thing went wrong try again");
                                Navigate("cart");
                            }
                        },
                        prefill: {
                            name: data.name,
                            email: "",
                            contact: data.contactNumber,
                        },
                        notes: {
                            address:
                                "H.no-42-785/5, Andal Nagar Moula-Ali, ECIL,Hyderabad-500040",
                        },
                        theme: {
                            color: "#3399cc",
                        },
                    };
                    props.setmodal(false);
                    var rzp1 = new window.Razorpay(options);
                    rzp1.open();
                }
            });
        }
    };
    return paymodal ? (
        !paymentLoading ? (
            <ExtraPay
                createOrder={createOrder}
                setmodal={setPaymodal}
                primaryModal={props.setmodal}
                json={Json}
                setpaymentLoading={setpaymentLoading}
            />
        ) : (
            <div className={extrapayClasses["modal"]}>
                <div className={extrapayClasses["spinner"]}>
                    <FadeLoader color="#36d7b7" />
                </div>
            </div>
        )
    ) : (
        <>
            <div className={cartclasses["modal"]} onClick={CancelHandler}>
                <div
                    className={cartclasses["modal-content-pay"]}
                    onClick={openhandler}>
                    <h3>Payment</h3>
                    <div className={cartclasses["pay-form"]}>
                        <h4>Choose Payment mode</h4>
                        <form onSubmit={submitHandler}>
                            <div className={cartclasses["pay-control"]}>
                                <input
                                    onChange={changeHandler}
                                    type="radio"
                                    id="COD"
                                    name="TYPE"
                                    value="COD"
                                    defaultChecked={type == "COD"}
                                    disabled={
                                        Number(
                                            storePrice - promocode.amount
                                        ).toFixed(2) > 1500
                                    }
                                />
                                <div className={cartclasses["payLalbel"]}>
                                      <label for="COD">Cash on Delivery</label>
                                    {Number(
                                        storePrice - promocode.amount
                                    ).toFixed(2) > 1500 ? (
                                        <p
                                            style={{
                                                fontSize: "11px",
                                                fontFamily: "lora",
                                            }}>
                                            COD is not allowed for the order
                                            value more than Rs 1500
                                        </p>
                                    ) : (
                                        <p
                                            style={{
                                                fontSize: "11px",
                                                fontFamily: "lora",
                                            }}>
                                            An amount of Rs.50 will be charged
                                            for cash handling charges
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div className={cartclasses["pay-control"]}>
                                <input
                                    onChange={changeHandler}
                                    type="radio"
                                    id="ONLINE"
                                    name="TYPE"
                                    value="ONLINE"
                                    defaultChecked={type == "ONLINE"}
                                />
                                  <label for="ONLINE">Online</label>
                            </div>
                            <button type="submit">Proceed</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Modal;
