import Layout from "../../Utilities/layout";
import Footer from "../HomePage/footer/footer";
import cartclasses from "./cart.module.css";
import image from "../../Assets/image.png";
import Loader from "../../Utilities/loader";
import emptyCart from "../../Assets/emptycart.webp";
import Coupen from "../../Assets/coupen.png";
import { IoMdClose } from "react-icons/io";
import Skeleton from "react-loading-skeleton";

function Cart() {
    return (
        <>
            <div className={cartclasses["cart-container"]}>
                <div className={cartclasses["cart"]}>
                    <div className={cartclasses["cart-items"]}>
                        {/* <Skeleton width={150} /> */}
                        {Array(1).fill(0).length > 0 ? (
                            Array(2)
                                .fill(0)
                                .map((item) => {
                                    return (
                                        <div
                                            className={
                                                cartclasses["cart-item"]
                                            }>
                                            <Skeleton
                                                width={200}
                                                height={200}
                                            />
                                            <div
                                                className={
                                                    cartclasses[
                                                        "cart-item-desc"
                                                    ]
                                                }>
                                                <Skeleton
                                                    width={200}
                                                    count={5}
                                                    style={{
                                                        marginBottom: "0.6rem",
                                                    }}
                                                />
                                                <div
                                                    className={
                                                        cartclasses["mrp"]
                                                    }>
                                                    <Skeleton
                                                        width={150}
                                                        count={2}
                                                        style={{
                                                            marginBottom:
                                                                "0.6rem",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    cartclasses["remove-btn"]
                                                }>
                                                <Skeleton
                                                    width={100}
                                                    height={30}
                                                />
                                            </div>
                                        </div>
                                    );
                                })
                        ) : (
                            <p>Cart is empty</p>
                        )}
                    </div>
                    <div className={cartclasses["cart-bill-wrapper"]}>
                        <div className={cartclasses["coupen"]}>
                            <Skeleton width={200} />
                            <Skeleton
                                count={2}
                                style={{
                                    width: "100%",
                                    marginBottom: "0.6rem",
                                }}
                            />
                        </div>
                        <div className={cartclasses["coupen"]}>
                            <Skeleton
                                count={2}
                                style={{
                                    width: "100%",
                                    marginBottom: "0.6rem",
                                }}
                            />
                        </div>
                        <div className={cartclasses["cart-bill"]}>
                            <Skeleton width={150} />

                            {Array(3)
                                .fill(3)
                                .map((_, i) => (
                                    <Skeleton style={{ width: "100%" }} />
                                ))}
                            <div
                                className={`${cartclasses["bill-data"]} ${cartclasses["total"]}`}>
                                <Skeleton style={{ width: "100%" }} />
                            </div>
                        </div>

                        <Skeleton style={{ width: "100%" }} count={2} />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Cart;
