import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import latestClasses from "../LatestProducts/latest.module.css";
import Skeleton from "react-loading-skeleton";

function ProductSlider(props) {
    const Navigate = useNavigate();

    const responsive = {
        1800: { items: 1 },
    };
    const items = props.banners.map((item) => {
        return (
            <div className={latestClasses["banner-slide"]}>
                <img
                    src={item.imageUrl}
                    alt="banner"
                    // style={{
                    //     width: "100%",
                    //     height: "100%",
                    //     maxHeight: "30rem",
                    // }}
                />
                {/* <button>Shop Now</button> */}
            </div>
        );
    });
    return (
        <div
            className={`${latestClasses["product-Container"]} ${latestClasses["product-Container-slider"]}`}>
            <div className={`${latestClasses["slider"]} ${"banner-slider"}`}>
                {props.loading ? (
                    <Skeleton height={300} style={{ width: "100%" }} />
                ) : (
                    <AliceCarousel
                        mouseTracking
                        autoPlay={true}
                        items={items}
                        responsive={responsive}
                        controlsStrategy="alternate"
                        disableDotsControls={false}
                        infinite={true}
                        autoPlayInterval={2000}
                    />
                )}
            </div>
        </div>
    );
}

export default ProductSlider;

/* 

<div
                    className={`${ProductClasses["slider-one"]} ${
                        activeTab === 1
                            ? ProductClasses["activetab"]
                            : ProductClasses["inactivetab"]
                    }`}>
                    <img src={slider} alt="bg" />
                    <div className={ProductClasses["slider-one-details"]}>
                        <h2>
                            Get rid of bacteria <br /> in your home
                        </h2>
                        <p>Get rid of all bacteria </p>
                        <div className={ProductClasses["slider-bacteria"]}>
                            <div>
                                <img
                                    src={bacterial}
                                    alt="bacteria"
                                    width={"30px"}
                                />
                                <p>Anti-Bacterial</p>
                            </div>
                            <div>
                                <img
                                    src={virus}
                                    alt="bacteria"
                                    width={"30px"}
                                />
                                <p>Anti-Virus</p>
                            </div>
                        </div>
                        <button
                            onClick={() => {
                                Navigate("/shop");
                            }}>
                            Shop Now
                        </button>
                    </div>
                </div>
*/
