import React from "react";
import { useForm } from "react-hook-form";
import HelpClasses from "./help.module.css";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Services } from "../../../services";

function Enquiry() {
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    const validatePhoneNumber = (value) => {
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        return phoneNumberPattern.test(value) || "Invalid phone number";
    };
    const handleInputChange = (e) => {
        const { value } = e.target;
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        if (value.length > 10) {
            setValue("MobileNumber", String(value).substring(0, 10));
            return;
        }
        if (phoneNumberPattern.test(value)) {
            setValue("MobileNumber", value);
        }
    };
    const onSubmit = (data) => {
        let formdata = JSON.stringify({ ...data });
        Services.helpForm("POST", formdata, token)
            .then((response) => {
                if (response.Status === 1) {
                    Navigate("/orders");
                    toast.success(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    return (
        <div className={HelpClasses["help-form"]}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3>Enquiry Form</h3>
                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="Name">Name</label>
                    <div>
                        <input
                            placeholder="Name"
                            type="text"
                            {...register("Name", {
                                required: true,
                                validate: (value) => {
                                    return !!value.trim();
                                },
                            })}
                        />
                        {errors?.Name && (
                            <p className={HelpClasses.error}>
                                Name is required
                            </p>
                        )}
                    </div>
                </div>
                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="mobileNumber">mobile number</label>
                    <div>
                        {" "}
                        <input
                            placeholder="Mobile number"
                            type="number"
                            {...register("MobileNumber", {
                                required: true,
                                validate: validatePhoneNumber,
                            })}
                            onChange={handleInputChange}
                        />
                        {errors?.MobileNumber && (
                            <p className={HelpClasses.error}>
                                Mobile number is required!
                            </p>
                        )}
                    </div>
                </div>
                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="EmailID">Email ID</label>
                    <div>
                        <input
                            placeholder="EmailID"
                            type="email"
                            {...register("EmailID", {
                                required: true,
                                validate: (value) => {
                                    return !!value.trim();
                                },
                            })}
                        />
                        {errors?.EmailID && (
                            <p className={HelpClasses.error}>
                                Email is required!
                            </p>
                        )}
                    </div>
                </div>
                <div className={HelpClasses["form-control"]}>
                    <label htmlFor="Description">Description</label>
                    <div>
                        <textarea
                            rows={5}
                            {...register("Description", {
                                required: true,
                                validate: (value) => {
                                    return !!value.trim();
                                },
                            })}></textarea>
                        {errors?.Description && (
                            <p className={HelpClasses.error}>
                                Description is required!
                            </p>
                        )}
                    </div>
                </div>
                <input type={"submit"} value="Submit" />
            </form>
        </div>
    );
}

export default Enquiry;
