import React, { useState, useEffect } from "react";
import latestClasses from "./latest.module.css";
// import ReactStars from "react-stars";
import "../../../App.css";
import { AiOutlineHeart } from "react-icons/ai";
import { RiShoppingBasket2Line } from "react-icons/ri";
import { GrFormSearch } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import Modal from "../../productDetails/quickview";
import image from "../../../Assets/image.png";
import useStore from "../../../store";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Services } from "../../../services";
import { toast } from "react-toastify";
import QuantityModal from "./quantityModal";
import AddtoCart from "./AddToCart";
import { Handlers } from "../../../Utilities/Handlers";
import ReactPixel from "react-facebook-pixel";

import ReactGA from "react-ga";
window.Buffer = window.Buffer || require("buffer").Buffer;
function Product(props) {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    let value = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [view, setview] = useState(false);
    const setWishlistCount = useStore((state) => state.setwishList);

    const setcartCount = useStore((state) => state.setcartCount);
    const auth = useStore((state) => state.isAuth);
    const setLogin = useStore((state) => state.setLoginModal);
    const [itemCount, setItemCount] = useState(1);
    const [notifyModal, setNotifyModal] = useState(false);
    const [addToCart, setAddtoCart] = useState(false);
    const [notifyProductId, setNotifyProductId] = useState();
    const warehouseid = sessionStorage.getItem("warehouseId");

    const [cartModal, setCartModal] = useState(false);
    const [prodid, setprodId] = useState();
    const [availableQty, setavailableQty] = useState();
    const wishlistCount = useStore((state) => state.wishlistCount);
    const detailHandler = () => {
        if (!auth) {
            setLogin("login");
            return;
        }
        let pathstring = props.heading.replace(/\s+/g, "-").toLowerCase();
        // Navigate(`/${pathstring}`, { state: props?.productId });

        Navigate(`/product-details?id=${props?.productId}&name=${pathstring}`);
    };
    const viewmodalHandler = () => {
        setview(true);
    };
    useEffect(() => {
        ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ID);
    }, []);
    const itemCountHandler = (e) => {
        setItemCount(e.target.value);
    };
    // useEffect(() => {
    //     if (props.availableQty < 10) {

    //     }
    // }, []);
    const NotifyHandler = (e) => {
        if (!sessionStorage.getItem("token")) {
            setLogin("login");
            return;
        }
        setNotifyModal(true);

        setNotifyProductId(e.currentTarget.getAttribute("prodId"));
        // let json = JSON.stringify({
        //     productId: ProductId,
        //     quantity: qty,
        //     warehouseId: warehouseid,
        // });
        // Services.NotfiyProduct("POST", json, token, props.id)
        //     .then((response) => {
        //         if (response.Status === 1) {
        //             // Message
        //             toast.success(`${response.Message}`, {
        //                 position: "top-center",
        //                 autoClose: 1000,
        //                 hideProgressBar: true,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //             });
        //         } else if (response.Status === 0) {
        //             toast.error(`${response.Message}`, {
        //                 position: "top-center",
        //                 autoClose: 1000,
        //                 hideProgressBar: true,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //             });
        //             if (response.Message === "Token expired") {
        //                 Navigate("/");
        //             }
        //         }
        //     })
        //     .catch((err) => {
        //         // alert(err);
        //         alert("something went wrong please try again");
        //         console.log(err);
        //     });
    };

    const deleteHandler = (e) => {
        e.stopPropagation();
        if (
            window.confirm(
                "Are you sure you want to remove Product from Wishlist"
            )
        ) {
            Services.removeWishlistItem(
                "DELETE",
                null,
                token,
                e.currentTarget.getAttribute("prodId")
            )
                .then((response) => {
                    if (response.Status === 1) {
                        ReactPixel.track("RemoveFromWishlist", {
                            content_name: props?.allData?.productName,
                            content_ids: [props?.allData?.productId],
                            content_type: "product",
                            value: props?.allData?.genifast_sellingPrice,
                            currency: "INR",
                            genericName: props?.allData?.genericName,
                            categoryId: props?.allData?.categoryId,
                        });
                        ReactGA.event({
                            category: "Wishlist",
                            action: "Removed",
                            label: `${props?.allData?.productName} - ${props?.allData?.productId}`,
                            value: props?.allData?.genifast_sellingPrice,
                        });
                        setWishlistCount(wishlistCount - 1);
                        props.getwishlist();
                        props.setload(false);
                    } else if (response.Status === 0) {
                        toast.error(`${response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    toast.error(`something went wrong please try again`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }
    };
    return (
        <>
            {cartModal && (
                <AddtoCart
                    setmodal={setCartModal}
                    prodid={prodid}
                    availableQty={availableQty}
                    allDetails={props.details}
                />
            )}
            {notifyModal && (
                <QuantityModal
                    productId={notifyProductId}
                    setmodal={setNotifyModal}
                    warehouseid={warehouseid}
                />
            )}
            {view && <Modal setview={setview} view={view} {...props} />}
            <div className={latestClasses["product"]}>
                <div className={latestClasses["product-image"]}>
                    <img
                        src={props.img}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = image;
                        }}
                        alt="product"
                        onClick={detailHandler}
                    />
                </div>
                <div className={latestClasses["product-desc"]}>
                    <h3 title={props.heading}>{props.heading}</h3>

                    {props.genericName && (
                        <p
                            className={`${latestClasses["company"]} ${latestClasses["generic-name"]}`}>
                            {" "}
                            {String(props.genericName).toLowerCase()}
                        </p>
                    )}
                    <p className={latestClasses["company"]}>
                        <span>
                            Company:{String(props.manufacturedBy).toLowerCase()}
                        </span>
                    </p>
                    <p className={latestClasses["company"]}>
                        <span>Packing Size:{props.packing}</span>
                    </p>
                    <p className={latestClasses["company"]}>
                        <span>Expiry:{props.expiry}</span>
                    </p>

                    <p
                        className={` ${
                            !auth
                                ? latestClasses["blur-text"]
                                : latestClasses["cost"]
                        }`}>
                        Price {auth ? `₹ ${props.sellingPrice}` : 0.0}
                    </p>
                    {auth && (
                        <div className={latestClasses["mrp"]}>
                            <p style={{ textTransform: "uppercase" }}>
                                MRP <del>₹ {props.cost}</del>
                            </p>
                            <p className={latestClasses["save"]}>
                                You save ₹
                                {Math.round(props.cost - props.sellingPrice)}
                            </p>
                        </div>
                    )}
                    <div className={latestClasses["add-items"]}>
                        {/* {props.availableQty <= 0 && <p>OUT OF STOCK</p>} */}
                        {props.availableQty > 0 ? (
                            <button
                                prodId={props?.productId}
                                onClick={(e) => {
                                    if (!auth) {
                                        setLogin("login");
                                        return;
                                    }
                                    setprodId(
                                        e.currentTarget.getAttribute("prodId")
                                    );
                                    props.setallDetails(props.allData);
                                    setCartModal(true);
                                    setavailableQty(props.availableQty);
                                }}
                                disabled={props.availableQty <= 0}>
                                Add to Cart
                            </button>
                        ) : (
                            <button
                                style={{ backgroundColor: "red" }}
                                onClick={NotifyHandler}
                                prodId={props?.productId}>
                                Notify Product
                            </button>
                        )}
                    </div>
                </div>
                <div className={latestClasses["product-action-btns"]}>
                    {props.wishlist ? (
                        <div className={latestClasses["button-one"]}>
                            <button
                                prodId={props?.productId}
                                onClick={deleteHandler}>
                                <RiDeleteBin5Line size={17} color="#103178" />
                            </button>

                            <span className={latestClasses["tooltiptext"]}>
                                Delete
                            </span>
                        </div>
                    ) : (
                        <div className={latestClasses["button-one"]}>
                            <button
                                prodId={props?.productId}
                                onClick={(e) => {
                                    console.log(props.allData);
                                    ReactPixel.track("AddToWishlist", {
                                        content_name:
                                            props?.allData?.productName,
                                        content_ids: [
                                            props?.allData?.productId,
                                        ],
                                        content_type: "product",
                                        value: props?.allData
                                            ?.genifast_sellingPrice,
                                        currency: "INR",
                                        genericName:
                                            props?.allData?.genericName,
                                        categoryId: props?.allData?.categoryId,
                                    });
                                    ReactGA.event({
                                        category: "Wishlist",
                                        action: "Add to wishlist",
                                        label: `${props?.allData?.productName}-${props?.allData?.productId}`,
                                    });
                                    Handlers.wishlist(
                                        e.currentTarget.getAttribute("prodId"),
                                        auth,
                                        setLogin,
                                        setWishlistCount
                                    );
                                }}>
                                <AiOutlineHeart size={17} color="#103178" />
                            </button>
                            <span className={latestClasses["tooltiptext"]}>
                                {props.wishlist ? "Delete" : "wishlist"}
                            </span>
                        </div>
                    )}
                    {/* <div className={latestClasses["button-one"]}>
                        <button>
                            <RiMenu3Fill size={17} color="#103178" />
                        </button>
                        <span className={latestClasses["tooltiptext"]}>
                            Compare
                        </span>
                    </div> */}

                    <div className={latestClasses["button-one"]}>
                        <button onClick={viewmodalHandler}>
                            <GrFormSearch size={25} color="#103178" />
                        </button>
                        <span className={latestClasses["tooltiptext"]}>
                            Quick View
                        </span>
                    </div>

                    <div className={latestClasses["button-one"]}>
                        <button
                            prodId={props?.productId}
                            disabled={props.availableQty <= 0}
                            onClick={(e) => {
                                if (!auth) {
                                    setLogin("login");
                                    return;
                                }
                                // Handlers.AddToCart(
                                //     itemCount,
                                //     e.currentTarget.getAttribute("prodId"),
                                //     auth,
                                //     setLogin,
                                //     props.availableQty,
                                //     setcartCount,
                                //     warehouseid
                                // );
                                setprodId(
                                    e.currentTarget.getAttribute("prodId")
                                );
                                props.setallDetails(props.allData);
                                setCartModal(true);
                                setavailableQty(props.availableQty);
                            }}>
                            <RiShoppingBasket2Line size={17} color="#103178" />
                        </button>
                        <span className={latestClasses["tooltiptext"]}>
                            Add to Cart
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Product;
