import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import HelpClasses from "../help/help.module.css";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Services } from "../../../services";
import user from "../../../Assets/profile.svg";
import ProfileLayout from "../profileLayout";
import { BsCloudUpload } from "react-icons/bs";
import { FileUploader } from "react-drag-drop-files";
import { FiCamera } from "react-icons/fi";
import S3FileUpload from "react-s3";
import { config } from "../../../s3config";
import { GrFormClose } from "react-icons/gr";
import pincodeDirectory from "india-pincode-lookup";
import { State } from "country-state-city";
import uuid from "react-uuid";
window.Buffer = window.Buffer || require("buffer").Buffer;

function Enquiry() {
    const validatePhoneNumber = (value) => {
        const phoneNumberPattern =
            /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/;
        return phoneNumberPattern.test(value) || "Invalid phone number";
    };
    const alphaOnly = /^[A-Za-z]+$/i;
    let states = State.getStatesOfCountry("IN");
    const Navigate = useNavigate();
    const [fileNames, setFileNames] = useState([]);
    const [fileCount, setfileCount] = useState(0);
    const [uploadFileNames, setUploadFileNames] = useState([]);
    const [profilepic, setprofilepic] = useState(user);
    // eslint-disable-next-line
    const [base64, setbase64] = useState(undefined);
    const [profileUrl, setProfileUrl] = useState("");
    const [userType, setUserType] = useState("DOCTOR");

    const [gstfileNames, setgstFileNames] = useState([]);
    const [gstfileCount, setgstfileCount] = useState(0);
    const [uploadgstfiles, setuploadgstfiles] = useState([]);

    const [pincode, setpincode] = useState("");
    const [pincodeError, setpincodeError] = useState(false);
    const pinref = useRef();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    console.log(pincodeDirectory.lookup(520004));
    const userDetails = JSON.parse(sessionStorage.getItem("userdetails"));
    useEffect(() => {
        setpincode(userDetails.pincode);
        reset({ ...userDetails });
        setUserType(userDetails.userType);
        if (userDetails?.profilePic !== "NA") {
            setprofilepic(userDetails?.profilePic);
        }
        // eslint-disable-next-line
    }, []);
    const pinchange = (e) => {
        if (e.target.value.trim().length <= 6) {
            setpincode(e.target.value);
            setpincodeError(false);
        }
    };
    const onSubmit = (data) => {
        let Formdata = { ...data };
        if (userType === "DOCTOR" && uploadFileNames.length > 0) {
            Formdata = { ...Formdata, doc_letterheadURL: [...uploadFileNames] };
        } else if (userType === "CHEMIST" && uploadFileNames.length > 0) {
            Formdata = { ...Formdata, drugLicenseURL: [...uploadFileNames] };
        }

        // return;
        if (pincode.trim().length != 6 || pincode.slice(0, 1) == 0) {
            setpincodeError(true);
            pinref.current.focus();
            return;
        }
        if (profileUrl.length > 0) {
            Formdata = {
                ...Formdata,
                profilePic: profileUrl,
                GSTFileURL: [...uploadgstfiles],
            };
        }
        if (pincode.length == 6) {
            Formdata = { ...Formdata, pincode: pincode };
        }
        Services.updateProfile(
            "PUT",
            JSON.stringify(Formdata),
            token,
            userDetails.userId
        )
            .then((response) => {
                if (response.Status === 1) {
                    sessionStorage.setItem(
                        "userdetails",
                        JSON.stringify(response?.userdetails)
                    );
                    Navigate("/orders");
                    toast.success(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    const profilechange = (e) => {
        if (
            e.target.files[0].type === "image/png" ||
            e.target.files[0].type === "image/jpeg"
        ) {
            const timestamp = Date.now();
            const newfile = new File(
                e.target.files,
                `${timestamp}.${String(e.target.files[0].type).split("/")[1]}`
            );
            S3FileUpload.uploadFile(newfile, config)
                .then((res) => {
                    setProfileUrl(
                        `${timestamp}.${
                            String(e.target.files[0].type).split("/")[1]
                        }`
                    );
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                });
            setprofilepic(URL.createObjectURL(e.target.files[0]));
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function () {
                setbase64(reader.result);
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        } else {
            alert("upload file in png and jpeg fromat");
        }
    };
    const base64Convert = (file) => {
        let reader = new FileReader();
        // setfileCount((prev) => prev + 1);
        reader.readAsDataURL(file);
        reader.onload = function () {
            setFileNames((prev) => [
                ...prev,
                { filename: reader.result, id: uuid() },
            ]);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const handleChange = (files) => {
        if (fileCount > 5 || Object.keys(files).length >= 6) {
            alert("Uploading more than 5 files is not permitted");
            return;
        }
        Object.keys(files).every((key) => {
            if (
                files[key].type === "image/png" ||
                files[key].type === "image/jpeg" ||
                files[key].type === "application/pdf"
            ) {
                const timestamp = Date.now();
                const newfile = new File(
                    [files[key]],
                    `${timestamp}.${String(files[key].type).split("/")[1]}`
                );
                let count = fileCount + Object.keys(files).length;
                if (count >= 6) {
                    alert("Uploading more than 5 files is not permitted");
                    return false;
                }
                base64Convert(newfile);
                setfileCount((prev) => prev + 1);
                S3FileUpload.uploadFile(newfile, config)
                    .then((res) => {
                        setUploadFileNames((prev) => [
                            ...prev,
                            `${timestamp}.${
                                String(files[key].type).split("/")[1]
                            }`,
                        ]);
                    })
                    .catch((err) => {
                        alert(err);
                        console.log(err);
                    });
            } else {
                alert(`${files[key].name} is neither Image nor PDF File`);
                return false;
            }
            return true;
        });
    };
    const removeHandler = (e) => {
        const id = e.currentTarget.getAttribute("id");
        if (e.currentTarget.getAttribute("usertype") === "gstfile") {
            const new_files_array = gstfileNames.filter(
                (item) => item.id != id
            );
            setgstFileNames(new_files_array);
            setgstfileCount((prev) => prev - 1);
            return;
        }
        // eslint-disable-next-line
        const new_files_array = fileNames.filter((item) => item.id != id);
        setFileNames(new_files_array);
        setfileCount((prev) => prev - 1);
    };
    const fileuploadDesign = (
        <div className={HelpClasses["add-item"]}>
            <BsCloudUpload className={HelpClasses["upload-icon"]} />
            <h5>Drag Your Image here</h5>
            <p>(Only *.jpeg and *.png images will be accepted)</p>
        </div>
    );
    const gstbase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setgstFileNames((prev) => [
                ...prev,
                { filename: reader.result, id: uuid() },
            ]);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const gsthandleChange = (files) => {
        if (gstfileCount > 5 || Object.keys(files).length >= 6) {
            alert("Uploading more than 5 files is not permitted");
            return;
        }
        Object.keys(files).every((key) => {
            if (
                files[key].type === "image/png" ||
                files[key].type === "image/jpeg" ||
                files[key].type === "application/pdf"
            ) {
                const timestamp = Date.now();
                const newfile = new File(
                    [files[key]],
                    `${timestamp}.${String(files[key].type).split("/")[1]}`
                );
                let count = gstfileCount + Object.keys(files).length;
                if (count >= 6) {
                    alert("Uploading more than 5 files is not permitted");
                    return false;
                }
                gstbase64(newfile);
                setgstfileCount((prev) => prev + 1);
                S3FileUpload.uploadFile(newfile, config)
                    .then((res) => {
                        setuploadgstfiles((prev) => [
                            ...prev,
                            `${timestamp}.${
                                String(files[key].type).split("/")[1]
                            }`,
                        ]);
                    })
                    .catch((err) => {
                        alert(err);
                        console.log(err);
                    });
            } else {
                alert(`${files[key].name} is neither image Nor PDF`);
                return false;
            }
            return true;
        });
    };
    return (
        <ProfileLayout isactive={"editProfile"}>
            <div className={HelpClasses["help-form"]}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h3>Edit Profile</h3>
                    <div className={HelpClasses.imageupload}>
                        <img
                            src={profilepic}
                            className={HelpClasses.profile_pic_image}
                            alt=""
                        />
                        <label
                            htmlFor="file-input"
                            className={HelpClasses.profilepic_icon}>
                            <FiCamera size={20} color="white" />
                        </label>

                        <input
                            id="file-input"
                            type="file"
                            accept="image/jpeg, image/png,.jpeg, .png"
                            onChange={profilechange}
                        />
                    </div>
                    <div className={HelpClasses["form-control"]}>
                        <label htmlFor="ownerName">Owner Name</label>
                        <div>
                            <input
                                placeholder="Owner name"
                                type="text"
                                {...register("ownerName", {
                                    required: true,
                                    validate: (value) => {
                                        return !!value.trim();
                                    },
                                })}
                            />
                            {errors?.ownerName && (
                                <p className={HelpClasses.error}>
                                    Enter a valid Name !
                                </p>
                            )}
                        </div>
                    </div>
                    {userType !== "DOCTOR" && (
                        <div className={HelpClasses["form-control"]}>
                            <label htmlFor="shopName">Shop Name</label>
                            <div>
                                <input
                                    placeholder="Shop Name"
                                    type="text"
                                    {...register("shopName", {
                                        required: userType !== "DOCTOR",
                                        validate: (value) => {
                                            if (userType == "DOCTOR") {
                                                return true;
                                            } else {
                                                return !!value.trim();
                                            }
                                        },
                                    })}
                                />
                                {errors?.shopName && (
                                    <p className={HelpClasses.error}>
                                        shop Name is required
                                    </p>
                                )}
                            </div>
                        </div>
                    )}

                    <div className={HelpClasses["form-control"]}>
                        <label htmlFor="address">Address</label>
                        <div>
                            <input
                                placeholder="Address"
                                type="text"
                                {...register("address", {
                                    required: true,
                                    validate: (value) => {
                                        return !!value.trim();
                                    },
                                })}
                            />
                            {errors?.address && (
                                <p className={HelpClasses.error}>
                                    Address is required!
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={HelpClasses["form-control"]}>
                        <label htmlFor="city">City</label>
                        <div>
                            <input
                                placeholder="city"
                                type="text"
                                {...register("city", {
                                    pattern: alphaOnly,
                                    required: true,
                                    validate: (value) => {
                                        return !!value.trim();
                                    },
                                })}
                            />
                            {errors?.city && (
                                <p className={HelpClasses.error}>
                                    city is required!
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={HelpClasses["form-control"]}>
                        <label htmlFor="state">state</label>
                        <div>
                            <select
                                {...register("state", {
                                    required: "State is required!",
                                })}
                                defaultValue="">
                                <option value="" disabled>
                                    Select State
                                </option>
                                {states.length > 0 &&
                                    states.map((item) => (
                                        <option value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                            {errors?.state && (
                                <p className={HelpClasses.error}>
                                    State is required!
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={HelpClasses["form-control"]}>
                        <label htmlFor="pincode">pincode</label>
                        <div>
                            <input
                                placeholder="pincode"
                                type="number"
                                onChange={pinchange}
                                value={pincode}
                                ref={pinref}
                            />
                            {pincodeError && (
                                <p className={HelpClasses.error}>
                                    Enter a valid pincode
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={HelpClasses["form-control"]}>
                        <label htmlFor="mci_registrationNo">
                            mci registration number
                        </label>
                        <div>
                            <input
                                placeholder="mci registration number"
                                type="text"
                                {...register("mci_registrationNo", {
                                    required: userType === "DOCTOR",
                                    validate: (value) => {
                                        if (userType !== "DOCTOR") {
                                            return true;
                                        } else {
                                            return !!value.trim();
                                        }
                                    },
                                })}
                            />
                            {errors?.mci_registrationNo && (
                                <p className={HelpClasses.error}>
                                    mci_registrationNo is required!
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={HelpClasses["form-control"]}>
                        <label htmlFor="GSTNo">GST Number</label>
                        <div>
                            <input
                                placeholder="GST Number"
                                type="text"
                                {...register("GSTNo", {
                                    required: userType !== "DOCTOR",
                                    validate: (value) => {
                                        if (userType === "DOCTOR") {
                                            return true;
                                        }
                                        return !!value.trim();
                                    },
                                })}
                            />
                            {errors?.GSTNo && (
                                <p className={HelpClasses.error}>
                                    {errors?.GSTNo?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div
                        className={`${HelpClasses["form-control"]} ${HelpClasses["image-form-control"]}`}>
                        <label htmlFor="doc_letterheadURL">GST File </label>
                        <div className={HelpClasses["uploadfile"]}>
                            <FileUploader
                                classes={HelpClasses["upload-image"]}
                                multiple={true}
                                handleChange={gsthandleChange}
                                name="file"
                                children={fileuploadDesign}
                                // types={fileTypes}
                            />
                        </div>
                        <div className={HelpClasses["uploaded-img"]}>
                            {gstfileNames.map((item) => {
                                return (
                                    <div
                                        className={
                                            HelpClasses["uploaded-name"]
                                        }>
                                        <button
                                            type="button"
                                            id={item.id}
                                            usertype={"gstfile"}
                                            onClick={removeHandler}>
                                            <GrFormClose
                                                color="white"
                                                className={
                                                    HelpClasses[
                                                        "image-deleteIcon"
                                                    ]
                                                }
                                            />
                                        </button>
                                        <img
                                            src={item.filename}
                                            alt="uploaded-file"
                                            width={"140px"}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {userType === "DOCTOR" && (
                        <div
                            className={`${HelpClasses["form-control"]} ${HelpClasses["image-form-control"]}`}>
                            <label htmlFor="doc_letterheadURL">
                                DOCTOR LETTERHEAD
                            </label>
                            <div className={HelpClasses["uploadfile"]}>
                                <FileUploader
                                    classes={HelpClasses["upload-image"]}
                                    multiple={true}
                                    handleChange={handleChange}
                                    name="file"
                                    children={fileuploadDesign}
                                    // types={fileTypes}
                                />
                            </div>
                            <div className={HelpClasses["uploaded-img"]}>
                                {fileNames.map((item) => {
                                    return (
                                        <div
                                            className={
                                                HelpClasses["uploaded-name"]
                                            }>
                                            <button
                                                type="button"
                                                id={item.id}
                                                usertype={"doctor"}
                                                onClick={removeHandler}>
                                                <GrFormClose
                                                    color="white"
                                                    className={
                                                        HelpClasses[
                                                            "image-deleteIcon"
                                                        ]
                                                    }
                                                />
                                            </button>
                                            <img
                                                src={item.filename}
                                                alt="uploaded-file"
                                                width={"140px"}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {userType === "CHEMIST" && (
                        <div
                            className={`${HelpClasses["form-control"]} ${HelpClasses["image-form-control"]}`}>
                            <label htmlFor="doc_letterheadURL">
                                DOCTOR LETTERHEAD
                            </label>
                            <div className={HelpClasses["uploadfile"]}>
                                <FileUploader
                                    classes={HelpClasses["upload-image"]}
                                    multiple={true}
                                    handleChange={handleChange}
                                    name="file"
                                    children={fileuploadDesign}
                                    // types={fileTypes}
                                />
                            </div>
                            <div className={HelpClasses["uploaded-img"]}>
                                {fileNames.map((item) => {
                                    return (
                                        <div
                                            className={
                                                HelpClasses["uploaded-name"]
                                            }>
                                            <button
                                                type="button"
                                                id={item.id}
                                                usertype={"doctor"}
                                                onClick={removeHandler}>
                                                <GrFormClose
                                                    color="white"
                                                    className={
                                                        HelpClasses[
                                                            "image-deleteIcon"
                                                        ]
                                                    }
                                                />
                                            </button>
                                            <img
                                                src={item.filename}
                                                alt="uploaded-file"
                                                width={"140px"}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <input type={"submit"} value="Submit" />
                </form>
            </div>
        </ProfileLayout>
    );
}

export default Enquiry;
