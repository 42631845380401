import React from "react";
import Layout from "../../Utilities/layout";
import Footer from "../HomePage/footer/footer";
import SuccessClasses from "./sucess.module.css";
import SuccessImg from "../../Assets/success.png";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
function Success() {
    const [search] = useSearchParams();
    const orderNumber = search.get("orderNo");
    const { id } = useParams();
    const Navigate = useNavigate();
    return (
        <Layout>
            <div className={SuccessClasses["success-wrapper"]}>
                <img src={SuccessImg} alt="success" />
                <h3>Order Successful</h3>
                <p className={SuccessClasses["greeting"]}>
                    Thank you for your order
                </p>
                <p className={SuccessClasses["orderid"]}>
                    Order Number : {orderNumber}
                </p>
                <button
                    onClick={() => {
                        Navigate(`/orders/${id}`);
                    }}>
                    Order Details
                </button>
            </div>
            <Footer />
        </Layout>
    );
}

export default Success;
