import React from "react";
import LoginClasses from "./login.module.css";
import LoginLogo from "../../Assets/login/forgotpassword.jpeg";
import { useForm } from "react-hook-form";
import { IoClose } from "react-icons/io5";
function Login(props) {
    const {
        register,
        handleSubmit,

        formState: { errors },
    } = useForm();
    const onSubmit = (data) => console.log(data);
    const loginClose = (e) => {
        props.setlogin("");
    };
    return (
        <div className={LoginClasses["login-wrapper"]}>
            <div
                className={`${LoginClasses["signup-subwrapper"]} ${LoginClasses["forgot-password"]}`}>
                <div className={LoginClasses["close"]}>
                    <button onClick={loginClose}>
                        <IoClose />
                    </button>
                </div>
                <div className={LoginClasses["login-logo"]}>
                    <img src={LoginLogo} alt="login-logo" />
                </div>
                <div className={LoginClasses["login-form"]}>
                    <h3>Forgot Password</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                {...register("EmailID", {
                                    required: "Email is required!",
                                })}
                            />
                            {errors?.EmailID && (
                                <p className={LoginClasses.error}>
                                    {errors?.EmailID?.message}
                                </p>
                            )}
                        </div>

                        <input type="submit" value="Recover Password" />

                        <span></span>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
