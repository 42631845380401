import Stepper from "react-stepper-horizontal";
import React, { useState, useEffect, useRef } from "react";
import LoginClasses from "./login.module.css";
import { useForm } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
import { Services } from "../../services";
import { toast } from "react-toastify";
import { useSearchParams, useNavigate, NavLink } from "react-router-dom";
import S3FileUpload from "react-s3";
import { config } from "../../s3config";
import { State } from "country-state-city";
import { GrFormClose } from "react-icons/gr";
import Modal from "../../Utilities/error";
import Logo from "../../Assets/logo.png";
import uuid from "react-uuid";
window.Buffer = window.Buffer || require("buffer").Buffer;
function SignupDetails() {
    const alphaOnly = /^[A-Za-z]+$/i;
    const [searchParams] = useSearchParams();
    const [modal, setmodal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    let states = State.getStatesOfCountry("IN");
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();
    const formData = watch();
    const [fileNames, setFileNames] = useState([]);
    const [fileCount, setfileCount] = useState(0);
    const [uploadFileNames, setUploadFileNames] = useState([]);

    const [DrugLicensefileNames, setDrugLicenseFileNames] = useState([]);
    const [DrugLicensefileCount, setDrugLicensefileCount] = useState(0);
    const [uploadDrugLicensefiles, setDrugLicensefiles] = useState([]);

    const [gstfileNames, setgstFileNames] = useState([]);
    const [gstfileCount, setgstfileCount] = useState(0);
    const [uploadgstfiles, setuploadgstfiles] = useState([]);
    const [termsCheck, settermscheck] = useState(false);

    const [pincode, setpincode] = useState("");
    const [pinError, setpinError] = useState(false);
    const pinref = useRef();

    const usertype = ["DOCTOR", "CHEMIST", "HOSPITAL"];
    useEffect(() => {
        // states = ;
        const mobilenumber = searchParams.get("mobileNumber");
        reset({ mobileNumber: mobilenumber });
    }, []);
    const changeHandler = (e) => {
        settermscheck((prev) => !prev);
    };
    const pinchange = (e) => {
        console.log(e.target.value.trim().length);
        if (e.target.value.trim().length <= 6) {
            setpinError(false);
            setpincode(e.target.value);
        } else {
            setpinError(true);
        }
    };
    const base64 = (file) => {
        let reader = new FileReader();
        // setfileCount((prev) => prev + 1);
        reader.readAsDataURL(file);
        reader.onload = function () {
            setFileNames((prev) => [
                ...prev,
                { filename: reader.result, id: uuid() },
            ]);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const handleChange = (files) => {
        if (fileCount > 5 || Object.keys(files).length >= 6) {
            alert("Uploading more than 5 files is not permitted");
            return;
        }
        Object.keys(files).every((key) => {
            if (
                files[key].type === "image/png" ||
                files[key].type === "image/jpeg" ||
                files[key].type === "application/pdf"
            ) {
                const timestamp = Date.now();
                const newfile = new File(
                    [files[key]],
                    `${timestamp}.${String(files[key].type).split("/")[1]}`
                );
                let count = fileCount + Object.keys(files).length;
                if (count >= 6) {
                    alert("Uploading more than 5 files is not permitted");
                    return false;
                }
                base64(newfile);
                setfileCount((prev) => prev + 1);
                S3FileUpload.uploadFile(newfile, config)
                    .then((res) => {
                        setUploadFileNames((prev) => [
                            ...prev,
                            `${timestamp}.${
                                String(files[key].type).split("/")[1]
                            }`,
                        ]);
                    })
                    .catch((err) => {
                        alert(err);
                        console.log(err);
                    });
            } else {
                alert(`${files[key].name} is neither image Nor PDF`);
                return false;
            }
            return true;
        });
    };
    const gstbase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setgstFileNames((prev) => [
                ...prev,
                { filename: reader.result, id: uuid() },
            ]);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const gsthandleChange = (files) => {
        if (gstfileCount > 5 || Object.keys(files).length >= 6) {
            alert("Uploading more than 5 files is not permitted");
            return;
        }
        Object.keys(files).every((key) => {
            if (
                files[key].type === "image/png" ||
                files[key].type === "image/jpeg" ||
                files[key].type === "application/pdf"
            ) {
                const timestamp = Date.now();
                const newfile = new File(
                    [files[key]],
                    `${timestamp}.${String(files[key].type).split("/")[1]}`
                );
                let count = gstfileCount + Object.keys(files).length;
                if (count >= 6) {
                    alert("Uploading more than 5 files is not permitted");
                    return false;
                }
                gstbase64(newfile);
                setgstfileCount((prev) => prev + 1);
                S3FileUpload.uploadFile(newfile, config)
                    .then((res) => {
                        setuploadgstfiles((prev) => [
                            ...prev,
                            `${timestamp}.${
                                String(files[key].type).split("/")[1]
                            }`,
                        ]);
                    })
                    .catch((err) => {
                        alert(err);
                        console.log(err);
                    });
            } else {
                alert(`${files[key].name} is neither image Nor PDF`);
                return false;
            }
            return true;
        });
    };
    const DrugLicensebase64 = (file) => {
        let reader = new FileReader();
        // setfileCount((prev) => prev + 1);
        reader.readAsDataURL(file);
        reader.onload = function () {
            setDrugLicenseFileNames((prev) => [
                ...prev,
                { filename: reader.result, id: uuid() },
            ]);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const druglicenseChange = (files) => {
        if (DrugLicensefileCount > 5 || Object.keys(files).length >= 6) {
            alert("Uploading more than 5 files is not permitted");
            return;
        }
        Object.keys(files).every((key) => {
            if (
                files[key].type === "image/png" ||
                files[key].type === "image/jpeg" ||
                files[key].type === "application/pdf"
            ) {
                const timestamp = Date.now();
                const newfile = new File(
                    [files[key]],
                    `${timestamp}.${String(files[key].type).split("/")[1]}`
                );
                let count = DrugLicensefileCount + Object.keys(files).length;
                if (count >= 6) {
                    alert("Uploading more than 5 files is not permitted");
                    return false;
                }
                DrugLicensebase64(newfile);
                setDrugLicensefileCount((prev) => prev + 1);
                S3FileUpload.uploadFile(newfile, config)
                    .then((res) => {
                        setDrugLicensefiles((prev) => [
                            ...prev,
                            `${timestamp}.${
                                String(files[key].type).split("/")[1]
                            }`,
                        ]);
                    })
                    .catch((err) => {
                        alert(err);
                        console.log(err);
                    });
            } else {
                alert(`${files[key].name} is neither Image nor PDF file`);
                return false;
            }
            return true;
        });
    };
    const removeHandler = (e) => {
        // console.log(fileNames);
        const id = e.currentTarget.getAttribute("id");
        // console.log(id);
        // eslint-disable-next-line
        if (e.currentTarget.getAttribute("usertype") === "doctor") {
            const new_files_array = fileNames.filter((item) => item.id != id);
            setFileNames(new_files_array);
            setfileCount((prev) => prev - 1);
        } else if (e.currentTarget.getAttribute("usertype") === "gstfile") {
            const new_files_array = gstfileNames.filter(
                (item) => item.id != id
            );
            setgstFileNames(new_files_array);
            setgstfileCount((prev) => prev - 1);
        } else if (e.currentTarget.getAttribute("usertype") === "chemist") {
            const new_files_array = DrugLicensefileNames.filter(
                (item) => item.id != id
            );
            setDrugLicenseFileNames(new_files_array);
            setDrugLicensefileCount((prev) => prev - 1);
        }
    };

    const fileuploadDesign = (
        <div className={LoginClasses["add-item"]}>
            <BsCloudUpload className={LoginClasses["upload-icon"]} />
            <h5>Drag Your Image here</h5>
            <p>(Only *.jpeg ,*.png ,*.pdf will be accepted)</p>
        </div>
    );
    const onSubmit = (data) => {
        if (data.userType === "DOCTOR" && uploadFileNames.length === 0) {
            // alert("DOCTOR LETTERHEAD  is Madatory ");
            setErrorMessage("Please Upload Doctor Letterhead file");
            setmodal(true);
            return;
        }
        if (
            (data.userType === "CHEMIST" &&
                uploadDrugLicensefiles.length === 0) ||
            (data.userType === "HOSPITAL" &&
                uploadDrugLicensefiles.length === 0)
        ) {
            setErrorMessage("Please Upload Drug license file");
            setmodal(true);
            // alert("DRUG LICENSE URL is Madatory ");
            return;
        }
        if (gstfileNames.length === 0 && data.userType != "DOCTOR") {
            // alert("GST FILE URL is Madatory");
            setErrorMessage("Please Upload GST File file");
            setmodal(true);
            return;
        }
        if (!termsCheck) {
            setErrorMessage("please accept Terms and conditions");
            setmodal(true);
            // alert("please accept Terms and conditions");
            return;
        }
        if (pincode.trim().length != 6 || pincode.slice(0, 1) == 0) {
            setpinError(true);
            pinref.current.focus();
            return;
        }

        let submitData = {
            ...data,
            signupFrom: "web",
            pincode: pincode,
            doc_letterheadURL: [...uploadFileNames],
            drugLicenseURL: [...uploadDrugLicensefiles],
            GSTFileURL: [...uploadgstfiles],
        };

        Services.userSignupFormTwo("POST", JSON.stringify(submitData))
            .then((response) => {
                if (response.Status === 1) {
                    Navigate("/");
                } else if (response.Status === 0) {
                    if (
                        response.Message ===
                        "User Signup successful and account is under approval. Please try again after some time."
                    ) {
                        Navigate("/SignupSuccess");
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    // const userType=
    const shopnameHandler = (e) => {
        if (!formData.userType) {
            alert("please select userType");
            e.target.blur();
        }
    };
    return (
        <>
            {/* <Layout> */}
            {modal && <Modal errorMessage={errorMessage} setmodal={setmodal} />}
            <div className={LoginClasses["details-wrapper"]}>
                <div className={LoginClasses["signup-logo"]}>
                    <img src={Logo} alt="logo" onClick={() => Navigate("/")} />
                </div>
                <h3 className={LoginClasses["signupDataHeading"]}>
                    Registration
                </h3>
                <div className={LoginClasses["signupDetails-form"]}>
                    <div>
                        <Stepper
                            steps={[
                                { title: "Mobile verified" },
                                { title: "User-details" },
                                { title: "" },
                            ]}
                            activeStep={1}
                            defaultBorderWidth={7}
                        />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="MOBILE NUMBER">MOBILE NUMBER</label>
                            <input
                                // placeholder="Enter City"
                                disabled
                                type="number"
                                {...register("mobileNumber")}
                            />
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="Usertype">USER </label>
                            <select
                                {...register("userType", {
                                    required: "User Type is required!",
                                })}
                                defaultValue="">
                                <option value="" disabled>
                                    Select user type
                                </option>
                                {usertype.map((type) => {
                                    return <option value={type}>{type}</option>;
                                })}
                            </select>
                            {errors?.userType && (
                                <p className={LoginClasses.error}>
                                    {errors?.userType?.message}
                                </p>
                            )}
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="EMAIL">EMAIL</label>
                            <input
                                placeholder="Enter Email"
                                type="email"
                                {...register("emailId", {
                                    required: "Email is required!",
                                    validate: (value) => {
                                        return !!value.trim();
                                    },
                                })}
                            />
                            {errors?.emailId && (
                                <p className={LoginClasses.error}>
                                    {errors?.emailId?.message}
                                </p>
                            )}
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="Name">OWNER NAME</label>
                            <input
                                placeholder="Enter Owner Name"
                                type="text"
                                {...register("ownerName", {
                                    required: "Name is required!",
                                    validate: (value) => {
                                        return !!value.trim();
                                    },
                                })}
                            />
                            {errors?.ownerName && (
                                <p className={LoginClasses.error}>
                                    Name is required!
                                </p>
                            )}
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="shopName">SHOP NAME</label>
                            <input
                                placeholder="Enter Shop Name"
                                type="text"
                                onFocus={shopnameHandler}
                                {...register("shopName", {
                                    required: formData.userType !== "DOCTOR",
                                    validate: (value) => {
                                        if (formData.userType == "DOCTOR") {
                                            return true;
                                        } else {
                                            return !!value.trim();
                                        }
                                    },
                                })}
                            />
                            {errors?.shopName && (
                                <p className={LoginClasses.error}>
                                    shop name is required!
                                </p>
                            )}
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="ADDRESS">ADDRESS</label>
                            <input
                                placeholder="Enter Address"
                                type="text"
                                {...register("address", {
                                    required: "Address is required!",
                                    validate: (value) => {
                                        return !!value.trim();
                                    },
                                })}
                            />
                            {errors?.address && (
                                <p className={LoginClasses.error}>
                                    Address is required!
                                </p>
                            )}
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="city">CITY</label>
                            <input
                                placeholder="Enter City"
                                type="text"
                                {...register("city", {
                                    pattern: alphaOnly,
                                    required: "City is required!",
                                    validate: (value) => {
                                        return !!value.trim();
                                    },
                                })}
                            />
                            {errors?.city && (
                                <p className={LoginClasses.error}>
                                    Enter a valid City
                                </p>
                            )}
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="pincode">PINCODE</label>
                            <input
                                placeholder="Enter Pincode"
                                type="value"
                                onChange={pinchange}
                                value={pincode}
                                ref={pinref}
                            />
                            {pinError && (
                                <p className={LoginClasses.error}>
                                    Enter a valid pin
                                </p>
                            )}
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="mci_registrationNo">
                                MCI REGISTRATION NUMBER
                            </label>
                            <input
                                placeholder="Enter Mci registration number"
                                type="text"
                                {...register("mci_registrationNo", {
                                    required: formData.userType === "DOCTOR",
                                    validate: (value) => {
                                        if (formData.userType !== "DOCTOR") {
                                            return true;
                                        } else {
                                            return !!value.trim();
                                        }
                                    },
                                })}
                            />
                            {errors?.mci_registrationNo && (
                                <p className={LoginClasses.error}>
                                    MCI registration is required!
                                </p>
                            )}
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="state">STATE</label>
                            <select
                                {...register("state", {
                                    required: "State is required!",
                                })}
                                defaultValue="">
                                <option value="" disabled>
                                    Select State
                                </option>
                                {states.length > 0 &&
                                    states.map((item) => (
                                        <option value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                            {errors?.state && (
                                <p className={LoginClasses.error}>
                                    State is required!
                                </p>
                            )}
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="GSTNo">GST NUMBER</label>
                            <input
                                placeholder="Enter GST No"
                                type="text"
                                onFocus={shopnameHandler}
                                {...register("GSTNo", {
                                    required: formData.userType !== "DOCTOR",
                                    validate: (value) => {
                                        if (formData.userType === "DOCTOR") {
                                            return true;
                                        }
                                        return !!value.trim();
                                    },
                                })}
                            />
                            {errors?.GSTNo && (
                                <p className={LoginClasses.error}>
                                    GSTNo is required!
                                </p>
                            )}
                        </div>

                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="drugLicenseNo">
                                DRUG LICENSE NUMBER
                            </label>
                            <input
                                placeholder="Enter Drug license number"
                                type="text"
                                onFocus={shopnameHandler}
                                {...register("drugLicenseNo", {
                                    required:
                                        formData.userType === "CHEMIST" ||
                                        formData.userType === "HOSPITAL",
                                    validate: (value) => {
                                        if (formData.userType !== "CHEMIST") {
                                            return true;
                                        }
                                        return !!value.trim();
                                    },
                                })}
                            />
                            {errors?.drugLicenseNo && (
                                <p className={LoginClasses.error}>
                                    Drug License number is required!
                                </p>
                            )}
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="referredByCode">
                                REFERRED BY CODE
                            </label>
                            <input
                                placeholder="Enter Referred by code"
                                type="text"
                                {...register("referredByCode")}
                            />
                            {errors?.referredByCode && (
                                <p className={LoginClasses.error}>
                                    {errors?.referredByCode?.message}
                                </p>
                            )}
                        </div>
                        <div
                            className={`${LoginClasses["form-control"]} ${LoginClasses["image-form-control"]}`}>
                            <label htmlFor="doc_letterheadURL">
                                Upload GST document
                            </label>
                            <div className={LoginClasses["uploaded-data"]}>
                                <FileUploader
                                    classes={LoginClasses["upload-image"]}
                                    multiple={true}
                                    handleChange={gsthandleChange}
                                    name="file"
                                    children={fileuploadDesign}
                                    // types={fileTypes}
                                />
                                <div className={LoginClasses["uploaded-img"]}>
                                    {gstfileNames.map((item) => {
                                        return (
                                            <div
                                                className={
                                                    LoginClasses[
                                                        "uploaded-name"
                                                    ]
                                                }>
                                                <button
                                                    type="button"
                                                    usertype={"gstfile"}
                                                    id={item.id}
                                                    onClick={removeHandler}>
                                                    <GrFormClose
                                                        className={
                                                            LoginClasses[
                                                                "image-deleteIcon"
                                                            ]
                                                        }
                                                    />
                                                </button>
                                                <img
                                                    src={item.filename}
                                                    alt="uploaded-file"
                                                    width={"200px"}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${LoginClasses["form-control"]} ${LoginClasses["image-form-control"]}`}>
                            <label htmlFor="doc_letterheadURL">
                                Upload Doctor Letter Head
                            </label>
                            <div className={LoginClasses["uploaded-data"]}>
                                <FileUploader
                                    classes={LoginClasses["upload-image"]}
                                    multiple={true}
                                    handleChange={handleChange}
                                    name="file"
                                    children={fileuploadDesign}
                                    // types={fileTypes}
                                />
                                <div className={LoginClasses["uploaded-img"]}>
                                    {fileNames.map((item) => {
                                        return (
                                            <div
                                                className={
                                                    LoginClasses[
                                                        "uploaded-name"
                                                    ]
                                                }>
                                                <button
                                                    type="button"
                                                    id={item.id}
                                                    usertype={"doctor"}
                                                    onClick={removeHandler}>
                                                    <GrFormClose
                                                        className={
                                                            LoginClasses[
                                                                "image-deleteIcon"
                                                            ]
                                                        }
                                                    />
                                                </button>
                                                <img
                                                    src={item.filename}
                                                    alt="uploaded-file"
                                                    width={"200px"}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <div
                            className={`${LoginClasses["form-control"]} ${LoginClasses["image-form-control"]}`}>
                            <label htmlFor="doc_letterheadURL">
                                Upload Drug License Document
                            </label>
                            <div className={LoginClasses["uploaded-data"]}>
                                <FileUploader
                                    classes={LoginClasses["upload-image"]}
                                    multiple={true}
                                    handleChange={druglicenseChange}
                                    name="file"
                                    children={fileuploadDesign}
                                    // types={fileTypes}
                                />
                                <div className={LoginClasses["uploaded-img"]}>
                                    {DrugLicensefileNames.map((item) => {
                                        return (
                                            <div
                                                className={
                                                    LoginClasses[
                                                        "uploaded-name"
                                                    ]
                                                }>
                                                <button
                                                    type="button"
                                                    usertype={"chemist"}
                                                    id={item.id}
                                                    onClick={removeHandler}>
                                                    <GrFormClose
                                                        className={
                                                            LoginClasses[
                                                                "image-deleteIcon"
                                                            ]
                                                        }
                                                    />
                                                </button>
                                                <img
                                                    src={item.filename}
                                                    alt="uploaded-file"
                                                    width={"200px"}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                alignSelf: "center",
                                // justifySelf: "center",
                            }}>
                            <input
                                type="checkbox"
                                id="terms"
                                name="terms"
                                onChange={changeHandler}
                            />
                            <label for="vehicle1">
                                {" "}
                                Accept{" "}
                                <NavLink to="/terms-conditions">
                                    Terms & Conditions
                                </NavLink>
                            </label>
                        </div>
                        <input type={"submit"} value={"Submit"} />
                    </form>
                </div>
            </div>
            {/* </Layout> */}
        </>
    );
}

export default SignupDetails;
