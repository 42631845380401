import { useState, useEffect } from "react";
import HomePageClasses from "./homepage.module.css";
import ProductSlider from "./productSlider/productSlider";
import Product from "./products/product";
import Popular from "./popularProducts/popular";
import Latestproducts from "./LatestProducts/Latestproducts";
import useStore from "../../store";
import Footer from "./footer/footer";
import { Services } from "../../services";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../Utilities/layout";
import { Helmet } from "react-helmet";
// import Addtocart from './LatestProducts/AddToCart'
import LatestProductSkeleton from "./LatestProducts/LatestProductSkeleton";
function HomePageMain(props) {
    const [deals, setDealsofDay] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ExpiryProducts, setExpiryProducts] = useState([]);
    const [RecentlyOrderedItems, setRecentlyOrderedItems] = useState([]);
    const [banners, setBanners] = useState([]);
    const storewarehouseId = useStore((state) => state.warehouseId);
    const Navigate = useNavigate();
    useEffect(() => {
        let warehouseid = sessionStorage.getItem("warehouseId");
        if (!warehouseid) {
            return;
        }
        Services.getHomeScreen("GET", null, warehouseid)
            .then((res) => {
                setLoading(false);
                console.log(res);
                if (res.Status === 1) {
                    // setStoreCategories(res.categories);
                    setDealsofDay(res.DealsoftheDay);
                    setExpiryProducts(res.ExpiryProducts);
                    setBanners(res.banners);
                    setRecentlyOrderedItems(res.RecentlyOrderedItems);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
        // eslint-disable-next-line
    }, [storewarehouseId]);
    return (
        <>
            <Helmet defer={false}>
                <title>
                    Buy medicine wholesale online, Wholesale generic medicine
                    suppliers - Genifast.in
                </title>
                <meta
                    name="description"
                    content={
                        "Genifast.in provides wholesale medicine company, buy medicine in bulk, wholesalers near me, drug wholesalers near me, generic medicine wholesale near me, wholesale medicine suppliers, buy medicine wholesale, generic medicines wholesale suppliers."
                    }
                />
            </Helmet>
            <Layout>
                <div className={HomePageClasses["homePage-wrapper"]}>
                    <ProductSlider banners={banners} loading={loading} />
                    {/* <Product /> */}
                    <Popular loading={loading} />
                    {loading && <LatestProductSkeleton />}
                    {deals?.length > 0 && (
                        <Latestproducts
                            deals={deals}
                            heading={"Deals of the Day"}
                        />
                    )}
                    {ExpiryProducts?.length > 0 && (
                        <Latestproducts
                            deals={ExpiryProducts}
                            heading={"Near Expiry Products"}
                        />
                    )}
                    {RecentlyOrderedItems?.length > 0 && (
                        <Latestproducts
                            deals={RecentlyOrderedItems}
                            heading={"Recently Ordered Items"}
                        />
                    )}

                    <Footer />
                </div>
            </Layout>
        </>
    );
}

export default HomePageMain;
