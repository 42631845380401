import React from "react";
import listClasses from "./wishlist.module.css";
import Layout from "../../Utilities/layout";

import emptyCart from "../../Assets/emptycart.webp";
import { useNavigate } from "react-router-dom";
function Wishlist() {
    const Navigate = useNavigate();
    return (
        <>
            <Layout />
            <h1 className={listClasses["heading"]}>Coming Soon</h1>
            <div className={listClasses["emptyCart-wrapper"]}>
                <div className={listClasses["emptyCart"]}>
                    <img src={emptyCart} alt="emptycart" />
                    {/* <h2>Coming Soon</h2> */}
                    {/* <p>Add items to it now</p> */}
                    <button
                        onClick={() => {
                            Navigate("/shop");
                        }}>
                        Shop now
                    </button>
                </div>
            </div>
        </>
    );
}

export default Wishlist;
