import React from "react";
import listClasses from "./wishlist.module.css";
import Layout from "../../Utilities/layout";
import Logo from "../../Assets/about.png";
import emptyCart from "../../Assets/emptycart.webp";
import Footer from "../HomePage/footer/footer";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
function Wishlist() {
    const Navigate = useNavigate();
    return (
        <>
            <Helmet defer={false}>
                <title>About us</title>
                <meta
                    name="description"
                    content={
                        "We, at GeniFast, have a dream of becoming a pioneer in making generic drugs affordable, accessible, widely available & as popular as their branded versions if not more. Why? Because we’ve seen & felt the suffering, pain and helplessness of those who can no longer afford the medication in a chronic condition…very closely"
                    }
                />
            </Helmet>
            <Layout>
                <div className={listClasses["about-us"]}>
                    <div className={listClasses["wrapper"]}>
                        <h3>About</h3>
                        {/* <div className={listClasses["logo"]}>
                            <img src={Logo} alt="logo" width={"100px"} />
                            <p>
                                {" "}
                                One Stop Destination <br />
                                For Generic Medicine
                            </p>
                        </div> */}
                        <img src={Logo} alt="logo" />
                        <p>
                            Branded medicines sans multitudes of marketing
                            strategies, gimmick and voila!...generic medicines
                            were born. Yes, the concept of generic medicines or
                            drugs is that straightforward and simple, from the
                            time it came into being almost 100 years ago
                        </p>
                        <p>
                            We, at GeniFast, have a dream of becoming a pioneer
                            in making generic drugs affordable, accessible,
                            widely available & as popular as their branded
                            versions if not more. Why? Because we’ve seen & felt
                            the suffering, pain and helplessness of those who
                            can no longer afford the medication in a chronic
                            condition…very closely. They just wish to die
                            instead. And also because we know how important and
                            difficult it is at the same time, for a nation of
                            130+ billion people to get quality & inexpensive
                            health care.
                        </p>
                    </div>
                </div>
                <Footer />
            </Layout>
        </>
    );
}

export default Wishlist;
