import React, { useEffect, useState } from "react";
import ProfileLayout from "../profileLayout";
import referClasses from "./refer.module.css";
import referimg from "../../../Assets/refer.png";
import { RiFileCopyLine } from "react-icons/ri";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { FiFacebook } from "react-icons/fi";
import { FaTelegramPlane } from "react-icons/fa";
import { SiGmail } from "react-icons/si";

function Refer() {
    const [referCode, setreferralcode] = useState("");
    const copyHandler = () => {
        navigator.clipboard.writeText(`${referCode}`);
    };
    useEffect(() => {
        const details = JSON.parse(sessionStorage.getItem("userdetails"));
        setreferralcode(details?.referralCode);
    }, []);
    return (
        <ProfileLayout isactive={"refer"}>
            <div className={referClasses["refer-wrapper"]}>
                <h3>Refer & Earn</h3>
                <img src={referimg} alt="refer" />

                <p>
                    Refer and Earn -0.1% of the Delivered
                    <br /> Orders Referred by the users
                </p>
                <div className={referClasses["refer-code"]}>
                    <p>{referCode}</p>

                    <button onClick={copyHandler}>
                        <RiFileCopyLine size={25} />
                    </button>
                </div>
                <div className={referClasses["sharebuttons"]}>
                    <button className={referClasses["whatsapp"]}>
                        <AiOutlineWhatsApp color="white" />
                    </button>
                    <button className={referClasses["fb"]}>
                        <FiFacebook color="white" />
                    </button>
                    <button className={referClasses["telegram"]}>
                        <FaTelegramPlane color="white" />
                    </button>
                    <button className={referClasses["gmail"]}>
                        <SiGmail color="red" size={26} />
                    </button>
                </div>
            </div>
        </ProfileLayout>
    );
}

export default Refer;
