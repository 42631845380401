import React, { useState } from "react";
import logo from "../../../Assets/logo.png";
import SearchClasses from "./Search.module.css";
import { FaSearch } from "react-icons/fa";
import { AiOutlineUser, AiFillCaretDown } from "react-icons/ai";
import { FiHeart } from "react-icons/fi";
import { IoCartOutline, IoClose } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import useStore from "../../../store";
import { toast } from "react-toastify";
import { Services } from "../../../services";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Handlers } from "../../../Utilities/Handlers";
import Modal from "./Model";
import { GiHamburgerMenu } from "react-icons/gi";
import Logout from "../../Profile/navbar/logout";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
function Search(props) {
    const Navigate = useNavigate();
    const [logoutModal, setLogoutModal] = useState(false);
    const [firstTime, setFirsttime] = useState(true);
    const useredetails = JSON.parse(sessionStorage.getItem("userdetails"));
    const wishlistCount = useStore((state) => state.wishlistCount);
    const setwishlistCount = useStore((state) => state.setwishList);
    const cartCount = useStore((state) => state.CartCount);
    const setcartCount = useStore((state) => state.setcartCount);
    const resetState = useStore((state) => state.resetState);
    const storeSetwarehouseId = useStore((state) => state.SetwarehouseId);
    const storewarehouseId = useStore((state) => state.warehouseId);
    const [searchParams] = useSearchParams();
    const searchinput = searchParams.get("search");
    const [userdetails, setuserdetails] = useState({});
    const [searchString, setSearchString] = useState("");
    const [ActiveWareHouses, setActiveWareHouse] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState("");
    const [modal, setmodal] = useState(false);

    const loginHandler = () => {
        if (props.auth) {
            // Navigate("/orders");
            props.setShowSettings(true);
        } else if (!props.auth) {
            props.setlogin("login");
        }
    };
    useEffect(() => {
        ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ID);
    }, []);
    useEffect(() => {
        // if (searchinput) {
        //     setSearchString(sessionStorage.getItem("search"));
        // } else {
        //     setSearchString("");
        // }
        if (searchinput) {
            setSearchString("");
        }

        // eslint-disable-next-line
    }, [searchinput]);
    useEffect(() => {
        if (props.auth) {
            setuserdetails(JSON.parse(sessionStorage.getItem("userdetails")));
        }
    }, [props.auth]);
    useEffect(() => {
        let timer;
        if (searchString.length > 0) {
            console.log("aa");
            timer = setTimeout(() => {
                searchProductByGenericName(searchString);
            }, 500);
        }
        return () => clearTimeout(timer);
    }, [searchString]);
    useEffect(() => {
        if (!sessionStorage.getItem("warehouses")) {
            Services.Activewasrehouse("GET", null)
                .then((res) => {
                    if (res.Status === 1) {
                        setActiveWareHouse(res.Warehouses);
                        sessionStorage.setItem(
                            "warehouses",
                            JSON.stringify(res.Warehouses)
                        );
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            const warehouses = JSON.parse(sessionStorage.getItem("warehouses"));
            setActiveWareHouse(warehouses);
        }
        if (sessionStorage.getItem("warehouseId")) {
            setSelectedWarehouse(sessionStorage.getItem("warehouseId"));
            storeSetwarehouseId(sessionStorage.getItem("warehouseId"));
        }
        // eslint-disable-next-line
    }, [storewarehouseId]);
    const cartHandler = () => {
        if (props.auth) {
            Navigate("/cart");
        } else if (!props.auth) {
            props.setlogin("login");
        }
    };
    const wishlistHandler = () => {
        if (props.auth) {
            Navigate("/wishlist");
        } else if (!props.auth) {
            props.setlogin("login");
        }
    };
    const inputSearchHandler = (e) => {
        setSearchString(e.target.value);
    };
    const searchProductByGenericName = (name) => {
        let userDetails=JSON.parse(sessionStorage.getItem('userdetails'))

        let jsondata = {
            searchstring: name,
            warehouseId: sessionStorage.getItem("warehouseId"),
        };
        if (userDetails){
            jsondata={...jsondata,userType:userDetails?.userType}
        }
        Services.SearchProductByGenericName("POST", JSON.stringify(jsondata))
            .then((res) => {
                if (res.Status === 1) {
                    props.setSearch(true);
                    props.setGenericNames(res.Products);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    const onclickhandler = (e) => {
        const searchString = e.currentTarget.getAttribute("name");
        sessionStorage.setItem("search", searchString);

        ReactPixel.track("Search", {
            search_string: searchString,
            content_type: "genericName",
        });
        ReactGA.event({
            category: "Search",
            action: "Product search",
            label: searchString,
        });
        const SearchParam = searchString
            .replace("+", "")
            .toLowerCase()
            .replace(/\s+/g, "-");

        Navigate(`/shop?search=${SearchParam}`);
        props.setSearch(false);
    };
    const ModalHandler = (e) => {
        setSelectedWarehouse(e.target.value);
        const token = {
            Authorization: `token ${sessionStorage.getItem("token")}`,
        };

        if (sessionStorage.getItem("token") && cartCount !== 0) {
            setmodal(true);
        } else {
            // storeSetwarehouseId(e.target.value);
            Services.getwishlist("GET", null, token, e.target.value)
                .then((response) => {
                    console.log(response);
                    if (response.Status === 1) {
                        setwishlistCount(response.wishlist_Products.length);
                    } else if (response.Status === 0) {
                        setwishlistCount(0);
                    }
                })
                .catch((err) => {
                    toast.error(`something went wrong please try again`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            storeSetwarehouseId(e.target.value);
            sessionStorage.setItem("warehouseId", e.target.value);
            Handlers.getCart(setcartCount);
            // window.location.reload();
        }
        // if (cartCount==0 && sessionStorage)
    };
    const logouthandler = () => {
        setLogoutModal(true);
    };
    console.log(props.auth);
    return (
        <>
            {logoutModal && <Logout setmodal={setLogoutModal} />}
            {modal && (
                <Modal
                    selectedWarehouse={selectedWarehouse}
                    setSelectedWarehouse={setSelectedWarehouse}
                    setview={setmodal}
                />
            )}
            <div
                className={SearchClasses["Search-Container"]}
                style={{ ...props?.searchStyle }}>
                <img src={logo} alt="logo" onClick={() => Navigate("/")} />
                <select value={selectedWarehouse} onChange={ModalHandler}>
                    <option disabled value={""}>
                        Warehouse
                    </option>
                    {ActiveWareHouses.map((warehouse) => (
                        <option value={warehouse.warehouseId}>
                            {warehouse.warehouseName}
                        </option>
                    ))}
                </select>
                <div className={SearchClasses["search-input-wrapper"]}>
                    <div className={SearchClasses["search-input"]}>
                        <input
                            placeholder="Search Products by generic name"
                            value={searchString}
                            onChange={inputSearchHandler}
                        />
                        <FaSearch
                            className={SearchClasses["search-icon"]}
                            size={20}
                        />
                        {/* {searchString.length > 0 && (
                        <button
                        className={SearchClasses["close-btn"]}
                        onClick={() => {
                            props.setGenericNames("");
                            setSearchString("");
                        }}>
                        <IoClose
                        className={SearchClasses["close-icon"]}
                        size={20}
                        />
                        </button>
                    )} */}
                    </div>
                    {props.search > 0 && (
                        <div
                            className={SearchClasses["search-results"]}
                            id="search">
                            {props.genericNames.length > 0 ? (
                                props.genericNames
                                    .sort(function (a, b) {
                                        return a.genericName.localeCompare(
                                            b.genericName
                                        );
                                    })
                                    .map((item) => (
                                        <button
                                            onClick={onclickhandler}
                                            name={`${item.genericName}`}>
                                            {item.genericName}
                                        </button>
                                    ))
                            ) : (
                                <p>No results Found</p>
                            )}
                        </div>
                    )}
                </div>

                <div className={SearchClasses["user-details"]}>
                    <p></p>

                    <button onClick={wishlistHandler}>
                        <FiHeart
                            className={SearchClasses["icon"]}
                            color="#ff9923"
                        />
                        <p>{wishlistCount}</p>
                    </button>
                    <button onClick={cartHandler}>
                        <IoCartOutline
                            className={SearchClasses["icon"]}
                            color="#ff9923"
                        />
                        <p>{cartCount}</p>
                    </button>
                    <div className={SearchClasses["account-settings"]}>
                        <button
                            onClick={loginHandler}
                            className={SearchClasses["acc-settings-btn"]}
                            id="account-settings">
                            {!props.auth ? (
                                <AiOutlineUser
                                    color="#ff9923"
                                    className={SearchClasses["icon"]}
                                />
                            ) : useredetails?.profilePic == "NA" ? (
                                <AiOutlineUser
                                    color="#ff9923"
                                    className={SearchClasses["icon"]}
                                />
                            ) : (
                                <img
                                    src={userdetails?.profilePic}
                                    alt="profile-picture"
                                />
                            )}
                            <h3> {props.auth && userdetails?.ownerName}</h3>
                            <AiFillCaretDown />
                        </button>
                        {props.showSettings && (
                            <div className={SearchClasses["setting-btns"]}>
                                <NavLink to="/orders">My Orders</NavLink>
                                <NavLink to="/manage-Address">
                                    Manage Address
                                </NavLink>
                                <NavLink to="/editProfile">Profile</NavLink>
                                <NavLink to="/wishlist">Wishlist</NavLink>
                                <NavLink to="/help">Help</NavLink>
                                <button onClick={logouthandler}>Logout</button>
                            </div>
                        )}
                    </div>
                    <div className={SearchClasses["drop-down"]}>
                        <button
                            onClick={() => {
                                props.setShowMenu(true);
                            }}
                            id="show-menu">
                            {!props.showMenu ? (
                                <GiHamburgerMenu
                                    className={SearchClasses["icon"]}
                                />
                            ) : (
                                <IoClose className={SearchClasses["icon"]} />
                            )}
                        </button>
                        {props.showMenu && (
                            <div className={SearchClasses["menu-btns"]}>
                                <NavLink to="/">Home</NavLink>
                                <NavLink to="/shop">Shop by Category</NavLink>
                                <NavLink to="/about-us">About us</NavLink>
                                <NavLink to="/contact-us">Contact us </NavLink>
                                <NavLink to="/blog">Blog</NavLink>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Search;
