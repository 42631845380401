import React from "react";
import Layout from "../../Utilities/layout";
import Footer from "../HomePage/footer/footer";
import Pagenotfound from "../../Assets/404-error.webp";
import errorClasses from "./notfound.module.css";
import { useNavigate } from "react-router";
function Index() {
    const Navigate = useNavigate();
    return (
        <>
            <Layout />
            <div className={errorClasses["notfound-Container"]}>
                <img src={Pagenotfound} alt="err" />
                <div>
                    <button
                        onClick={() => {
                            Navigate(-1);
                        }}>
                        Go Back
                    </button>
                    <button
                        onClick={() => {
                            Navigate("/");
                        }}>
                        Home
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Index;
