import React, { useState } from "react";
import detailClasses from "./details.module.css";
import detailone from "../../Assets/facemask/detailone.jpg";
// import ReactStars from "react-stars";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import Modal from "react-modal";
import image from "../../Assets/image.png";
import { Handlers } from "../../Utilities/Handlers";
import useStore from "../../store";
Modal.setAppElement("#root");
function Quickview(props) {
    const auth = useStore((state) => state.isAuth);
    const setLogin = useStore((state) => state.setLoginModal);

    const warehouseid = sessionStorage.getItem("warehouseId");
    // eslint-disable-next-line
    const [displayImage, setDisplayImage] = useState(detailone);
    const setWishlistCount = useStore((state) => state.setwishList);

    const setcartCount = useStore((state) => state.setcartCount);
    const [qty, setQty] = useState(1);
    // eslint-disable-next-line
    const [size, setSize] = useState("1");

    // const addProductQty = () => {
    //     setQty((prev) => prev + 1);
    // };
    // const subtractProductQty = () => {
    //     if (qty > 1) {
    //         setQty((prev) => prev - 1);
    //     }
    // };
    const count = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const customStyles = {
        overlay: {
            // backgroundColor: 'papayawhip'
            zIndex: 100,
            overflow: "scroll",
        },
    };

    return (
        <Modal
            isOpen={props.view}
            style={customStyles}
            className={detailClasses["quickmodal"]}
            onRequestClose={() => {
                props.setview(false);
            }}>
            <div className={detailClasses["quick-view-wrapper"]}>
                <div className={detailClasses["details-image"]}>
                    <img
                        className={detailClasses["quick-display-image"]}
                        src={props.img !== "NA" ? props.img : image}
                        alt="product"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = image;
                        }}
                    />
                </div>
                <div className={detailClasses["detail-desc"]}>
                    {props?.availableQty < 10 && props?.availableQty >= 1 && (
                        <h3 className={detailClasses["available"]}>
                            Only {props?.availableQty} Left in Stock
                        </h3>
                    )}
                    {props.availableQty <= 0 && (
                        <h3 className={detailClasses["OutofStock"]}>
                            Out of Stock
                        </h3>
                    )}
                    <p>
                        {" "}
                        {props?.manufacturedBy !== "NA"
                            ? props?.manufacturedBy
                            : "No Brand"}
                    </p>
                    <h1>{props?.heading} </h1>
                    {/* <div className={detailClasses["user-rating"]}>
                        <ReactStars
                        count={5}
                        value={3.5}
                        size={20}
                        color2={"#ff9923"}
                        edit={false}
                        />
                        <p>(1 review)</p>
                    </div> */}
                    <div className={detailClasses["points"]}>
                        <ul>
                            <li>{props.genericName}</li>
                        </ul>
                    </div>
                    <div className={detailClasses["price"]}>
                        <h1
                            className={` ${
                                !auth ? detailClasses["blur-text"] : ""
                            }`}>
                            {auth ? `₹ ${props.sellingPrice}` : 0.0}
                            <span> (Inclusive of all taxes)</span>
                        </h1>
                        {auth && (
                            <div className={detailClasses["mrp"]}>
                                <p>
                                    MRP
                                    <del> ₹{props?.cost}</del>
                                </p>
                                <p className={detailClasses["save"]}>
                                    You save ₹
                                    {Math.round(
                                        props?.cost - props?.sellingPrice
                                    )}
                                </p>
                            </div>
                        )}
                        {/* <div className={detailClasses["sizes"]}>
                            <h3>Sizes:</h3>
                            <button
                                className={
                                    size === "1"
                                        ? detailClasses["btn-active"]
                                        : detailClasses["btn-inactive"]
                                }
                                onClick={sizeHandler}
                                btn={1}>
                                Small
                            </button>
                            <button
                                className={
                                    size === "2"
                                        ? detailClasses["btn-active"]
                                        : detailClasses["btn-inactive"]
                                }
                                onClick={sizeHandler}
                                btn={2}>
                                Medium
                            </button>
                            <button
                                className={
                                    size === "3"
                                        ? detailClasses["btn-active"]
                                        : detailClasses["btn-inactive"]
                                }
                                onClick={sizeHandler}
                                btn={3}>
                                Large
                            </button>
                        </div> */}
                        <div className={detailClasses["quantity"]}>
                            <h3>Quantity</h3>
                            <div className={detailClasses["user-btns"]}>
                                <div className={detailClasses["total-item"]}>
                                    {/* <select
                                        onChange={(e) => {
                                            setQty(e.target.value);
                                        }}>
                                        {count.map((count) => (
                                            <option value={count}>
                                                {count}
                                            </option>
                                        ))}
                                    </select> */}
                                    <input
                                        type={"number"}
                                        defaultValue={1}
                                        onChange={(e) => {
                                            setQty(e.target.value);
                                        }}
                                    />
                                </div>
                                <button
                                    prodId={props?.productId}
                                    disabled={props.availableQty <= 0}
                                    onClick={(e) => {
                                        Handlers.AddToCart(
                                            qty,
                                            e.currentTarget.getAttribute(
                                                "prodId"
                                            ),
                                            auth,
                                            setLogin,
                                            props.availableQty,
                                            setcartCount,
                                            warehouseid
                                        );
                                    }}>
                                    Add to Cart
                                </button>
                            </div>
                            <div className={detailClasses["wishlist-btns"]}>
                                <button
                                    prodId={props?.productId}
                                    onClick={(e) => {
                                        Handlers.wishlist(
                                            e.currentTarget.getAttribute(
                                                "prodId"
                                            ),
                                            auth,
                                            setLogin,
                                            setWishlistCount
                                        );
                                    }}>
                                    Add to wishlist
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default Quickview;
