import latestClasses from "./latest.module.css";
import HomePageProductSkeleton from "./homePageProductSkeleton";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import Skeleton from "react-loading-skeleton";
function Latestproducts(props) {
    const items = Array(5)
        .fill(0)
        .map((item) => {
            return <HomePageProductSkeleton />;
        });
    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
        1225: { items: 4 },
        1400: { items: 5 },
    };

    return (
        <>
            <div className={latestClasses["product-Container"]}>
                <h3 style={{ textAlign: "center" }}>
                    <Skeleton style={{ width: "40%" }} />
                </h3>
                <div
                    className={`${latestClasses["slider"]} ${latestClasses["multi-slider"]} multi-slider`}>
                    <AliceCarousel
                        mouseTracking
                        items={items}
                        responsive={responsive}
                        controlsStrategy="responsive"
                        disableDotsControls={true}
                        infinite={false}
                    />
                </div>
            </div>
        </>
    );
}

export default Latestproducts;
