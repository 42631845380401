import React, { useEffect } from "react";
import LoginClasses from "./login.module.css";
import Logo from "../../Assets/logo.png";
import { useNavigate } from "react-router-dom";
import Successlogin from "../../Assets/successlogin.png";
import party from "party-js";

function SignupSuccess() {
    const Navigate = useNavigate();
    useEffect(() => {
        party.confetti(document.body);
    }, []);
    return (
        <div className={LoginClasses["success-wrapper"]}>
            <div className={LoginClasses["signup-logo"]}>
                <img src={Logo} alt="logo" onClick={() => Navigate("/")} />
            </div>
            <h3>REGISTRATION SUCCESSFULL</h3>
            <img src={Successlogin} alt="success" />
            <p>
                Your account is under approval. We will reach out to you in next
                24 hrs
            </p>
        </div>
    );
}

export default SignupSuccess;
