import React, { useState, useRef } from "react";
import sideNavClasses from "./Navbar.module.css";
import { useNavigate, NavLink } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { CgShoppingBag } from "react-icons/cg";
import { AiOutlineMenu } from "react-icons/ai";
import { HiOutlineLogout } from "react-icons/hi";
import { BsPeople } from "react-icons/bs";
import { IoIosHelpCircle } from "react-icons/io";
import { GiNewspaper } from "react-icons/gi";
import LogoutModal from "./logout";
function SideNav(props) {
    const isactive = props.isactive;
    const element = useRef();
    const [showNav, setShowNav] = useState(false);
    const [logoutmodal, setlogoutmodal] = useState(false);

    const navbarhandler = () => {
        setShowNav((prev) => !prev);
    };
    const logouthandler = () => {
        setlogoutmodal(true);
    };
    return (
        <>
            {logoutmodal && <LogoutModal setmodal={setlogoutmodal} />}
            <div
                ref={element}
                className={`${sideNavClasses["sidenavwrapper"]} ${
                    showNav && sideNavClasses["wrapper-show"]
                }`}>
                <AiOutlineMenu
                    className={sideNavClasses["menu-icon"]}
                    onClick={navbarhandler}
                />

                <div
                    className={`${sideNavClasses["sidenavbar"]} ${
                        showNav && sideNavClasses["show"]
                    }`}>
                    <div className={sideNavClasses["Navlinks"]}>
                        <NavLink
                            to="/orders"
                            className={
                                isactive === "profile"
                                    ? sideNavClasses["nav-active"]
                                    : sideNavClasses["nav-inactive"]
                            }>
                            <CgShoppingBag
                                className={sideNavClasses["nav-icon"]}
                            />
                            <span>My Orders</span>
                        </NavLink>
                        <NavLink
                            to="/manage-Address"
                            className={
                                isactive === "address"
                                    ? sideNavClasses["nav-active"]
                                    : sideNavClasses["nav-inactive"]
                            }>
                            <AiOutlineUnorderedList />
                            <span>Manage Address</span>
                        </NavLink>
                        <NavLink
                            to="/Refer"
                            className={
                                isactive === "refer"
                                    ? sideNavClasses["nav-active"]
                                    : sideNavClasses["nav-inactive"]
                            }>
                            <BsPeople className={sideNavClasses["nav-icon"]} />
                            <span>Refer & Earn</span>
                        </NavLink>
                        <NavLink
                            to="/Points"
                            className={
                                isactive === "points"
                                    ? sideNavClasses["nav-active"]
                                    : sideNavClasses["nav-inactive"]
                            }>
                            <GiNewspaper
                                className={sideNavClasses["nav-icon"]}
                            />
                            <span>Your Points</span>
                        </NavLink>
                        <NavLink
                            to={"/help"}
                            className={
                                isactive === "help"
                                    ? sideNavClasses["nav-active"]
                                    : sideNavClasses["nav-inactive"]
                            }>
                            <IoIosHelpCircle
                                className={sideNavClasses["nav-icon"]}
                            />
                            <span>Help</span>
                        </NavLink>
                        {/* <NavLink
                            to="/"
                            className={
                                isactive === "orders"
                                    ? sideNavClasses["nav-active"]
                                    : sideNavClasses["nav-inactive"]
                            }>
                            <AiOutlineCompass
                                className={sideNavClasses["nav-icon"]}
                            />
                            <span>About US</span>
                        </NavLink> */}
                        <NavLink
                            to="/editProfile"
                            className={
                                isactive === "editProfile"
                                    ? sideNavClasses["nav-active"]
                                    : sideNavClasses["nav-inactive"]
                            }>
                            <CgProfile className={sideNavClasses["nav-icon"]} />
                            <span>Edit profile</span>
                        </NavLink>
                    </div>
                    <div className={sideNavClasses["logout"]}>
                        <button onClick={logouthandler}>
                            {" "}
                            <HiOutlineLogout size={22} />
                            <span>Logout</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SideNav;
