import React, { useState, useEffect } from "react";
import shopClasses from "./shop.module.css";
import { NavLink } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import Categories from "./Categories/categories";
import { FiChevronDown } from "react-icons/fi";
import Products from "./products/products";
import Footer from "../HomePage/footer/footer";
import { Services } from "../../services";
import { useNavigate, useSearchParams,  } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../Utilities/layout";
import useStore from "../../store";
import { Helmet } from "react-helmet";
import emptyCart from "../../Assets/emptycart.webp";
import ProductSkeleton from "./products/productsSkeleton";
function Shop() {
    const [searchParams] = useSearchParams();
    const CategoryId = searchParams.get("id");
    const Page = searchParams.get("page");
    const search = searchParams.get("search");
    const [loading, setLoading] = useState(true);
    const [sortBy, setSortBy] = useState("sortby");
    const [showSort, setShowSort] = useState(false);
    const [category, setCategory] = useState([]);
    const [SelectedCategory, setSelectedCategory] = useState();
    // const [categoriesMetaData, setCategoriesMetaData] = useState();
    const setCategoriesMetaData = useStore(
        (state) => state.setCategorisMetaData
    );
    const categoriesMetaData = useStore((state) => state.categoriesMetaData);
    const [products, setProducts] = useState([]);
    const storewarehouseId = useStore((state) => state.warehouseId);
    const warehouseid = sessionStorage.getItem("warehouseId");
    const auth = useStore((state) => state.isAuth);
    const Navigate = useNavigate();
    const dealsOfDay = () => {
        setLoading(true);
        Services.getHomeScreen("GET", null)
            .then((res) => {
                setLoading(false);
                if (res.Status === 1) {
                    setProducts(res.DealsoftheDay);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const getProducts = (id) => {
        setLoading(true);
        setSortBy("sortby");
        fetch("https://ipapi.co/json/")
            .then((response) => response.json())
            .then((result) => {
                Services.getProductsbyCategoryId(
                    "GET",
                    null,
                    id,
                    warehouseid,
                    result.region
                )
                    .then((res) => {
                        setLoading(false);
                        if (res.Status === 1) {
                            setProducts(res.Products);
                        } else if (res.Status === 0) {
                            toast.error(`${res.Message}`, {
                                position: "top-center",
                                autoClose: 1000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            if (res.Message === "Token expired") {
                                Navigate("/");
                            }
                        }
                    })
                    .catch((err) => {
                        setLoading(false);
                        // alert(err);
                        alert("something went wrong please try again");
                        console.log(err);
                    });
            });
    };
    const searchupdate = () => {
        const token = {
            Authorization: `token ${sessionStorage.getItem("token")}`,
        };
        let userDetails=JSON.parse(sessionStorage.getItem('userdetails'))

        
        let data = {
            genericName: sessionStorage.getItem("search"),
            warehouseId: sessionStorage.getItem("warehouseId"),
        };
        if(userDetails){
            data={...data,userType:userDetails?.userType}
        }
        Services.SearchProductByGenericName("POST", JSON.stringify(data), token)
            .then((res) => {
                setLoading(false);
                if (res.Status === 1) {
                    console.log(res);
                    setProducts(res.Products);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        setLoading(true);
        Services.getCategories("GET", null)
            .then((res) => {
                if (res.Status === 1) {
                    setCategory(res.categories);
                    if (search) {
                        searchupdate();
                        return;
                    }
                    if (CategoryId) {
                        setSelectedCategory(
                            sessionStorage.getItem("categoryName")
                        );
                        setCategoriesMetaData(
                            res.categories.find(
                                (item) =>
                                    item.categoryName ==
                                    sessionStorage.getItem("categoryName")
                            )
                        );
                        getProducts(CategoryId);
                        return;
                    }
                    else {
                        getProducts(res.categories[0].categoryId);
                        setSelectedCategory(res.categories[0].categoryName);
                        setCategoriesMetaData(res.categories[0]);
                    }
                    if (Page) {
                        dealsOfDay();
                        setSelectedCategory("");
                        return;
                    }

                  
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        // eslint-disable-next-line
    }, [Page, search, warehouseid, storewarehouseId]);
    const showSortHandler = () => {
        setShowSort(true);
    };
    const HideSortHandler = () => {
        setShowSort(false);
    };
    function SortProducts(a, b) {
        return a.sellingPrice - b.sellingPrice;
    }

    const buttonHandler = (e) => {
        const id = e.currentTarget.getAttribute("id");
        setLoading(true);
        if (id === "low") {
            setSortBy("low-high");
            setProducts((products) => products.sort(SortProducts));
            setLoading(false);
        } else if (id === "high") {
            setSortBy("high-low");
            setProducts((products) => products.sort(SortProducts).reverse());
            setLoading(false);
        } else {
            setSortBy(id);
        }
        HideSortHandler();
    };
    return (
        <>
            <Layout>
                <Helmet defer={false}>
                    <title>
                        {categoriesMetaData?.metaTitle?.length > 0
                            ? categoriesMetaData.metaTitle
                            : "GeniFast"}
                    </title>
                    <meta
                        name="description"
                        content={
                            categoriesMetaData?.metaDescription?.length > 0
                                ? categoriesMetaData.metaDescription
                                : "GeniFast"
                        }
                    />
                    <meta
                        name="keywords"
                        content={
                            categoriesMetaData?.metaKeywords?.length > 0
                                ? categoriesMetaData.metaKeywords
                                : "GeniFast"
                        }
                    />
                </Helmet>
                <div className={shopClasses["shop-wrapper"]}>
                    <div className={shopClasses["links"]}>
                        <NavLink className={shopClasses["home-link"]} to="/">
                            Home
                        </NavLink>
                        <p>
                            <AiOutlineRight size={12} color="#5b6c8f" />
                        </p>
                        <p className={shopClasses["shop"]}>shop</p>
                    </div>
                    <div className={shopClasses["header"]}>
                        <h2>
                            Shop <sup>({products.length})</sup>
                        </h2>
                        {Page && <h2>Deals of the Day</h2>}
                        {SelectedCategory && <h2>{SelectedCategory}</h2>}
                    </div>
                    <div className={shopClasses["products"]}>
                        <div className={shopClasses["categories"]}>
                            <Categories
                                category={category}
                                setSelectedCategory={setSelectedCategory}
                                setCategoriesMetaData={setCategoriesMetaData}
                                SelectedCategory={SelectedCategory}
                                getProducts={getProducts}
                            />
                        </div>
                        <div className={shopClasses["product-right-side"]}>
                            <div className={shopClasses["shop-heading"]}>
                                {Page && (
                                    <h2 style={{ textAlign: "center" }}>
                                        Deals of the Day
                                    </h2>
                                )}
                                {SelectedCategory && (
                                    <h2 style={{ textAlign: "center" }}>
                                        {SelectedCategory}
                                    </h2>
                                )}
                            </div>
                            {auth && (
                                <div className={shopClasses["sort-wrapper"]}>
                                    <div
                                        className={
                                            shopClasses["offer-product"]
                                        }>
                                        {/* <input type={"checkbox"} />
                                    <label htmlFor="product">
                                        Show Only Product On Sale
                                    </label> */}
                                    </div>
                                    <div className={shopClasses["sort-show"]}>
                                        <div className={shopClasses["sort"]}>
                                            {/* <label htmlFor="sortyby">Sortby</label> */}
                                            <div
                                                className={
                                                    shopClasses[
                                                        "sort-option-wrapper"
                                                    ]
                                                }
                                                onMouseLeave={HideSortHandler}
                                                onMouseEnter={showSortHandler}>
                                                <p>
                                                    {sortBy}{" "}
                                                    <FiChevronDown color="lightgrey" />
                                                </p>
                                                {showSort && (
                                                    <div
                                                        className={
                                                            shopClasses[
                                                                "sort-options"
                                                            ]
                                                        }
                                                        onMouseLeave={
                                                            HideSortHandler
                                                        }
                                                        onMouseEnter={
                                                            showSortHandler
                                                        }>
                                                        <button id={"sortby"}>
                                                            Sort By
                                                        </button>
                                                        <button
                                                            id="low"
                                                            onClick={
                                                                buttonHandler
                                                            }>
                                                            Price:Low-High
                                                        </button>
                                                        <button
                                                            id="high"
                                                            onClick={
                                                                buttonHandler
                                                            }>
                                                            Price:High-Low
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {loading ? (
                                <ProductSkeleton />
                            ) : products.length > 0 ? (
                                <Products products={products} />
                            ) : (
                                <div
                                    className={
                                        shopClasses["emptyCart-wrapper"]
                                    }>
                                    <div className={shopClasses["emptyCart"]}>
                                        <img src={emptyCart} alt="emptycart" />
                                        <h2>No Products Found!</h2>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
            </Layout>
        </>
    );
}

export default Shop;
