import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { Services } from "../../../services";
import { Services } from "../../../services";
import { useForm } from "react-hook-form";
import quantityClasses from "./quantity.module.css";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import useStore from "../../../store";
import { Handlers } from "../../../Utilities/Handlers";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
function Modal(props) {
    const Navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const setcartCount = useStore((state) => state.setcartCount);
    const auth = useStore((state) => state.isAuth);
    const setLogin = useStore((state) => state.setLoginModal);
    const warehouseid = sessionStorage.getItem("warehouseId");
    const [error, setError] = useState(undefined);
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };
    const userDetails = JSON.parse(sessionStorage.getItem("userdetails"));
    // initilize react pixel

    useEffect(() => {
        console.log(userDetails);

        const advanceMatching = {
            em: userDetails.emailId,
            ph: userDetails.mobileNumber,
            fn: userDetails.ownerName,
            ct: userDetails.city,
            st: userDetails.state,
            zp: userDetails.pincode,
            country: "IN",
            external_id: userDetails.userId,
        };
        ReactPixel.init(
            process.env.REACT_APP_FACEBOOK_PIXEL_ID,
            advanceMatching,
            false
        );
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ID);
        ReactGA.set({
            userId: userDetails.userId,
            email: userDetails.emailId,
            firstName: userDetails.ownerName,
            phone: userDetails.mobileNumber,
        });
    }, []);
    console.log(props);
    const onSubmit = (data) => {
        // Handlers.AddToCart(
        //     1,
        //     props.prodid,
        //     auth,
        //     setLogin,
        //     props.availableQty,
        //     setcartCount,
        //     warehouseid
        // );
        if (!auth) {
            setLogin("login");
            return;
        }
        const token = {
            Authorization: `token ${sessionStorage.getItem("token")}`,
        };

        if (props.availableQty <= 0) {
            toast.warn("Out of stock", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        if (props.availableQty < data.quantity) {
            setError(`only ${props.availableQty} Qty is available`);
            return;
        }
        if (data.quantity == 0) {
            setError("Quantity is required!");
            return;
        }
        if (data.quantity <= 0) {
            setError(`quantity should be greather than 0`);
            return;
        }
        const body = JSON.stringify({
            productId: props.prodid,
            quantity: data.quantity,
        });
        ReactPixel.track("AddToCart", {
            content_name: props?.allDetails?.productName,
            content_ids: [props?.allDetails?.productId],
            content_type: "product",
            value: props?.allDetails?.genifast_sellingPrice,
            currency: "INR",
            genericName: props?.allDetails?.genericName,
            categoryId: props?.allDetails?.categoryId,
        });
        ReactGA.event({
            category: "Cart",
            action: "Add to cart",
            label: `${props?.allDetails?.productName} - ${props?.allDetails?.productId}`,
            value: props?.allDetails?.genifast_sellingPrice,
        });
        Services.addPorductToCart("POST", body, token)
            .then((res) => {
                console.log("1", res);
                if (res.Status === 1) {
                    props.setmodal(false);
                    Handlers.getCart(setcartCount, warehouseid);
                    toast.success(`${res.Message}`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (res.Status === 0) {
                    // props.setmodal(false);
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    return (
        <>
            {loading ? (
                <div className={quantityClasses["modal"]}>
                    <div className={quantityClasses["spinner"]}>
                        <FadeLoader color="#36d7b7" />
                    </div>
                </div>
            ) : (
                <div
                    className={`${quantityClasses["modal"]} ${quantityClasses["addCart-modal"]}`}>
                    <div
                        className={quantityClasses["modal-content"]}
                        onClick={openhandler}>
                        <div className={quantityClasses["header"]}>
                            <p>Add to Cart </p>
                            <button
                                className={quantityClasses["close-btn"]}
                                onClick={CancelHandler}>
                                <IoClose
                                    size={27}
                                    color="white"
                                    style={{ cursor: "pointer" }}
                                />
                            </button>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={quantityClasses["form-control"]}>
                                <label htmlFor="Cancellation Reason">
                                    Quantity
                                </label>
                                <input
                                    type={"number"}
                                    placeholder="Enter Quantity"
                                    {...register("quantity", {
                                        onChange: (e) => {
                                            if (e.target.value.length > 0) {
                                                setError(undefined);
                                            }
                                        },
                                    })}
                                />
                                {error && (
                                    <p className={quantityClasses.error}>
                                        {error}
                                    </p>
                                )}
                                <p
                                    style={{
                                        color: "red",
                                        fontFamily: "Roboto, sans-serif",
                                        fontWeight: "bolder",
                                    }}>
                                    {props.availableQty} left in stock
                                </p>
                            </div>
                            <div className={quantityClasses["control-btns"]}>
                                <button
                                    className={quantityClasses["delete-btn"]}
                                    type="button"
                                    onClick={CancelHandler}>
                                    Cancel
                                </button>
                                <button
                                    className={quantityClasses["submit-btn"]}
                                    type="submit">
                                    Add to Cart
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default Modal;
