import React, { useState, useEffect } from "react";
import detailClasses from "./details.module.css";
import { NavLink, useSearchParams } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import Footer from "../HomePage/footer/footer";
import Customer from "./customerbought/customer";
import Similar from "./similarProducts/similar";
import { toast } from "react-toastify";
import { Services } from "../../services";
import Loader from "../../Utilities/loader";
import Layout from "../../Utilities/layout";
import image from "../../Assets/image.png";
import useStore from "../../store";
import { Handlers } from "../../Utilities/Handlers";
import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";
import ReadMoreReact from "read-more-react";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
function ProductDetails(props) {
    const [searchParams] = useSearchParams();
    const prodid = searchParams.get("id");
    const auth = useStore((state) => state.isAuth);
    const setLogin = useStore((state) => state.setLoginModal);
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };

    const warehouseid = sessionStorage.getItem("warehouseId");
    const [products, setProducts] = useState({});
    const [load, setload] = useState(true);
    const [qty, setQty] = useState(1);

    const setWishlistCount = useStore((state) => state.setwishList);

    const setcartCount = useStore((state) => state.setcartCount);
    const userDetails = JSON.parse(sessionStorage.getItem("userdetails"));
    // initilize react pixel

    useEffect(() => {
        console.log(userDetails);

        const advanceMatching = {
            em: userDetails.emailId,
            ph: userDetails.mobileNumber,
            fn: userDetails.ownerName,
            ct: userDetails.city,
            st: userDetails.state,
            zp: userDetails.pincode,
            country: "IN",
            external_id: userDetails.userId,
        };
        ReactPixel.init(
            process.env.REACT_APP_FACEBOOK_PIXEL_ID,
            advanceMatching,
            false
        );
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ID);
        ReactGA.set({
            userId: userDetails.userId,
            email: userDetails.emailId,
            firstName: userDetails.ownerName,
            phone: userDetails.mobileNumber,
        });
    }, []);

    useEffect(() => {
        setload(true);
        const warehouseid = sessionStorage.getItem("warehouseId");
        Services.getProductByProductId("GET", null, token, prodid, warehouseid)
            .then((response) => {
                console.log(response);
                //     content_category: 'Product Category',
                if (response.Status === 1) {
                    ReactPixel.trackSingle("ViewContent", {
                        content_type: "product",
                        content_ids: [response.Products.productId],
                        content_name: response.Products.productName,
                        genericName: response.Products.genericName,
                        value: response.Products.genifast_sellingPrice,
                        currency: "INR",
                    });
                    ReactGA.event({
                        category: "Product",
                        action: "Viewed",
                        label: `${response.Products.productName} - ${response.Products.productIdtId}`,
                        value: response.Products.genifast_sellingPrice,
                    });
                    setProducts({
                        productDetail: response.Products,
                        RelatedProducts: response.RelatedProducts,
                    });
                    setload(false);
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                toast.error(`something went wrong please try again`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        // eslint-disable-next-line
    }, [prodid]);
    const addToCartHandler = (e) => {
        if (qty <= 0) {
            console.log(products.productDetail);
            toast.error(`Enter a valid quantity`, {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        ReactPixel.track("AddToCart", {
            content_name: products?.productDetail?.productName,
            content_ids: [products?.productDetail?.productId],
            content_type: "product",
            value: products?.productDetail?.genifast_sellingPrice,
            currency: "INR",
            genericName: products?.productDetail?.genericName,
            categoryId: products?.productDetail?.categoryId,
        });
        ReactGA.event({
            category: "Cart",
            action: "Add to cart",
            label: `${products?.productDetail?.productName} - ${products?.productDetail?.productId}`,
            value: products?.productDetail?.genifast_sellingPrice,
        });
        Handlers.AddToCart(
            qty,
            products?.productDetail?.productId,
            auth,
            setLogin,
            products?.productDetail?.availableQty,
            setcartCount,
            warehouseid
        );
    };
    return (
        <Layout>
            {load ? (
                <Loader />
            ) : (
                <>
                    <div className={detailClasses["detail-wrapper"]}>
                        <Helmet defer={false}>
                            <title>{products?.productDetail?.metaTitle}</title>
                            <meta
                                name="description"
                                content={
                                    products?.productDetail?.metaDescription
                                }
                            />
                            <meta
                                name="keywords"
                                content={products?.productDetail?.metaKeywords}
                            />
                        </Helmet>
                        <div className={detailClasses["links"]}>
                            <NavLink
                                className={detailClasses["home-link"]}
                                to="/">
                                Home
                            </NavLink>
                            <p>
                                <AiOutlineRight size={12} color="#5b6c8f" />
                            </p>
                            <NavLink
                                className={detailClasses["home-link"]}
                                to="/shop">
                                Shop
                            </NavLink>
                            <p>
                                <AiOutlineRight size={12} color="#5b6c8f" />
                            </p>
                            <p className={detailClasses["shop"]}>Details</p>
                        </div>
                        <div className={detailClasses["details-item"]}>
                            <div className={detailClasses["item-leftside"]}>
                                <div className={detailClasses["details-image"]}>
                                    <img
                                        className={
                                            detailClasses["display-image"]
                                        }
                                        src={
                                            products?.productDetail
                                                ?.imageUrl !== "NA"
                                                ? products?.productDetail
                                                      ?.imageUrl
                                                : image
                                        }
                                        alt="product"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = image;
                                        }}
                                    />
                                </div>
                                <div className={detailClasses["detail-desc"]}>
                                    {products?.productDetail?.availableQty <
                                        10 &&
                                        products?.productDetail?.availableQty >=
                                            1 && (
                                            <h3
                                                className={
                                                    detailClasses["available"]
                                                }>
                                                Only{" "}
                                                {
                                                    products?.productDetail
                                                        .availableQty
                                                }{" "}
                                                Left in Stock
                                            </h3>
                                        )}
                                    {products?.productDetail?.availableQty <=
                                        0 && (
                                        <h3
                                            className={
                                                detailClasses["OutofStock"]
                                            }>
                                            Out of Stock
                                        </h3>
                                    )}
                                    <p>
                                        {products?.productDetail
                                            ?.manufacturedBy !== "NA"
                                            ? products.productDetail
                                                  ?.manufacturedBy
                                            : "No Brand"}
                                    </p>
                                    <h1>
                                        {products?.productDetail?.productName}
                                    </h1>

                                    <div className={detailClasses["points"]}>
                                        <ul>
                                            <li>
                                                {
                                                    products?.productDetail
                                                        ?.genericName
                                                }
                                            </li>
                                            {products?.productDetail
                                                ?.moleculeDescription?.length >
                                                0 && (
                                                <li>
                                                    <h3
                                                        style={{
                                                            margin: "0.5rem 0",
                                                        }}>
                                                        Description
                                                    </h3>
                                                    <ReadMoreReact
                                                        text={
                                                            products
                                                                ?.productDetail
                                                                ?.moleculeDescription
                                                        }
                                                    />
                                                </li>
                                            )}
                                            <p style={{ marginTop: "0.5rem" }}>
                                                Expiry:
                                                {products.productDetail.expiry}
                                            </p>
                                        </ul>
                                    </div>
                                    <div className={detailClasses["price"]}>
                                        <h1>
                                            ₹{" "}
                                            {
                                                products?.productDetail
                                                    ?.genifast_sellingPrice
                                            }
                                            <span>
                                                {" "}
                                                (Inclusive of all taxes)
                                            </span>
                                        </h1>
                                        <div className={detailClasses["mrp"]}>
                                            <p>
                                                MRP
                                                <del>
                                                    {" "}
                                                    ₹
                                                    {
                                                        products?.productDetail
                                                            ?.price
                                                    }
                                                </del>
                                            </p>
                                            <p
                                                className={
                                                    detailClasses["save"]
                                                }>
                                                You save ₹
                                                {Math.round(
                                                    products?.productDetail
                                                        ?.price -
                                                        products?.productDetail
                                                            ?.genifast_sellingPrice
                                                )}
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                detailClasses["quantity"]
                                            }>
                                            {products?.productDetail
                                                ?.availableQty > 0 && (
                                                <h3>Quantity</h3>
                                            )}
                                            {products?.productDetail
                                                ?.availableQty > 0 && (
                                                <div
                                                    className={
                                                        detailClasses[
                                                            "user-btns"
                                                        ]
                                                    }>
                                                    <div
                                                        className={
                                                            detailClasses[
                                                                "total-item"
                                                            ]
                                                        }>
                                                        <input
                                                            type={"number"}
                                                            defaultValue={1}
                                                            onChange={(e) => {
                                                                setQty(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <button
                                                        onClick={
                                                            addToCartHandler
                                                        }>
                                                        Add to Cart
                                                    </button>
                                                </div>
                                            )}
                                            <div
                                                className={
                                                    detailClasses[
                                                        "wishlist-btns"
                                                    ]
                                                }>
                                                <button
                                                    onClick={(e) => {
                                                        console.log(
                                                            products?.productDetail
                                                        );
                                                        ReactPixel.track(
                                                            "AddToWishlist",
                                                            {
                                                                content_name:
                                                                    products
                                                                        ?.productDetail
                                                                        ?.productName,
                                                                content_ids: [
                                                                    products
                                                                        ?.productDetail
                                                                        ?.productId,
                                                                ],
                                                                content_type:
                                                                    "product",
                                                                value: products
                                                                    ?.productDetail
                                                                    ?.genifast_sellingPrice,
                                                                currency: "INR",
                                                                genericName:
                                                                    products
                                                                        ?.productDetail
                                                                        ?.genericName,
                                                                categoryId:
                                                                    products
                                                                        ?.productDetail
                                                                        ?.categoryId,
                                                            }
                                                        );
                                                        ReactGA.event({
                                                            category:
                                                                "Wishlist",
                                                            action: "Add to wishlist",
                                                            label: `${products?.productDetail?.productName}-${products?.productDetail?.productId}`,
                                                        });

                                                        Handlers.wishlist(
                                                            products
                                                                ?.productDetail
                                                                ?.productId,
                                                            auth,
                                                            setLogin,
                                                            setWishlistCount
                                                        );
                                                    }}>
                                                    Add to wishlist
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className={detailClasses["frequent"]}>
                                    <Specifications />
                                </div>

                                <div className={detailClasses["frequent"]}>
                                    <Review />
                                </div> */}
                            </div>
                            {/* <div className={detailClasses["right-side"]}>
                                <Similar />
                            </div> */}
                        </div>
                    </div>
                    {products?.RelatedProducts?.length > 0 && (
                        <Customer relatedProducts={products?.RelatedProducts} />
                    )}
                    <Footer />
                </>
            )}
        </Layout>
    );
}

export default ProductDetails;
