import { toast } from "react-toastify";
import { Services } from "../services";

const AddToCart = (
    count,
    id,
    auth,
    setLogin,
    availableQty,
    setcartCount,
    warehouseid
) => {
    if (!auth) {
        setLogin("login");
        return;
    }
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };

    if (availableQty <= 0) {
        toast.warn("Out of stock", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return;
    }
    if (availableQty < count) {
        toast.warn(`only ${availableQty} left in stock`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return;
    }
    const body = JSON.stringify({ productId: id, quantity: count });
    Services.addPorductToCart("POST", body, token)
        .then((res) => {
            console.log("1", res);
            if (res.Status === 1) {
                getCart(setcartCount, warehouseid);
                toast.success(`${res.Message}`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (res.Status === 0) {
                toast.error(`${res.Message}`, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch((err) => {
            // alert(err);
            alert("something went wrong please try again");
            console.log(err);
        });
};
const wishlist = (id, auth, setLogin, setwishList) => {
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    if (!auth) {
        setLogin("login");
        return;
    }
    const body = JSON.stringify({ productId: id });
    Services.addTowishList("POST", body, token)
        .then((res) => {
            if (res.Status === 1) {
                getWishlist(setwishList);
                toast.success(`${res.Message}`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (res.Status === 0) {
                toast.error(`${res.Message}`, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch((err) => {
            // alert(err);
            alert("something went wrong please try again");
            console.log(err);
        });
};
const getWishlist = (setWishlistCount) => {
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    const warehouseid = sessionStorage.getItem("warehouseId");
    Services.getwishlist("GET", null, token, warehouseid)
        .then((response) => {
            console.log(response);
            if (response.Status === 1) {
                setWishlistCount(response.wishlist_Products.length);
            }
        })
        .catch((err) => {
            toast.error(`something went wrong please try again`, {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
};
const getCart = (setcartCount, warehouseid) => {
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    const wareid = sessionStorage.getItem("warehouseId");
    Services.getCart("GET", null, token, wareid)
        .then((response) => {
            console.log(response);
            if (response.Status === 1) {
                setcartCount(response.Cart.cartItems.length);
            }
        })
        .catch((err) => {
            toast.error(`something went wrong please try again`, {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
};
export const Handlers = {
    AddToCart,
    wishlist,
    getCart,
    getWishlist,
};
