import React, { useEffect } from "react";
import cartclasses from "./cart.module.css";
import ReactPixel from "react-facebook-pixel";
function Modal(props) {
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setview(false);
    };
    const DeleteHandler = (e) => {
        e.stopPropagation();
        props.deleteHandler();
    };
    const openhandler = (e) => {
        e.stopPropagation();
    };
    const userDetails = JSON.parse(sessionStorage.getItem("userdetails"));
    // initilize react pixel

    useEffect(() => {
        console.log(userDetails);

        const advanceMatching = {
            em: userDetails.emailId,
            ph: userDetails.mobileNumber,
            fn: userDetails.ownerName,
            ct: userDetails.city,
            st: userDetails.state,
            zp: userDetails.pincode,
            country: "IN",
            external_id: userDetails.userId,
        };
        ReactPixel.init(
            process.env.REACT_APP_FACEBOOK_PIXEL_ID,
            advanceMatching,
            false
        );
    }, []);
    return (
        <div className={cartclasses["modal"]} onClick={CancelHandler}>
            <div className={cartclasses["modal-content"]} onClick={openhandler}>
                <div className={cartclasses["modal-content"]}>
                    <button className={cartclasses["close-btn"]}>
                        {/* <FaRegWindowClose size={27} /> */}
                    </button>
                    <p>You can move Products to wishlist and Buy later?</p>
                    <div className={cartclasses["control-btns"]}>
                        <button
                            className={cartclasses["cancel-btn"]}
                            onClick={props.moveTowishlist}>
                            WishList
                        </button>
                        <button
                            className={cartclasses["delete-btn"]}
                            onClick={DeleteHandler}>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
