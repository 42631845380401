import React from "react";
import ProductClasses from "./product.module.css";
import vaporizer from "../../../Assets/vaporizer.jpg";
import thermometer from "../../../Assets/thermometer.jpg";
import brush from "../../../Assets/brush.jpg";
import { useNavigate } from "react-router-dom";
function Product() {
    const Navigate = useNavigate();
    return (
        <div className={ProductClasses["product-wrapper"]}>
            <div className={ProductClasses["product-one"]}>
                <div className={ProductClasses["image-box"]}>
                    <img src={vaporizer} alt="vaporizer" />
                </div>
                <div className={ProductClasses["productOne-details"]}>
                    <h5>New</h5>
                    <h3>
                        Vaprorizer <br />
                        Charger <br />
                        x200 5kw
                    </h3>
                    <button
                        onClick={() => {
                            Navigate("/shop");
                        }}>
                        More
                    </button>
                </div>
            </div>
            <div className={ProductClasses["product-two"]}>
                <div className={ProductClasses["image-box"]}>
                    <img src={thermometer} alt="vaporizer" />
                </div>
                <div className={ProductClasses["productTwo-details"]}>
                    <h3>
                        Digital <br />
                        Thermometer <br />
                        x30-Pro
                    </h3>
                    <h5>-10%</h5>
                    <button
                        onClick={() => {
                            Navigate("/shop");
                        }}>
                        Shop Now
                    </button>
                </div>
            </div>
            <div className={ProductClasses["product-three"]}>
                <div className={ProductClasses["image-box"]}>
                    <img src={brush} alt="brush" />
                </div>
                <div className={ProductClasses["productThree-details"]}>
                    <h3>
                        Digital <br />
                        Thermometer <br />
                        x30-Pro
                    </h3>
                    <h5>
                        ₹119.00{" "}
                        <sup>
                            <del>225</del>
                        </sup>
                    </h5>

                    <button
                        onClick={() => {
                            Navigate("/shop");
                        }}>
                        Shop Now
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Product;
