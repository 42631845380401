import React from "react";
import "../../../App.css";
import productShopclasses from "./products.module.css";
import Product from "../../HomePage/LatestProducts/homePageProductSkeleton";
function productsSkeleton() {
    return (
        <div className={productShopclasses["product-container"]}>
            <div className={productShopclasses["products-wrapper"]}>
                {Array(15)
                    .fill(0)
                    .map((item) => (
                        <Product />
                    ))}
            </div>
        </div>
    );
}

export default productsSkeleton;
