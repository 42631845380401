import React from "react";
import navbarClasses from "./navbar.module.css";
import { NavLink } from "react-router-dom";
// import FadeIn from "react-fade-in";

function Navbar(props) {
    return (
        <div className={navbarClasses["navbar-Container"]}>
            <div className={navbarClasses["navbar-wrapper"]}>
                <div className={navbarClasses["navbar"]}>
                    <NavLink
                        to="/"
                        className={({ isActive }) =>
                            isActive
                                ? `${navbarClasses["active"]}`
                                : `${navbarClasses["inactive"]}`
                        }>
                        Home
                    </NavLink>
                    <NavLink
                        to="/shop"
                        className={({ isActive }) =>
                            isActive
                                ? `${navbarClasses["active"]}`
                                : `${navbarClasses["inactive"]}`
                        }>
                        Shop by Category
                    </NavLink>
                    <NavLink
                        to="/about-us"
                        className={({ isActive }) =>
                            isActive
                                ? `${navbarClasses["active"]}`
                                : `${navbarClasses["inactive"]}`
                        }>
                        About us
                    </NavLink>
                    <NavLink
                        to="/contact-us"
                        className={({ isActive }) =>
                            isActive
                                ? `${navbarClasses["active"]}`
                                : `${navbarClasses["inactive"]}`
                        }>
                        Contact us{" "}
                    </NavLink>
                    <NavLink
                        to="/blog"
                        className={({ isActive }) =>
                            isActive
                                ? `${navbarClasses["active"]}`
                                : `${navbarClasses["inactive"]}`
                        }>
                        Blog
                    </NavLink>
                </div>
                <p>Need help? 8886-6602-01</p>
            </div>
        </div>
    );
}

export default Navbar;
